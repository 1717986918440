import React from "react";
import ReactDOM from "react-dom/client";
import { IntercomProvider } from "react-use-intercom";
import { IntlProvider } from "react-intl";
import { IonApp } from "@ionic/react";
import { Auth0Provider } from "@auth0/auth0-react";

import { ApiProvider } from "./providers/ApiProvider";
import LocaleProvider from "./providers/LocaleProvider";
import { UserProvider } from "./providers/UserProvider";
import LocationProvider from "./providers/LocationProvider";
import NotificationsProvider from "./providers/NotificationsProvider";
import CSRFProvider from "providers/CSRFProvider";
import { IntercomProvider as LocalIntercomProvider } from "./providers/IntercomProvider";
import DollarizeProvider from "providers/DollarizeProvider";
import FlagsHandler from "components/FlagsHandler";
import { PHProvider } from "providers/PHProvider";
import { ErrorProvider } from "providers/ErrorProvider";
import { createBrowserHistory } from "history";
import { unstable_HistoryRouter as Router } from "react-router-dom";

import App from "./App";

import ARMessages from "./compiled-lang/es-AR.json";

import "./assets/styles/global.scss";

const history = createBrowserHistory();

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
  <React.StrictMode>
    <PHProvider>
      {/* Este never es macabro, pero no pude encontrar el tipo adecuado */}
      <Router history={history as never}>
        <IntlProvider
          locale={"es-AR"}
          defaultLocale="es-AR"
          messages={ARMessages}
        >
          <ErrorProvider>
            <NotificationsProvider>
              <LocationProvider>
                <LocaleProvider>
                  <IntercomProvider
                    appId={window.env.intercomAppId}
                    autoBoot
                    autoBootProps={{
                      alignment: "right",
                    }}
                  >
                    <CSRFProvider>
                      <ApiProvider>
                        <Auth0Provider
                          domain={window.env.auth0_domain}
                          clientId={window.env.auth0_client_id}
                        >
                          <UserProvider>
                            <LocalIntercomProvider>
                              <FlagsHandler>
                                <DollarizeProvider>
                                  <IonApp className="ion-root">
                                    <App />
                                  </IonApp>
                                </DollarizeProvider>
                              </FlagsHandler>
                            </LocalIntercomProvider>
                          </UserProvider>
                        </Auth0Provider>
                      </ApiProvider>
                    </CSRFProvider>
                  </IntercomProvider>
                </LocaleProvider>
              </LocationProvider>
            </NotificationsProvider>
          </ErrorProvider>
        </IntlProvider>
      </Router>
    </PHProvider>
  </React.StrictMode>,
);
