import { z } from "zod";
import axios from "axios";

export const UNMANAGED_ERROR_TYPE = "UNMANAGED_ERROR_TYPE";

export const errorParser = (error: unknown) => {
  if (axios.isAxiosError(error)) {
    const parsed = DefaultErrorSchema.safeParse(error.response?.data);

    if (parsed.success) {
      return (parsed.data.errors[0]);
    } else {
      return (UNMANAGED_ERROR_TYPE);
    }
  } else {
    return (UNMANAGED_ERROR_TYPE);
  }
}

export const onErrorHandler = (handler: (message: string) => void) => {
  return (error: unknown) => {
    handler(errorParser(error))
  };
};

export const DefaultErrorSchema = z.object({
  errors: z.array(z.string()),
});

export class DefaultError {
  constructor(readonly message: string[]) { }
}
