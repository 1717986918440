import { useContext } from "react";
import { SWRResponse } from "swr";
import { HubBalanceParams, HubBalanceResponse } from "@letsbit/malcolmlb";
import { ApiContext } from "providers/ApiProvider";
import { useAuthSWR } from "hooks/utils/useAuthSWR";

export const SWR_GET_HUB_BALANCES_KEY = "get-hub-balances";

export const useGetHubBalances = (
  params?: HubBalanceParams
): SWRResponse<HubBalanceResponse[], any> => {
  const { memberInstance } = useContext(ApiContext);

  const swr = useAuthSWR([SWR_GET_HUB_BALANCES_KEY, params], async () => {
    const { data } = await memberInstance.hub.getBalances(params);

    return data;
  });

  return swr;
};
