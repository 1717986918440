import { useContext } from "react";
import { KeyedMutator } from "swr";
import { UltimateBeneficialOwner } from "@letsbit/malcolmlb";
import { useAuthSWR } from "hooks/utils/useAuthSWR";
import { ApiContext } from "providers/ApiProvider";

export const SWR_ULTIMATE_BENEFICIAL_OWNER_KEY =
  "get-ultimate-beneficial-owner";

export const useGetUltimateBeneficialOwner = (): {
  data: UltimateBeneficialOwner[] | undefined;
  error: Error | undefined;
  mutate: KeyedMutator<UltimateBeneficialOwner[]>;
} => {
  const { memberInstance } = useContext(ApiContext);
  const { data, mutate, error } = useAuthSWR(
    SWR_ULTIMATE_BENEFICIAL_OWNER_KEY,
    async () => {
      const response =
        await memberInstance.compliance.getUltimateBeneficialOwner();
      return response?.data;
    },
  );
  return {
    data,
    error,
    mutate,
  };
};
