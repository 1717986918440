import { defineMessages } from "react-intl";

export const scope = "components.OnBoardingSelectAccountTypePage";

export const messages = defineMessages({
  carouselTitle: {
    id: `${scope}.carouselTitle`,
    defaultMessage: "Soluciones para tu empresa",
  },
  carouselSubtitle: {
    id: `${scope}.carouselSubtitle`,
    defaultMessage:
      "Con Let’sBit vas a poder simplificar cobros y pagos internacionales, resguardar reservas a través de dólares bancarios y generar rendimientos de tus reservas.",
  },
  title: {
    id: `${scope}.title`,
    defaultMessage: "¿Qué tipo de uso le darás a esta cuenta?",
  },
  naturalPerson: {
    id: `${scope}.naturalPerson`,
    defaultMessage: "Personal",
  },
  juridicalPerson: {
    id: `${scope}.juridicalPerson`,
    defaultMessage: "Empresarial",
  },
  errorRequired: {
    id: `${scope}.errorRequired`,
    defaultMessage: "Este campo es requerido",
  },
  confirm: {
    id: `${scope}.confirm`,
    defaultMessage: "CONFIRMAR",
  },
  notificationTitle: {
    id: `${scope}.notificationTitle`,
    defaultMessage: "Actualizar usuario",
  },
  notificationMessage: {
    id: `${scope}.notificationMessage`,
    defaultMessage: "Ocurrió un error, vuelva a intentar nuevamente",
  },
});
