import React from "react";

import { PrecogsFirstAndFinalSlideHeaderProps } from "./types";
import { ReactComponent as CrossIcon } from "assets/images/icons/cross.svg";

import "./styles.scss";

export const PrecogsFirstAndFinalSlideHeader: React.FC<PrecogsFirstAndFinalSlideHeaderProps> =
  ({ onClick }) => {
    return (
      <div className="precogs-first-and-final-slide-header flex justify-end items-center">
        <CrossIcon onClick={onClick} />
      </div>
    );
  };

export default PrecogsFirstAndFinalSlideHeader;
