import allCountries from "utils/allCountries.json";
import { CountryData, CountrySelectorProps } from "./types";
import { ModalSelector } from "components/ModalSelector";
import { filterCountries } from "utils/filters";
import { Card } from "@letsbit/milkylb";
import { useGetOnboardingCountries } from "hooks/user/useGetOnboardingCountries";
import LoadingAnimation from "components/LoadingAnimation";

const countries = (allCountries as CountryData[]).map(
  (country: CountryData) => ({
    id: country.countryShortCode,
    label: country.countryName.es,
    value: country.countryShortCode,
  }),
);

export const CountrySelector: React.FC<CountrySelectorProps> = ({
  personType,
  onChange,
  placeholder,
  value,
  title,
  error,
  onBlur,
}) => {
  const { data: onboardingCountries, isLoading } =
    useGetOnboardingCountries(personType);

  const validCountries = countries.filter((c) =>
    !personType ? true : onboardingCountries && onboardingCountries[c.value],
  );

  return (
    <>
      {isLoading && <LoadingAnimation />}

      <ModalSelector
        filter={filterCountries}
        value={typeof value === "string" ? value : value?.label}
        error={error}
        optionComponent={({ element }) => (
          <Card
            className="shrink-0"
            key={element.id}
            title={element.label}
            icon={
              <img
                src={`https://flagcdn.com/${element.value.toLowerCase()}.svg`}
                alt=""
              />
            }
          >
            {element.label}
          </Card>
        )}
        title={<h3 className="mb-8 text-center text-xl font-bold">{title}</h3>}
        onSelectOption={(paymentNetwork) => {
          onChange(paymentNetwork);
        }}
        placeholder={placeholder}
        options={validCountries}
        dataCypressId="enterprise__page-country__select"
        onBlur={onBlur}
      />
    </>
  );
};
