import {
  Icon,
  InformationRequestCardProps,
  InformationRequestItemProps,
  InformationRequestLabelProps,
} from "./types";
import { ReactComponent as Doc } from "assets/images/onboarding/doc.svg";
import { ReactComponent as DocSign } from "assets/images/onboarding/doc_sign.svg";
import { ReactComponent as InfoIcon } from "assets/images/icons/info.svg";
import { ReactComponent as WarningIcon } from "assets/images/icons/warning.svg";
import classNames from "classnames";
import { FormattedMessage } from "react-intl";
import { messages } from "./messages";
import { INFORMATION_REQUEST_STATUS } from "@letsbit/malcolmlb";
import dayjs from "dayjs";

const getIcon = (icon: Icon) => {
  switch (icon) {
    case "doc":
      return <Doc />;
    case "sign":
      return <DocSign />;
  }
};

export const InformationRequestLabel: React.FC<
  InformationRequestLabelProps
> = ({ className, label }) => {
  if (!label) {
    return null;
  }

  const defaultClassName = "rounded-2xl px-3 py-1 text-sm";

  switch (label) {
    case "info":
      return (
        <label className={classNames(className, defaultClassName)}>
          <InfoIcon />
        </label>
      );
    case "warning":
      return (
        <label className={classNames(className, defaultClassName)}>
          <WarningIcon />
        </label>
      );
    case INFORMATION_REQUEST_STATUS.ACCEPTED:
      return (
        <label
          className={classNames(
            className,
            defaultClassName,
            "border border-[--milkylb-color-green-success] bg-[--milkylb-color-green-success] text-white",
          )}
        >
          <FormattedMessage {...messages.aprovedLabel} />
        </label>
      );
    case INFORMATION_REQUEST_STATUS.REJECTED:
      return (
        <label
          className={classNames(
            className,
            defaultClassName,
            "bg-[--milkylb-color-red-error] text-white",
          )}
        >
          <FormattedMessage {...messages.rejectedLabel} />
        </label>
      );

    case INFORMATION_REQUEST_STATUS.NOTIFY_ADMIN:
      return (
        <label
          className={classNames(
            className,
            defaultClassName,
            "bg-[--milkylb-color-secondary-200] text-white",
          )}
        >
          <FormattedMessage {...messages.updatedLabel} />
        </label>
      );
    case INFORMATION_REQUEST_STATUS.AWAITING_ADMIN:
      return (
        <label
          className={classNames(
            className,
            defaultClassName,
            "bg-[--milkylb-color-background-input] text-[--milkylb-color-secondary-200]",
          )}
        >
          <FormattedMessage {...messages.awaitingAdminLabel} />
        </label>
      );

    default:
      return null;
  }
};

export const InformationRequestCard: React.FC<InformationRequestCardProps> = ({
  icon = "doc",
  informationRequest,
  onClick,
  className,
  tooltipIcon,
}) => {
  return (
    <div
      className={classNames(
        "relative flex h-44 w-48 cursor-pointer flex-col items-center gap-3 rounded-3xl p-4 pt-10 text-center shadow-md",
        className,
      )}
      onClick={onClick}
    >
      <InformationRequestLabel
        label={tooltipIcon || informationRequest.status}
        className="absolute right-2 top-2"
      />
      {getIcon(icon)}
      <label className="text-lg font-bold leading-6 text-[--milkylb-color-neutral-600]">
        {informationRequest.subject}
      </label>
    </div>
  );
};

export const InformationRequestItem: React.FC<InformationRequestItemProps> = ({
  informationRequest,
  onClick,
  className,
}) => {
  return (
    <div
      className={classNames(
        "relative flex items-center justify-between gap-3 rounded-2xl p-4 shadow-[2px_2px_5px_2px_#0000000F]",
        className,
      )}
      onClick={onClick}
    >
      <div>
        <label className="text-lg font-bold leading-6 text-[--milkylb-color-neutral-600]">
          {informationRequest.subject}
        </label>
        <p className="text-xs font-semibold text-[--milkylb-color-neutral-400]">
          {dayjs(informationRequest.created_at).format("DD.MM.YYYY")}
          {informationRequest.extra_document?.files.length !== undefined &&
            informationRequest.extra_document?.files.length > 0 && (
              <>
                - {informationRequest.extra_document?.files.length}{" "}
                {informationRequest.extra_document?.files.length > 1 ? (
                  <FormattedMessage {...messages.filesLabel} />
                ) : (
                  <FormattedMessage {...messages.fileLabel} />
                )}
              </>
            )}
        </p>
      </div>
      <InformationRequestLabel label={informationRequest.status} />
    </div>
  );
};
