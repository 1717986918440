import { AlertSign, Button } from "@letsbit/milkylb";
import { DividerWithCarousel } from "components/DividerWithCarousel";
import { FormattedMessage } from "react-intl";
import { messages } from "./messages";
import { Stepper } from "../../components/Stepper";
import { StepsPageProps } from "../types";
import { SolutionContent } from "../../components/SolutionContent";
import { useEffect } from "react";

const steps = [
  {
    idx: "1",
    title: <FormattedMessage {...messages.step1Title} />,
  },
  {
    idx: "2",
    title: <FormattedMessage {...messages.step2Title} />,
  },
  {
    idx: "3",
    title: <FormattedMessage {...messages.step3Title} />,
    items: [
      <FormattedMessage {...messages.step3Desc1} values={{ br: <br /> }} />,
      <FormattedMessage {...messages.step3Desc2} values={{ br: <br /> }} />,
      <FormattedMessage {...messages.step3Desc3} values={{ br: <br /> }} />,
      <FormattedMessage {...messages.step3Desc4} values={{ br: <br /> }} />,
    ],
  },
  {
    idx: "4",
    title: <FormattedMessage {...messages.step4Title} />,
    items: [
      <FormattedMessage {...messages.step4Desc1} values={{ br: <br /> }} />,
      <FormattedMessage {...messages.step4Desc2} values={{ br: <br /> }} />,
    ],
  },
];

export const StepsPage: React.FC<StepsPageProps> = ({
  completed,
  onContinue,
  reloadSteps,
}) => {
  const completedArray = [
    completed.customer_data && "1",
    completed.director_email && "2",
    completed.juridical_person_data && "3",
    completed.extra_docs && "4",
  ].filter((x) => !!x);

  const showAlertSign =
    completedArray.includes("1") && completedArray.includes("2");

  useEffect(() => {
    reloadSteps();
  }, [reloadSteps]);

  return (
    <DividerWithCarousel carouselContent={[<SolutionContent />]} noDots noSwipe>
      <div className="flex h-full w-full flex-col items-center justify-between p-24">
        <div className="flex w-full flex-col">
          <h1 className="pb-8 font-['Tomato_Grotesk'] text-3xl font-bold text-[--milkylb-color-neutral-600]">
            <FormattedMessage {...messages.title} />
          </h1>
          <p className="pb-4 text-sm font-normal text-[--milkylb-color-neutral-600]">
            <FormattedMessage {...messages.description} />
          </p>
          <Stepper steps={steps} completed={completedArray as string[]} />
        </div>
        <div
          className="flex w-full items-center"
          style={{
            justifyContent: showAlertSign ? "space-between" : "end",
          }}
        >
          {showAlertSign && (
            <AlertSign
              text={
                <FormattedMessage
                  {...messages.alertSign}
                  values={{
                    br: <br />,
                  }}
                />
              }
            />
          )}
          <Button
            className="self-end"
            onClick={onContinue}
            data-cypress-id="onboarding__page-empezar__button"
          >
            <FormattedMessage {...messages.start} />
          </Button>
        </div>
      </div>
    </DividerWithCarousel>
  );
};
