import { defineMessages } from "react-intl";

export const scope = "components.PrecogsFormSlider";

export const messages = defineMessages({
  progressBarFirst: {
    id: `${scope}.progressBarFirst`,
    defaultMessage: "Paso",
  },
  progressBarSecond: {
    id: `${scope}.progressBarSecond`,
    defaultMessage: "de",
  },
  buttonSlide: {
    id: `${scope}.buttonSlide`,
    defaultMessage: "CONTINUAR",
  },
});
