import { Button, CheckboxItem, Error, Input } from "@letsbit/milkylb";
import React from "react";
import { useIntl, FormattedMessage } from "react-intl";

import { messages, options } from "./messages";
import {
  OperatoryDetailsFormProps,
  OperatoryDetailsForm as OpForm,
} from "./types";
import classNames from "classnames";
import { Controller, useForm } from "react-hook-form";
import { formMessages } from "utils/formMessages";

import "./styles.scss";

export const OperatoryDetailsForm: React.FC<OperatoryDetailsFormProps> = ({
  onSubmit,
  className,
  form,
}) => {
  const { handleSubmit, control, watch } = useForm<
    OpForm & {
      "purpose_array": string[];
      "purpose-other": string;
    }
  >({
    mode: "onBlur",
    defaultValues: form ? {
      estimated_amount_monthly: form.estimated_amount_monthly,
      purpose_array: form.purpose?.split(",").map(purp => purp.includes(messages.otherOption.defaultMessage) ? purp.split(":")[0] : purp) ?? [],
      "purpose-other": form.purpose?.split(",").find(purp => purp.includes(messages.otherOption.defaultMessage))?.split(":")[1] ?? ""
    } : { purpose_array: [] },
  });
  const { formatMessage } = useIntl();

  const purpose = watch("purpose_array");

  return (
    <form
      onSubmit={handleSubmit((data) =>
        onSubmit({
          estimated_amount_monthly: data.estimated_amount_monthly,
          purpose:
            data.purpose_array.map(purp => purp === messages.otherOption.defaultMessage ? messages.otherOption.defaultMessage + ":" + data["purpose-other"] : purp).toString(),
        })
      )}
      className={classNames(
        className,
        "fadeInSimple relative flex flex-col items-center justify-center"
      )}
      data-cypress-id="operatory__data-form"
    >
      <div className="h-full w-full">
        <Controller
          control={control}
          rules={{
            required: formatMessage(formMessages.requiredFieldError),
          }}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <div className="operatory-details-form__options mb-4">
              <label className="grid-flow-row text-lg font-normal text-[--color-neutral-0]">
                <FormattedMessage {...messages.objectiveLabel} />
              </label>
              <div className="mt-4 grid grid-cols-2 gap-4">
                {options.map((opt) => (
                  <div>
                  <CheckboxItem
                    className="whitespace-normal"
                    checked={value.includes(opt.value)}
                    onIonChange={(e: { target: { checked: boolean } }) => {
                      if (e.target.checked) {
                        onChange(value.concat(opt.value));
                      } else {
                        onChange(value.filter(o => o !== opt.value));
                      }
                    }}
                    data-cypress-id={`operatory__data-checkbox-${opt.label.id}`}
                    label={<FormattedMessage {...opt.label} />}
                  />
                  </div>
                ))}
              </div>
              <Error error={!!error} message={error?.message} />
            </div>
          )}
          name="purpose_array"
        />
        {purpose.find(p => p === messages.otherOption.defaultMessage) && (
          <Controller
            control={control}
            rules={{
              required: formatMessage(formMessages.requiredFieldError),
            }}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <div className="fadeInSimple">
                <Input
                  placeholder={formatMessage(messages.anotherPlaceholder)}
                  value={value}
                  onIonInput={onChange}
                  error={!!error}
                  message={error?.message}
                  data-cypress-id="operatory__data-other__purpose__input"
                />
              </div>
            )}
            name="purpose-other"
          />
        )}
        <Controller
          control={control}
          rules={{
            required: formatMessage(formMessages.requiredFieldError),
          }}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <div>
              <label className="text-lg font-normal text-[--color-neutral-0]">
                <FormattedMessage {...messages.stimatedAmountLabel} />
              </label>
              <Input
                placeholder={formatMessage(messages.stimatedAmountPlaceholder)}
                value={value}
                onIonInput={onChange}
                error={!!error}
                type="number"
                message={error?.message}
                data-cypress-id="operatory__data-estimated__amount__input"
              />
            </div>
          )}
          name="estimated_amount_monthly"
        />
      </div>
      <Button
        type="submit"
        style={{ width: 197 }}
        className="mt-8 w-full self-end"
      >
        <FormattedMessage {...messages.continueButton} />
      </Button>
    </form>
  );
};
