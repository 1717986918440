import React from "react";

import { NotificationProps, NotificationStatus } from "./types";
import { ReactComponent as SuccessIcon } from "assets/images/icons/notifications/success.svg";
import { ReactComponent as ErrorIcon } from "assets/images/icons/notifications/error.svg";
import { ReactComponent as WarningIcon } from "assets/images/icons/notifications/warning.svg";
import { ReactComponent as InfoIcon } from "assets/images/icons/notifications/info.svg";

import "./styles.scss";
import classNames from "classnames";

export const Notification: React.FC<NotificationProps> = ({
  title,
  message,
  status,
  onCancel,
  forwardedRef,
  className,
}) => {
  return (
    <div
      className={classNames("Notification rounded-2xl", className)}
      ref={forwardedRef}
    >
      {status === NotificationStatus.SUCCESS && (
        <SuccessIcon className="Notification__icon" />
      )}
      {status === NotificationStatus.ERROR && (
        <ErrorIcon className="Notification__icon" />
      )}
      {status === NotificationStatus.WARNING && (
        <WarningIcon className="Notification__icon" />
      )}
      {status === NotificationStatus.INFO && (
        <InfoIcon className="Notification__icon" />
      )}
      <div
        className={classNames(
          "Notification__overlay",
          status === NotificationStatus.SUCCESS &&
            "bg-[--milkylb-color-green-success]",
          status === NotificationStatus.ERROR &&
            "bg-[--milkylb-color-red-error]",
          status === NotificationStatus.WARNING &&
            "bg-[--milkylb-color-yellow-warning]",
          status === NotificationStatus.INFO &&
            "bg-[--color-notification-blue-info]"
        )}
      ></div>
      <div className="p-2 pl-12">
        <h6 className="text-lg font-bold text-[--milkylb-color-neutral-800]">
          {title}
        </h6>
        <p className="text-[--milkylb-color-neutral-400]" data-cypress-id="main-page__login-notification__message">
          {message}
        </p>
        <ErrorIcon className="Notification__cancel" onClick={onCancel} />
      </div>
    </div>
  );
};
