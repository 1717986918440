import { useContext, useState } from "react";
import { ApiContext } from "providers/ApiProvider";
import { defineMessages, useIntl } from "react-intl";
import { useNotification } from "hooks/utils/useNotification";
import { NotificationStatus } from "components/Notification/types";
import {
  INFORMATION_REQUEST_STATUS,
  InformationRequests,
  UltimateBeneficialOwner,
  UltimateBeneficialOwnerDocumentForm,
  UltimateBeneficialOwnerForm,
} from "@letsbit/malcolmlb";

export const scope = "hook.useUltimateBeneficialOwner";

export const messages = defineMessages({
  defaultErrorNotificationTitle: {
    id: `${scope}.confirmErrorNotificationTitle`,
    defaultMessage: "Ocurrio un error, intentalo de nuevo mas tarde.",
  },
  createSuccessUltimateBeneficialOwnerNotificationMessage: {
    id: `${scope}.createSuccessUltimateBeneficialOwnerNotificationMessage`,
    defaultMessage: "Creado con éxito",
  },
  uploadSuccessUltimateBeneficialOwnerNotificationMessage: {
    id: `${scope}.uploadSuccessUltimateBeneficialOwnerNotificationMessage`,
    defaultMessage: "Documentos subidos con éxito",
  },
  uploadFailedUltimateBeneficialOwnerNotificationMessage: {
    id: `${scope}.uploadSuccessUltimateBeneficialOwnerNotificationMessage`,
    defaultMessage: "No pudimos subir {documentType}",
  },
  deleteSuccessUltimateBeneficialOwnerNotificationMessage: {
    id: `${scope}.deleteSuccessUltimateBeneficialOwnerNotificationMessage`,
    defaultMessage: "Borrado con éxito",
  },
  notificationTitle: {
    id: `${scope}.notificationTitle`,
    defaultMessage: "Beneficiarios finales",
  },
  updateSuccessUltimateBeneficialOwnerNotificationMessage: {
    id: `${scope}.updateSuccessUltimateBeneficialOwnerNotificationMessage`,
    defaultMessage: "Beneficiario final actualizado con éxito",
  },
  updateFailedUltimateBeneficialOwnerNotificationMessage: {
    id: `${scope}.updateSuccessUltimateBeneficialOwnerNotificationMessage`,
    defaultMessage: "No pudimos actualizar el beneficiario final",
  },
  front_id: {
    id: `${scope}.front_id`,
    defaultMessage: "el frente del documento",
  },
  back_id: {
    id: `${scope}.back_id`,
    defaultMessage: "el dorso del documento",
  },
  proof_of_address_document: {
    id: `${scope}.proof_of_address_document`,
    defaultMessage: "la prueba de domicilio",
  },
  defaultDocumentTypeMessage: {
    id: `${scope}.defaultDocumentTypeMessage`,
    defaultMessage: "el documento",
  },
});

export enum UBO_DOCUMENT_TYPES {
  FRONT_ID = "front_id",
  BACK_ID = "back_id",
  PROOF_OF_ADDRESS_DOCUMENT = "proof_of_address_document",
}

export const documentTypeToMessage = (documentType: string) => {
  switch (documentType) {
    case UBO_DOCUMENT_TYPES.FRONT_ID:
      return messages.front_id;
    case UBO_DOCUMENT_TYPES.BACK_ID:
      return messages.back_id;
    case UBO_DOCUMENT_TYPES.PROOF_OF_ADDRESS_DOCUMENT:
      return messages.proof_of_address_document;
    default:
      return messages.defaultDocumentTypeMessage;
  }
};

export const informationRequestHasValidState = (
  doc: InformationRequests | undefined,
): boolean => {
  if (!doc || doc.status === INFORMATION_REQUEST_STATUS.REJECTED) {
    return false;
  }

  return true;
};

export const useUltimateBeneficialOwner = () => {
  const { memberInstance } = useContext(ApiContext);
  const [loading, setLoading] = useState(false);
  const { showNotification } = useNotification();
  const { formatMessage } = useIntl();

  const updateUltimateBeneficialOwner = async (
    id: number,
    form: UltimateBeneficialOwnerForm,
  ): Promise<UltimateBeneficialOwner> => {
    setLoading(true);
    try {
      const { data: ubo } =
        await memberInstance.compliance.updateUltimateBeneficialOwner(id, form);
      showNotification({
        title: formatMessage(messages.notificationTitle),
        message: formatMessage(
          messages.updateSuccessUltimateBeneficialOwnerNotificationMessage,
        ),
        status: NotificationStatus.SUCCESS,
      });
      return ubo;
    } catch (error) {
      console.error(error);
      switch (error) {
        default:
          showNotification({
            title: formatMessage(messages.notificationTitle),
            message: formatMessage(
              messages.updateFailedUltimateBeneficialOwnerNotificationMessage,
            ),
            status: NotificationStatus.ERROR,
          });
          break;
      }
      throw error;
    } finally {
      setLoading(false);
    }
  };

  const createUltimateBeneficialOwner = async (
    form: UltimateBeneficialOwnerForm,
  ): Promise<UltimateBeneficialOwner> => {
    setLoading(true);
    try {
      const { data: ubo } =
        await memberInstance.compliance.createUltimateBeneficialOwner(form);
      showNotification({
        title: formatMessage(messages.notificationTitle),
        message: formatMessage(
          messages.createSuccessUltimateBeneficialOwnerNotificationMessage,
        ),
        status: NotificationStatus.SUCCESS,
      });
      return ubo;
    } catch (error) {
      console.error(error);
      switch (error) {
        default:
          showNotification({
            title: formatMessage(messages.notificationTitle),
            message: formatMessage(messages.defaultErrorNotificationTitle),
            status: NotificationStatus.ERROR,
          });
          break;
      }
      throw error;
    } finally {
      setLoading(false);
    }
  };

  const deleteUltimateBeneficialOwner = async (id: number): Promise<void> => {
    setLoading(true);
    try {
      await memberInstance.compliance.deleteUltimateBeneficialOwner(id);
      showNotification({
        title: formatMessage(messages.notificationTitle),
        message: formatMessage(
          messages.deleteSuccessUltimateBeneficialOwnerNotificationMessage,
        ),
        status: NotificationStatus.SUCCESS,
      });
    } catch (error) {
      console.error(error);
      switch (error) {
        default:
          showNotification({
            title: formatMessage(messages.notificationTitle),
            message: formatMessage(messages.defaultErrorNotificationTitle),
            status: NotificationStatus.ERROR,
          });
          break;
      }
      throw error;
    } finally {
      setLoading(false);
    }
  };

  const uploadUltimateBeneficialOwnerDocs = async (
    id: number,
    docs: UltimateBeneficialOwnerDocumentForm[],
  ): Promise<void> => {
    setLoading(true);
    try {
      let anyFailed = false;

      await Promise.allSettled(
        docs.map(async (doc) => {
          try {
            await memberInstance.compliance.uploadUltimateBeneficialOwnerDocument(
              id,
              {
                document_type: doc.document_type,
                file: doc.file,
              },
            );
          } catch (error) {
            console.error(error);
            anyFailed = true;
            showNotification({
              title: formatMessage(messages.notificationTitle),
              message: formatMessage(
                messages.uploadFailedUltimateBeneficialOwnerNotificationMessage,
                {
                  documentType: formatMessage(
                    documentTypeToMessage(doc.document_type),
                  ),
                },
              ),
              status: NotificationStatus.ERROR,
            });
          }
        }),
      );

      if (!anyFailed) {
        showNotification({
          title: formatMessage(messages.notificationTitle),
          message: formatMessage(
            messages.uploadSuccessUltimateBeneficialOwnerNotificationMessage,
          ),
          status: NotificationStatus.SUCCESS,
        });
      }
    } finally {
      setLoading(false);
    }
  };

  return {
    createUltimateBeneficialOwner,
    deleteUltimateBeneficialOwner,
    uploadUltimateBeneficialOwnerDocs,
    updateUltimateBeneficialOwner,
    loading,
  };
};
