import { defineMessages } from "react-intl";

export const scope = "components.UltimateBeneficialOwnersForm";

export const messages = defineMessages({
  title: {
    id: `${scope}.title`,
    defaultMessage: "Nuevo beneficiario final",
  },
  previous: {
    id: `${scope}.previous`,
    defaultMessage: "ANTERIOR",
  },
  save: {
    id: `${scope}.save`,
    defaultMessage: "GUARDAR",
  },
  next: {
    id: `${scope}.next`,
    defaultMessage: "SIGUIENTE",
  },
  delete: {
    id: `${scope}.delete`,
    defaultMessage: "BORRAR",
  },
  nameLabel: {
    id: `${scope}.nameLabel`,
    defaultMessage: "Nombre",
  },
  namePlaceholder: {
    id: `${scope}.namePlaceholder`,
    defaultMessage: "Ingrese el nombre",
  },
  lastNameLabel: {
    id: `${scope}.lastNameLabel`,
    defaultMessage: "Apellido",
  },
  lastNamePlaceholder: {
    id: `${scope}.lastNamePlaceholder`,
    defaultMessage: "Ingrese el apellido",
  },
  countryLabel: {
    id: `${scope}.countryLabel`,
    defaultMessage: "Nacionalidad",
  },
  countryPlaceholder: {
    id: `${scope}.countryPlaceholder`,
    defaultMessage: "Selecciona tu nacionalidad",
  },
  documentTypeLabel: {
    id: `${scope}.documentTypeLabel`,
    defaultMessage: "Tipo de documento",
  },
  documentTypePlaceholder: {
    id: `${scope}.documentTypePlaceholder`,
    defaultMessage: "Seleccione el tipo de documento",
  },
  professionLabel: {
    id: `${scope}.professionLabel`,
    defaultMessage: "Profesión",
  },
  professionPlaceholder: {
    id: `${scope}.professionPlaceholder`,
    defaultMessage: "Ingrese su profesión",
  },
  civilStateLabel: {
    id: `${scope}.civilStateLabel`,
    defaultMessage: "Estado civil",
  },
  civilStatePlaceholder: {
    id: `${scope}.civilStatePlaceholder`,
    defaultMessage: "Seleccione su estado civil",
  },
  emailLabel: {
    id: `${scope}.emailLabel`,
    defaultMessage: "Correo electrónico",
  },
  emailPlaceholder: {
    id: `${scope}.emailPlaceholder`,
    defaultMessage: "Ingrese el correo",
  },
  addressLabel: {
    id: `${scope}.addressLabel`,
    defaultMessage: "Domicilio",
  },
  addressPlaceholder: {
    id: `${scope}.addressPlaceholder`,
    defaultMessage: "Seleccione su domicilio",
  },
  phoneLabel: {
    id: `${scope}.phoneLabel`,
    defaultMessage: "Teléfono",
  },
  phonePlaceholder: {
    id: `${scope}.phonePlaceholder`,
    defaultMessage: "Ingrese el número",
  },
  documentNumberLabel: {
    id: `${scope}.documentNumberLabel`,
    defaultMessage: "Número de documento de identidad",
  },
  documentNumberPlaceholder: {
    id: `${scope}.documentNumberPlaceholder`,
    defaultMessage: "Ingrese el número de documento",
  },
  taxIdentificationLabel: {
    id: `${scope}.taxIdentificationLabel`,
    defaultMessage: "Número de Identificación tributaria",
  },
  taxIdentificationPlaceholder: {
    id: `${scope}.taxIdentificationPlaceholder`,
    defaultMessage: "Ingrese el número",
  },
  sharesLabel: {
    id: `${scope}.sharesLabel`,
    defaultMessage: "Porcentaje de acciones",
  },
  sharesPlaceholder: {
    id: `${scope}.sharesPlaceholder`,
    defaultMessage: "Ingrese su número",
  },
  nationalIdTypeLabel: {
    id: `${scope}.nationalIdTypeLabel`,
    defaultMessage: "Tipo de documento",
  },
  nationalIdTypePlaceholder: {
    id: `${scope}.nationalIdTypePlaceholder`,
    defaultMessage: "Seleccione el tipo de documento",
  },
  pepLabel: {
    id: `${scope}.pepLabel`,
    defaultMessage: "Persona Expuesta Políticamente",
  },
  yesLabel: {
    id: `${scope}.yesLabel`,
    defaultMessage: "Si",
  },
  noLabel: {
    id: `${scope}.noLabel`,
    defaultMessage: "No",
  },
  obligatedSubjectLabel: {
    id: `${scope}.obligatedSubjectLabel`,
    defaultMessage: "Sujeto obligado",
  },
  frontIdLabel: {
    id: `${scope}.frontIdLabel`,
    defaultMessage: "Frente del documento",
  },
  backIdLabel: {
    id: `${scope}.backIdLabel`,
    defaultMessage: "Dorso del documento",
  },
  proofOfAddressLabel: {
    id: `${scope}.proofOfAddressLabel`,
    defaultMessage: "Comprobante de domicilio",
  },
  introParagraph1: {
    id: `${scope}.introParagraph1`,
    defaultMessage:
      "Con el objetivo de dar cumplimiento a disposiciones legales de Prevención de Lavado de Activos y Financiamiento del Terrorismo, debemos identificar a las personas humanas que posean la titularidad{br}o el control -en última instancia- de una persona jurídica.",
  },
  introParagraph2: {
    id: `${scope}.introParagraph2`,
    defaultMessage:
      "<b>¿Quiénes son los beneficiarios finales?</b>{br}Personas humanas que posean o controlen como mínimo el 10% del capital o de los derechos de voto de una persona jurídica. Pueden ser:",
  },
  firstListItem: {
    id: `${scope}.firstListItem`,
    defaultMessage:
      "<b>Beneficiario de control directo:</b> Personas humanas las cuales poseen un 10% del capital o más de los derechos de voto de la persona jurídica en nombre de la cual se solicita la apertura de cuenta.",
  },
  secondListItem: {
    id: `${scope}.secondListItem`,
    defaultMessage:
      "<b>Control Indirecto:</b> Sólo en aquellos casos en los cuales el 10% o más del capital o de los derechos de voto de la persona jurídica se encuentren a su vez en poder de otra persona jurídica, deberán identificarse los beneficiarios finales de esta última (controlantes indirectos).",
  },
  beneficialOwnersTitle: {
    id: `${scope}.beneficialOwnersTitle`,
    defaultMessage: "Beneficiarios finales",
  },
  emptyBeneficial: {
    id: `${scope}.emptyBeneficial`,
    defaultMessage: "Todavía no tienes ningún beneficiario de control directo.",
  },
  addBeneficial: {
    id: `${scope}.addBeneficial`,
    defaultMessage: "AÑADIR BENEFICIARIO",
  },
  terms: {
    id: `${scope}.terms`,
    defaultMessage:
      "Declaro bajo juramento que los datos y documentos acompañados en la presente son veraces, completos, y que no se omite ni falsea dato alguno. Asimismo, me comprometo a actualizar los datos que anteceden cuando se produzcan modificaciones sobre los mismos o cuando sea requerido.",
  },
  modalTitle: {
    id: `${scope}.modalTitle`,
    defaultMessage: "¿Estás seguro?",
  },
  modalDescription: {
    id: `${scope}.modalDescription`,
    defaultMessage:
      "Si decides cerrar el formulario, no se guardará ninguna información del beneficiario y deberás cargarla nuevamente. ",
  },
  modalCancel: {
    id: `${scope}.modalCancel`,
    defaultMessage: "CERRAR",
  },
  modalBack: {
    id: `${scope}.modalBack`,
    defaultMessage: "ATRÁS",
  },
  idFrontLabel: {
    id: `${scope}.idFrontLabel`,
    defaultMessage: "Frente del documento",
  },
  idBackLabel: {
    id: `${scope}.idBackLabel`,
    defaultMessage: "Dorso del documento (Opcional)",
  },
  pepInfo: {
    id: `${scope}.pepInfo`,
    defaultMessage:
      "Esta respuesta tiene carácter de declaración jurada. Puedes{br}ver quiénes califican como PEP en este {link}",
  },
  modalBeneficiaryTitle: {
    id: `${scope}.modalBeneficiaryTitle`,
    defaultMessage: "¿Cargaste algún{br}beneficiario indirecto?",
  },
  modalBeneficiaryDescription: {
    id: `${scope}.modalBeneficiaryDescription`,
    defaultMessage:
      "Si dentro de las personas que cargaste se encuentra algún beneficiario indirecto, necesitaremos que cargues documentación sobre la cadena de control.",
  },
  modalBeneficiaryCancel: {
    id: `${scope}.modalBeneficiaryCancel`,
    defaultMessage: "NO",
  },
  modalBeneficiaryBack: {
    id: `${scope}.modalBeneficiaryBack`,
    defaultMessage: "SI",
  },

  civilStatusOptionSingle: {
    id: `${scope}.civilStatusOptionSingle`,
    defaultMessage: "Soltero",
  },
  civilStatusOptionMarried: {
    id: `${scope}.civilStatusOptionMarried`,
    defaultMessage: "Casado",
  },
  civilStatusOptionDivorced: {
    id: `${scope}.civilStatusOptionDivorced`,
    defaultMessage: "Divorciado",
  },
  civilStatusOptionWidowed: {
    id: `${scope}.civilStatusOptionWidowed`,
    defaultMessage: "Viudo",
  },

  professionStatusOptionStudent: {
    id: `${scope}.professionStatusOptionStudent`,
    defaultMessage: "Estudiante",
  },
  professionStatusOptionEmployee: {
    id: `${scope}.professionStatusOptionEmployee`,
    defaultMessage: "Empleado",
  },
  professionStatusOptionSelfEmployed: {
    id: `${scope}.professionStatusOptionSelfEmployed`,
    defaultMessage: "Autónomo",
  },
  professionStatusOptionRetired: {
    id: `${scope}.professionStatusOptionRetired`,
    defaultMessage: "Retirado",
  },
  professionStatusOptionUnemployed: {
    id: `${scope}.professionStatusOptionUnemployed`,
    defaultMessage: "Desempleado",
  },
});

export const civilStatusOptions = [
  {
    label: messages.civilStatusOptionSingle,
    id: "single",
  },
  {
    label: messages.civilStatusOptionMarried,
    id: "married",
  },
  {
    label: messages.civilStatusOptionDivorced,
    id: "divorced",
  },
  {
    label: messages.civilStatusOptionWidowed,
    id: "widowed",
  },
];

export const professionStatusOptions = [
  {
    label: messages.professionStatusOptionStudent,
    id: "student",
  },
  {
    label: messages.professionStatusOptionEmployee,
    id: "employee",
  },
  {
    label: messages.professionStatusOptionSelfEmployed,
    id: "self_employed",
  },
  {
    label: messages.professionStatusOptionRetired,
    id: "retired",
  },
  {
    label: messages.professionStatusOptionUnemployed,
    id: "unemployed",
  },
];
