import { defineMessages } from "react-intl";

export const scope = "pages.OnBoarding.components.SwornDeclarationUpload";

export const messages = defineMessages({
  description: {
    id: `${scope}.description`,
    defaultMessage:
      "Necesitaremos que firmes electrónicamente las declaraciones Juradas de Sujeto Obligado y de Control directo e indirecto que llegaron al mail del representante legal: {directorMemberEmail}",
  },
  dropzonePlaceholder: {
    id: `${scope}.dropzonePlaceholder`,
    defaultMessage: "Arrastra tu archivos .pdf aquí",
  },
  uploadButton: {
    id: `${scope}.uploadButton`,
    defaultMessage: "CARGAR ARCHIVO",
  },
  linkLabel: {
    id: `${scope}.linkLabel`,
    defaultMessage: "ENVIAR MAIL",
  },
  fstStepTitle: {
    id: `${scope}.fstStepTitle`,
    defaultMessage: "Abre tu casilla de correo",
  },
  fstStepDescription: {
    id: `${scope}.fstStepDescription`,
    defaultMessage:
      "Si no te llegó el mail, hacé click en el siguiente botón {link}",
  },
  sndStepTitle: {
    id: `${scope}.sndStepTitle`,
    defaultMessage: "Abre el mail de Esignature",
  },
  sndStepDescription: {
    id: `${scope}.sndStepDescription`,
    defaultMessage:
      "Dentro del mail encontrarás un botón que te lleva a completar y firmar las declaraciones juradas.",
  },
  thdStepTitle: {
    id: `${scope}.thdStepTitle`,
    defaultMessage: "Completa con la información y firma",
  },
  thdStepDescription: {
    id: `${scope}.thdStepDescription`,
    defaultMessage:
      "Agrega los datos que crees necesarios en los campos. Una vez completado y firmado, te llegará una copia a tu mail.",
  },
  fhtStepTitle: {
    id: `${scope}.fhtStepTitle`,
    defaultMessage: "Descarga y sube aquí el pdf",
  },
  fhtStepDescription: {
    id: `${scope}.fhtStepDescription`,
    defaultMessage:
      "Te llegará un nuevo mail con la copia del documento firmado la cual deberás cargar en esta pantalla.",
  },
});
