import { ReactComponent as InfoIconSvg } from "assets/images/icons/info.svg";
import { ReactNode } from "react";

export const InfoIcon: React.FC<{
  children?: ReactNode;
}> = ({ children }) => {
  return (
    <div className="mb-2 flex items-center">
      <InfoIconSvg className="mr-2 h-[24px] w-[24px]" />
      <p className="text-xs font-semibold text-[--milkylb-color-neutral-400]">
        {children}
      </p>
    </div>
  );
};
