import { FileInputProps } from "./types";
import { useRef, useState } from "react";
import { Modal } from "components/Modal";
import FileDropzone from "components/FileDropzone";
import { Button, Error } from "@letsbit/milkylb";
import { messages } from "./messages";
import { FormattedMessage } from "react-intl";
import { ReactComponent as UploadIcon } from "assets/images/icons/upload.svg";
import { ReactComponent as ArrowIcon } from "assets/images/icons/arrow.svg";
import { DropzoneRef } from "react-dropzone";

import "./styles.scss";

export const FileInput: React.FC<FileInputProps> = ({
  file,
  onChange,
  error,
}) => {
  const [fileModal, setFileModal] = useState(false);
  const dropzoneRef = useRef<DropzoneRef>(null);

  return (
    <div className="file-input">
      <Modal
        isOpen={fileModal}
        onDidDismiss={() => setFileModal(false)}
        withArrow={false}
        classNameModalWrapper="file-input__modal"
      >
        <FileDropzone
          files={file ? [file] : []}
          onChange={(newFile: File[] | undefined) => {
            if (newFile) {
              setFileModal(false);
              onChange(newFile[0]);
            }
          }}
        >
          <div className="flex w-80 flex-col items-center justify-items-center">
            <UploadIcon className="mb-4" />
            <h6 className="mb-10 font-['Tomato_Grotesk'] text-lg text-[--milkylb-color-neutral-200]">
              <FormattedMessage {...messages.dropzonePlaceholder} />
            </h6>
            <Button
              className="mb-2"
              leftIcon={ArrowIcon}
              onClick={() => dropzoneRef.current?.open()}
              type="button"
              fill="outline"
            >
              <FormattedMessage {...messages.uploadButton} />
            </Button>
          </div>
        </FileDropzone>
      </Modal>
      <div className="flex w-full items-center justify-between gap-2 ">
        <div className="flex flex-col">
          {file && (
            <label className="text-sm font-bold text-[--milkylb-color-neutral-600]">
              <FormattedMessage {...messages.selectedFileLabel} />
            </label>
          )}
          <label className="text-sm text-[--milkylb-color-neutral-400]">
            {file ? file.name : <FormattedMessage {...messages.noDocLabel} />}
          </label>
        </div>
        {!file ? (
          <Button type="button" fill="clear" onClick={() => setFileModal(true)}>
            <FormattedMessage {...messages.loadButton} />
          </Button>
        ) : (
          <Button
            type="button"
            fill="clear"
            onClick={() => onChange(undefined)}
            color="warning"
          >
            <FormattedMessage {...messages.deleteButton} />
          </Button>
        )}
      </div>
      <Error error={!!error} message={error} />
    </div>
  );
};
