import React, { ReactNode } from "react";
import { JuridicalPersonDataForm } from "@letsbit/malcolmlb";

export const JPDContext = React.createContext<
  JuridicalPersonDataForm | undefined
>(undefined);

export type Props = {
  children: ReactNode;
  value?: JuridicalPersonDataForm;
};

const JPDProvider = (props: Props) => {
  const { children, value } = props;

  return <JPDContext.Provider value={value}>{children}</JPDContext.Provider>;
};

export default JPDProvider;
