import React from "react";
import { FormattedMessage } from "react-intl";

import { ReactComponent as SuccessImage } from "assets/images/onboarding/success.svg";
import { LegalRepresentativeWarningCard } from "../../components/LegalRepresentativeWarningCard";

import { messages } from "./messages";

export const SuccessContent: React.FC = () => {
  return (
    <div className="flex h-full flex-col items-center justify-center text-center">
      <SuccessImage className="mb-11" />
      <h2 className="mb-11 w-full font-['Tomato_Grotesk'] text-4xl font-bold text-[--milkylb-color-neutral-600]">
        <FormattedMessage {...messages.title} />
      </h2>
      <p className="mb-4 text-base text-[--milkylb-color-neutral-600]">
        <FormattedMessage
          {...messages.fstSubtitle}
          values={{
            br: <br />,
          }}
        />
      </p>
      <p className="mb-11 text-base text-[--milkylb-color-neutral-600]">
        <FormattedMessage {...messages.sndSubtitle} />
      </p>
      <LegalRepresentativeWarningCard className="mb-8 max-w-md	text-left" />
    </div>
  );
};
