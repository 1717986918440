import { Modal } from "components/Modal";
import { InfoRequestModalProps } from "./types";
import { useState, useEffect, useRef, useContext } from "react";
import { useIntl } from "react-intl";
import { useNavigate } from "react-router-dom";
import { useGetInformationRequests } from "hooks/compliance/useGetInformationRequests";
import InfoScreen from "components/InfoScreen";
import CustomFormattedMessage from "components/CustomFormattedMessage";
import { UserContext } from "providers/UserProvider";
import Icon from "assets/images/info-request.svg";

import { messages } from "./messages";

const DISABLED_IR_PATHS = [
  "/logged/onboarding",
  "/logged/profile/information-requests",
];

const InfoRequestModal: React.FC<InfoRequestModalProps> = () => {
  const [isOpen, setIsOpen] = useState(false);
  const didLoad = useRef<boolean>(false);
  const { awaitingMember } = useGetInformationRequests();
  const { user } = useContext(UserContext);
  const { formatMessage } = useIntl();
  const navigate = useNavigate();

  useEffect(() => {
    if (
      awaitingMember &&
      awaitingMember.length > 0 &&
      awaitingMember.some((ir) => !ir.self_assigned) &&
      !didLoad.current
    ) {
      setIsOpen(true);
      didLoad.current = true;
    }
  }, [awaitingMember, didLoad]);

  const modalOpen =
    isOpen &&
    !!user &&
    !DISABLED_IR_PATHS.find((path) => location.pathname.includes(path));

  return (
    <Modal
      isOpen={modalOpen}
      canDismiss={!modalOpen}
      classNameModalWrapper="letsbit-tight-modal"
    >
      <InfoScreen
        picture={Icon}
        chatButton
        info={
          <div className="flex flex-col gap-4">
            <h2 className="text-center text-[32px]/[38.4px] font-bold text-[#5F259F]">
              <CustomFormattedMessage {...messages.title} />
            </h2>
            <p>
              <CustomFormattedMessage {...messages.description} />
            </p>
          </div>
        }
        filledButton={{
          text: formatMessage(messages.goToDocumentationButton),
          onSubmit: () => {
            navigate("/logged/profile/information-requests");
            setIsOpen(false);
          },
        }}
        clearButton={{
          text: formatMessage(messages.skipButton),
          onSubmit: () => {
            setIsOpen(false);
          },
        }}
      />
    </Modal>
  );
};

export default InfoRequestModal;
