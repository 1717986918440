import { LegalAddress } from "@letsbit/malcolmlb";
import { getDetails } from "use-places-autocomplete";

import { useNotification } from "./useNotification";
import { NotificationStatus } from "components/Notification/types";
import { defineMessages, useIntl } from "react-intl";

export interface AddressPrediction {
  addressPrediction: {
    description: string;
    legalAddress: Partial<LegalAddress>;
  };
}

export const scope = "hook.UsePlaceDetails";

export const messages = defineMessages({
  cantGetPlaceDetails: {
    id: `${scope}.cantGetPlaceDetails`,
    defaultMessage:
      "No pudimos seleccionar dirección, por favor reinténtalo más tarde.",
  },
  notificationTitle: {
    id: `${scope}.notificationTitle`,
    defaultMessage: "Ubicación",
  },
});

export interface PlaceDetails extends LegalAddress {
  country: string;
}

export const usePlaceDetails = (): {
  getPlaceDetails: (
    cantGetPlaceDetailsError: string,
    place_id: string,
  ) => Promise<PlaceDetails | undefined>;
} => {
  const MAX_CITY_LENGTH = 40;
  const { showNotification } = useNotification();
  const { formatMessage } = useIntl();
  const getPlaceDetails = async (
    _cantGetPlaceDetailsError: string,
    place_id: string,
  ): Promise<PlaceDetails | undefined> => {
    let legalAddress: PlaceDetails = {
      street_name: "",
      street_number: "",
      neighborhood: "-",
      province: "",
      postcode: "",
      city: "",
      country: "",
    };
    try {
      const sessionToken = new google.maps.places.AutocompleteSessionToken();

      const details = await getDetails({
        placeId: place_id,
        sessionToken,
        fields: ["address_component"],
      });

      if (typeof details === "string") {
        showNotification({
          title: formatMessage(messages.notificationTitle),
          message: formatMessage(messages.cantGetPlaceDetails),
          status: NotificationStatus.ERROR,
        });
        throw new Error("No encontro direccion");
      } else {
        details.address_components?.forEach(
          (ad: google.maps.GeocoderAddressComponent) => {
            if (ad.types.includes("country")) {
              legalAddress = { ...legalAddress, country: ad.long_name };
            }
            if (ad.types.includes("route")) {
              legalAddress = { ...legalAddress, street_name: ad.long_name };
            }
            if (ad.types.includes("street_number")) {
              legalAddress = { ...legalAddress, street_number: ad.long_name };
            }
            if (ad.types.includes("neighborhood")) {
              legalAddress = {
                ...legalAddress,
                neighborhood:
                  legalAddress.neighborhood &&
                  legalAddress.neighborhood !== ad.long_name
                    ? ad.long_name + " " + legalAddress.neighborhood
                    : ad.long_name,
              };
            }
            if (ad.types.includes("sublocality_level_1")) {
              legalAddress = {
                ...legalAddress,
                neighborhood:
                  legalAddress.neighborhood &&
                  legalAddress.neighborhood !== ad.long_name
                    ? ad.long_name + " " + legalAddress.neighborhood
                    : ad.long_name,
              };
            }
            if (ad.types.includes("postal_code")) {
              legalAddress = { ...legalAddress, postcode: ad.long_name };
            }
            if (ad.types.includes("locality")) {
              legalAddress = {
                ...legalAddress,
                city:
                  legalAddress.city && legalAddress.city !== ad.long_name
                    ? ad.long_name + " " + legalAddress.city
                    : ad.long_name,
              };
            }
            if (ad.types.includes("administrative_area_level_2")) {
              const auxCity = ad.long_name + " " + legalAddress.city;
              legalAddress = {
                ...legalAddress,
                city:
                  legalAddress.city && legalAddress.city !== ad.long_name
                    ? auxCity.length <= MAX_CITY_LENGTH
                      ? auxCity
                      : legalAddress.city
                    : ad.long_name,
              };
            }
            if (ad.types.includes("administrative_area_level_1")) {
              legalAddress = { ...legalAddress, province: ad.long_name };
            }
          },
        );
        return legalAddress;
      }
    } catch (e) {
      showNotification({
        title: formatMessage(messages.notificationTitle),
        message: formatMessage(messages.cantGetPlaceDetails),
        status: NotificationStatus.ERROR,
      });
    }
  };
  return { getPlaceDetails };
};
