import ITrackingProvider from "./trackingProvider";
import posthog from "posthog-js";

class PostHogAdapter implements ITrackingProvider {
  private posthog: typeof posthog;

  constructor(posthogInstance: typeof posthog) {
    this.posthog = posthogInstance;
  }

  public identify(userId: string, properties?: Record<string, any>): void {
    this.posthog.identify(userId, properties);
  }

  public capture(eventName: string, properties?: Record<string, any>): void {
    this.posthog.capture(eventName, properties);
  }
}

export default PostHogAdapter;
