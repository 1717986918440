import { useContext, useState } from "react";
import { ApiContext } from "providers/ApiProvider";
import { defineMessages, useIntl } from "react-intl";
import { useNotification } from "hooks/utils/useNotification";
import { NotificationStatus } from "components/Notification/types";
import { JuridicalPersonDataForm } from "@letsbit/malcolmlb";

export const scope = "hook.useCreateJuridicalPersonData";

export const messages = defineMessages({
  defaultErrorNotificationMessage: {
    id: `${scope}.defaultErrorNotificationMessage`,
    defaultMessage: "No pudimos subir tu información, intentalo de nuevo mas tarde",
  },
  notificationTitle: {
    id: `${scope}.notificationTitle`,
    defaultMessage: "Datos de la empresa",
  },
});

export const useCreateJuridicalPersonData = () => {
  const { memberInstance } = useContext(ApiContext)
  const [loading, setLoading] = useState(false);
  const { showNotification } = useNotification();
  const { formatMessage } = useIntl();

  const createJuridicalPersonData = async (
    form: JuridicalPersonDataForm
  ): Promise<void> => {
    setLoading(true)
    try {
      await memberInstance.compliance.submitJuridicalPerson({
        ...form,
        attached_documentation: form.attached_documentation || "empty",
      })

    } catch (error) {
      console.error(error);
      showNotification({
        title: formatMessage(messages.notificationTitle),
        message: formatMessage(messages.defaultErrorNotificationMessage),
        status: NotificationStatus.ERROR
      })
      throw error;
    } finally {
      setLoading(false)
    }
  };

  return {
    createJuridicalPersonData,
    loading,
  };
};
