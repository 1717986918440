import { defineMessages } from "react-intl";

export const scope = "pages.Withdraw.components.AddressInput";

export const messages = defineMessages({
  title: {
    id: `${scope}.modalTitle`,
    defaultMessage: "Dirección",
  },
  incompleteDataTitle: {
    id: `${scope}.incompleteDataTitle`,
    defaultMessage: "Tu dirección está incompleta",
  },
  incompleteDataMessage: {
    id: `${scope}.incompleteDataMessage`,
    defaultMessage:
      "Te pedimos por favor que ingreses los datos manualmente o reintentes la selección. {br}{br} Asegúrate seleccionar la opción que contenga Calle, Número, Ciudad y Provincia.",
  },
  incompleteRegionCityTitle: {
    id: `${scope}.incompleteRegionCityTitle`,
    defaultMessage: "Ingresa tu país, provincia y ciudad",
  },
  chooseCountry: {
    id: `${scope}.chooseCountry`,
    defaultMessage: "Selecciona tu pais",
  },
  chosseRegion: {
    id: `${scope}.chosseRegion`,
    defaultMessage: "Selecciona tu region",
  },
  chooseProvince: {
    id: `${scope}.chooseProvince`,
    defaultMessage: "Selecciona tu provincia",
  },
  chooseCity: {
    id: `${scope}.chooseCity`,
    defaultMessage: "Ingresa tu ciudad",
  },
  chooseStreetName: {
    id: `${scope}.chooseStreetName`,
    defaultMessage: "Ingresa tu calle",
  },
  chooseStreetNumber: {
    id: `${scope}.chooseStreetNumber`,
    defaultMessage: "Ingresa tu número",
  },
  placeholder: {
    id: `${scope}.placeholder`,
    defaultMessage: "Ingresa la dirección",
  },
  placeholderSearch: {
    id: `${scope}.placeholderSearch`,
    defaultMessage: "Buscá calle, número, localidad y país",
  },
  cantGetPlaceDetails: {
    id: `${scope}.cantGetPlaceDetails`,
    defaultMessage:
      "No pudimos seleccionar dirección, por favor reinténtalo más tarde.",
  },
  continueButton: {
    id: `${scope}.continueButton`,
    defaultMessage: "CONTINUAR",
  },
  retryButton: {
    id: `${scope}.retryButton`,
    defaultMessage: "REINTENTAR",
  },
  manualInputButton: {
    id: `${scope}.manualInputButton`,
    defaultMessage: "INGRESAR MANUALMENTE",
  },
  floorPlaceholder: {
    id: `${scope}.floorPlaceholder`,
    defaultMessage: "Ingresa tu piso",
  },
  apartmentPlaceholder: {
    id: `${scope}.apartmentPlaceholder`,
    defaultMessage: "Ingresa tu departamento",
  },
  adtionalReferencesPlaceholder: {
    id: `${scope}.adtionalReferencesPlaceholder`,
    defaultMessage: "Ingresa referencias adicionales",
  },
  postcodePlaceholder: {
    id: `${scope}.postcodePlaceholder`,
    defaultMessage: "Ingresa código postal",
  },
});
