import { defineMessages } from "react-intl";

export const scope = "components.PrecogsFirstSlide";

export const messages = defineMessages({
  filledButton: {
    id: `${scope}.filledButton`,
    defaultMessage: "COMENZAR",
  },
  clearButton: {
    id: `${scope}.clearButton`,
    defaultMessage: "SALTAR",
  },
});
