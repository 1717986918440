import { useEffect, useRef } from "react";

export const useOnLeavePage = ({
  onUnloadPage,
  onBackPage,
}: {
  onUnloadPage?: (e: BeforeUnloadEvent) => void;
  onBackPage?: (e: PopStateEvent) => void;
}) => {
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    const handleBeforeUnload = (e: BeforeUnloadEvent) => {
      return onUnloadPage?.(e);
    };

    const handleOnPopState = (e: PopStateEvent) => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }

      timeoutRef.current = setTimeout(function () {
        return onBackPage?.(e);
      }, 50);
    };

    window.addEventListener("beforeunload", handleBeforeUnload);
    window.addEventListener("popstate", handleOnPopState);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
      window.removeEventListener("popstate", handleOnPopState);
    };
  }, [onBackPage, onUnloadPage]);
};
