import { Card } from "@letsbit/milkylb";
import classNames from "classnames";
import { UltimateBeneficialCardProps } from "../types";
import { ReactComponent as EditIcon } from "assets/images/icons/edit.svg";
import { ReactComponent as DeleteIcon } from "assets/images/icons/delete.svg";

export const UltimateBeneficialCard: React.FC<UltimateBeneficialCardProps> = ({
  ultimateBeneficialOwner,
  className,
  onDelete,
  onEdit,
}) => {
  return (
    <Card
      className={classNames(className)}
      title={`${ultimateBeneficialOwner.first_name} ${ultimateBeneficialOwner.last_name}`}
      subtitle={ultimateBeneficialOwner.national_id}
    >
      <div className="flex justify-end gap-x-2">
        <EditIcon
          className="cursor-pointer"
          onClick={() => onEdit(ultimateBeneficialOwner)}
        />
        <DeleteIcon
          className="UltimateBeneficialCard__delete cursor-pointer"
          onClick={onDelete}
        />
      </div>
    </Card>
  );
};
