import { EVENT_TRACKER_EVENT_NAMES } from "@letsbit/malcolmlb";
import ITrackingProvider from "./trackingProvider";

class EventTracker {
  private providers: ITrackingProvider[];

  constructor(providers: ITrackingProvider[]) {
    this.providers = providers;
  }

  identify(userId: string, properties: Record<string, any>): void {
    this.providers.forEach((provider) => {
      provider.identify(userId, properties);
    });
  }

  capture(
    eventName: EVENT_TRACKER_EVENT_NAMES,
    properties: Record<string, any>,
  ): void {
    this.providers.forEach((provider) => {
      provider.capture(eventName, properties);
    });
  }
}

export default EventTracker;
