import {
  Button,
  Input,
  MultiLineInput,
} from "@letsbit/milkylb";
import React from "react";
import { useIntl, FormattedMessage } from "react-intl";

import { messages } from "./messages";
import { CompanyDataFormProps, CompanyDataForm as CompForm } from "./types";
import classNames from "classnames";
import { Controller, useForm } from "react-hook-form";
import { formMessages } from "utils/formMessages";
import DatePicker from "components/DatePicker/DatePicker";
import dayjs from "dayjs";

export const CompanyDataForm: React.FC<CompanyDataFormProps> = ({
  onSubmit,
  className,
  form,
}) => {
  const { handleSubmit, control } = useForm<CompForm>({
    mode: "onBlur",
    defaultValues: form,
  });
  const { formatMessage } = useIntl();
  const maxLengthActivity = 500;

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className={classNames(
        className,
        "fadeInSimple relative flex flex-col items-center justify-center"
      )}
      data-cypress-id="company__data-form"
    >
      <div className="h-full w-full">
        <Controller
          rules={{
            required: formatMessage(formMessages.requiredFieldError),
            maxLength: {
              value: maxLengthActivity,
              message: formatMessage(formMessages.activityMaxLengthError),
            },
          }}
          control={control}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <div>
              <label className="text-lg font-normal text-[--color-neutral-0]">
                <FormattedMessage {...messages.activityLabel} />
              </label>
              <MultiLineInput
                placeholder={formatMessage(messages.activityPlaceholder)}
                value={value}
                onIonInput={onChange}
                error={!!error}
                messageError={error?.message}
                data-cypress-id="company__data-main__activity__input"
              />
            </div>
          )}
          name="activity"
        />
        <Controller
          control={control}
          rules={{
            required: formatMessage(formMessages.requiredFieldError),
          }}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <div>
              <label className="text-lg font-normal text-[--color-neutral-0]">
                <FormattedMessage {...messages.inscriptionDateLabel} />
              </label>
              <DatePicker
                floatingLabel={formatMessage(formMessages.chooseDate)}
                value={value ? dayjs(value) : null}
                onChange={(event) => {
                  onChange(event?.toString());
                }}
                error={error}
                dataCypressId="company__data-date__picker"
              />
            </div>
          )}
          name="registration_date"
        />
        <Controller
          control={control}
          rules={{
            required: formatMessage(formMessages.requiredFieldError),
          }}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <div>
              <label className="text-lg font-normal text-[--color-neutral-0]">
                <FormattedMessage {...messages.registrationNumberLabel} />
              </label>
              <Input
                placeholder={formatMessage(
                  messages.registrationNumberPlaceholder
                )}
                value={value}
                onIonInput={onChange}
                error={!!error}
                message={error?.message}
                data-cypress-id="company__data-registration__number"
              />
            </div>
          )}
          name="registration_number"
        />
      </div>
      <Button
        type="submit"
        style={{ width: 197 }}
        className="mt-8 w-full self-end"
      >
        <FormattedMessage {...messages.continueButton} />
      </Button>
    </form>
  );
};
