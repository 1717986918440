import { FormattedMessage } from "react-intl";
import { AlertCard } from "@letsbit/milkylb";

import { messages } from "./messages";
import { LegalRepresentativeWarningCardProps } from "./types";

export const LegalRepresentativeWarningCard: React.FC<
  LegalRepresentativeWarningCardProps
> = ({ className }) => {
  return (
    <AlertCard
      className={className}
      text={
        <FormattedMessage
          {...messages.alertText}
          values={{
            bold: (
              <b>
                <FormattedMessage {...messages.alertTextBold} />
              </b>
            ),
            link: (
              <a
                style={{ textDecoration: "underline" }}
                href={window.env.downloadAppLink}
                target="_blank"
              >
                <FormattedMessage {...messages.linkText} />
              </a>
            ),
          }}
        />
      }
    />
  );
};
