import { useCallback } from "react";
import { useSWRConfig, mutate } from "swr";

export const useMutateAll = (): {
  mutateAll: (key: string) => Promise<void>;
} => {
  const { cache } = useSWRConfig();

  const mutateAll = useCallback(
    async (key: string) => {
      if (!(cache instanceof Map)) {
        return;
      }
      const keys = [];
      for (const entry of Array.from(cache.entries())) {
        if (typeof entry[0] === "string" && entry[0].includes(key)) {
          keys.push(entry[0]);
        }
      }

      try {
        await Promise.all(
          keys.map((key) =>
            mutate(key, undefined, {
              revalidate: true,
            }),
          ),
        );
      } catch (e) {
        console.log(e);
      }
    },
    [cache],
  );

  return { mutateAll };
};
