import { Button, Error } from "@letsbit/milkylb";
import React, { useRef } from "react";
import { useIntl, FormattedMessage } from "react-intl";

import { ReactComponent as ArrowIcon } from "assets/images/icons/arrow.svg";
import { ReactComponent as UploadIcon } from "assets/images/icons/upload.svg";
import { ReactComponent as EmailIcon } from "assets/images/icons/email.svg";
import { messages } from "./messages";
import { SwornDeclarationUploadProps } from "./types";
import classNames from "classnames";
import { Controller, useForm } from "react-hook-form";
import { formMessages } from "utils/formMessages";
import FileDropzone from "components/FileDropzone";
import { DropzoneRef } from "react-dropzone";
import { useSendEsignatureContract } from "hooks/user/useSendEsignatureContract";
import { Step } from "../../../../pages/LoggedRoutes/OnBoardingRoutes/components/Stepper/types";
import { Stepper } from "../../../../pages/LoggedRoutes/OnBoardingRoutes/components/Stepper";
import { RejectedReasonWarning } from "../RejectedReasonWarning";

export const SwornDeclarationUpload: React.FC<SwornDeclarationUploadProps> = ({
  onSubmit,
  formId,
  className,
  directorMemberEmail,
  children,
  informationRequest,
}) => {
  const dropzoneRef = useRef<DropzoneRef>(null);
  const { handleSubmit, control } = useForm<{
    files: File[];
  }>({
    mode: "onBlur",
  });
  const { formatMessage } = useIntl();
  const { sendContract } = useSendEsignatureContract();

  const steps: Step[] = [
    {
      idx: "1",
      title: <FormattedMessage {...messages.fstStepTitle} />,
      description: (
        <div className="flex flex-wrap">
          <FormattedMessage
            {...messages.fstStepDescription}
            values={{
              link: (
                <a
                  className="ml-6 flex items-center justify-center text-[--milkylb-color-secondary-400]"
                  href="#"
                  onClick={() => sendContract()}
                >
                  <EmailIcon className="mr-2" />
                  <FormattedMessage {...messages.linkLabel} />
                </a>
              ),
            }}
          />
        </div>
      ),
    },
    {
      idx: "2",
      title: <FormattedMessage {...messages.sndStepTitle} />,
      description: <FormattedMessage {...messages.sndStepDescription} />,
    },
    {
      idx: "3",
      title: <FormattedMessage {...messages.thdStepTitle} />,
      description: <FormattedMessage {...messages.thdStepDescription} />,
    },
    {
      idx: "4",
      title: <FormattedMessage {...messages.fhtStepTitle} />,
      description: <FormattedMessage {...messages.fhtStepDescription} />,
    },
  ];

  return (
    <form
      id={formId}
      onSubmit={handleSubmit((data) =>
        onSubmit({
          id: informationRequest.id.toString(),
          files: data.files,
        }),
      )}
      className={classNames(
        className,
        "fadeInSimple flex h-full w-full gap-10",
      )}
    >
      <div className="flex-1">
        {children}
        {informationRequest && informationRequest.comments && (
          <RejectedReasonWarning informationRequest={informationRequest} />
        )}
        <p className="mb-10 text-[--milkylb-color-neutral-600]">
          <FormattedMessage
            {...messages.description}
            values={{
              directorMemberEmail,
            }}
          />
        </p>
        <Stepper steps={steps} />
      </div>
      <Controller
        control={control}
        rules={{
          required: formatMessage(formMessages.requiredFieldError),
        }}
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <div className="flex-1">
            <div className="h-full">
              <FileDropzone
                acceptedExtensions={["application/pdf"]}
                files={value}
                onChange={(files) => onChange(files)}
                dropzoneRef={dropzoneRef}
                fileDisplay={true}
                noClick
              >
                <div className="flex w-80 flex-col items-center justify-items-center">
                  <UploadIcon className="mb-4" />
                  <h6 className="mb-10 font-['Tomato_Grotesk'] text-lg text-[--milkylb-color-neutral-200]">
                    <FormattedMessage {...messages.dropzonePlaceholder} />
                  </h6>
                  <Button
                    className="mb-2"
                    leftIcon={ArrowIcon}
                    onClick={() => dropzoneRef.current?.open()}
                    type="button"
                  >
                    <FormattedMessage {...messages.uploadButton} />
                  </Button>
                </div>
              </FileDropzone>
            </div>
            <Error error={!!error?.message} message={error?.message} />
          </div>
        )}
        name="files"
      />
    </form>
  );
};
