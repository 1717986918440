import React, { useState, ReactNode, useEffect, useCallback } from "react";
import { Location, useLocation } from "react-router-dom";
import { createVoidContext } from "utils/voidContext";

interface LocationContext {
  location: Location;
  displayLocation: Location;
  addPrefix: (prefix: string) => void;
  setDisplayLocation: React.Dispatch<React.SetStateAction<Location>>;
  transitionStage: "fadeIn" | "fadeOut";
  setTransistionStage: React.Dispatch<
    React.SetStateAction<"fadeIn" | "fadeOut">
  >;
}

export const LocationContext = React.createContext<LocationContext>(
  createVoidContext("location-context")
);

export type Props = {
  children: ReactNode;
};

const LocationProvider = (props: Props) => {
  const { children } = props;
  const location = useLocation();
  const [prefixes, setPrefixes] = useState<string[]>([]);

  const [displayLocation, setDisplayLocation] = useState(location);
  const [transitionStage, setTransistionStage] = useState<"fadeIn" | "fadeOut">(
    "fadeIn"
  );

  const addPrefix = useCallback((prefix: string) => {
    setPrefixes((value) => [...value, prefix]);
  }, []);

  useEffect(() => {
    if (
      // This means that you are navigating between pages that has the behavior of entering and leaving
      // with fade effects
      prefixes.some((prefix) => displayLocation.pathname.startsWith(prefix)) &&
      location.pathname !== displayLocation.pathname
    ) {
      setTransistionStage("fadeOut");
      setTimeout(() => {
        setTransistionStage("fadeIn");
        setDisplayLocation(location);
      }, 300);
    } else if (location.pathname !== displayLocation.pathname) {
      setDisplayLocation(location);
    }
  }, [location, displayLocation, prefixes]);

  const contextValue = {
    location,
    displayLocation,
    setDisplayLocation,
    transitionStage,
    setTransistionStage,
    addPrefix,
  };

  return (
    <LocationContext.Provider value={contextValue}>
      {children}
    </LocationContext.Provider>
  );
};

export default LocationProvider;
