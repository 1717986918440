import { DocumentType, PERSON_TYPES } from "@letsbit/malcolmlb";
import { useContext } from "react";
import useSWR, { SWRResponse } from "swr";
import { ApiContext } from "providers/ApiProvider";

export const SWR_GET_ON_BOARDING_COUNTRIES = "get-onboarding-countries";

export type OnboardingCountries = Record<
  string,
  Omit<DocumentType, "country">[]
>;

export const useGetOnboardingCountries = (
  personType?: PERSON_TYPES,
): SWRResponse<OnboardingCountries, Error> => {
  const { publicInstance } = useContext(ApiContext);
  const swr = useSWR(personType && SWR_GET_ON_BOARDING_COUNTRIES, async () => {
    const { data } = await publicInstance.compliance.getDocumentType({
      person_type: personType,
    });

    const onboardingCountries: OnboardingCountries = {};
    data.forEach(({ country, ...rest }) => {
      if (onboardingCountries[country]) {
        onboardingCountries[country].push(rest);
      } else {
        onboardingCountries[country] = [rest];
      }
    });

    return onboardingCountries;
  });

  return swr;
};
