import React, { PropsWithChildren } from "react";
import Dropzone from "react-dropzone";
import classnames from "classnames";
import { ReactComponent as CrossIcon } from "assets/images/icons/cross.svg";
import { Error } from "@letsbit/milkylb";

import { FileDropzoneProps, KB_TO_MB_RATIO } from "./types";
import { FormattedMessage } from "react-intl";
import { messages } from "./messages";

export const FileDropzone: React.FC<PropsWithChildren<FileDropzoneProps>> = ({
  files,
  onChange,
  error,
  onBlur,
  className,
  acceptedExtensions,
  inputName,
  noClick,
  fileDisplay = false,
  dropzoneRef,
  children,
}) => {
  return (
    <>
      <div
        className={classnames("w-full", {
          "h-2/3": fileDisplay,
          "h-full": !fileDisplay,
        })}
      >
        <Dropzone
          ref={dropzoneRef}
          accept={acceptedExtensions?.reduce((acum, value) => {
            return {
              ...acum,
              [value]: [],
            };
          }, {})}
          noClick={noClick}
          onDrop={(e) => {
            if (e.length) {
              files !== undefined
                ? onChange([...files, ...e])
                : onChange([...e]);
            }
          }}
        >
          {({ getRootProps, getInputProps }) => (
            <>
              <div
                {...getRootProps()}
                className={classnames(
                  className,
                  error &&
                    "border-[--milkylb-color-red-error] hover:border-[--milkylb-color-red-error]",
                  "flex items-center justify-evenly",
                  !noClick && "cursor-pointer",
                  "h-full rounded-[32px] border border-dashed border-[--milkylb-color-secondary-200] outline-none transition-[border-color] hover:border-[--milkylb-color-secondary-600]"
                )}
              >
                <input
                  {...getInputProps()}
                  name={inputName}
                  onBlur={onBlur}
                  multiple={true}
                />
                {!fileDisplay && files && files.length > 0
                  ? files.map((f: File) => (
                      <div
                        key={f.name}
                        className="flex items-center justify-between text-[--milkylb-color-neutral-600]"
                      >
                        <p
                          className={classnames(
                            "text-xs",
                            error && "text-[--milkylb-color-red-error]"
                          )}
                        >
                          {`• ${f.name}`}
                          {` (${(f.size / KB_TO_MB_RATIO).toFixed(2)} mb)`}
                        </p>
                        <button
                          onClick={(e) => {
                            e.stopPropagation();
                            onChange(
                              files?.filter((file) => file.name !== f.name)
                            );
                          }}
                          type="button"
                        >
                          <CrossIcon
                            className={classnames(
                              "h-5 w-5 fill-[--milkylb-color-neutral-600]",
                              error && "fill-[--milkylb-color-red-error]"
                            )}
                          />
                        </button>
                      </div>
                    ))
                  : children}
              </div>
            </>
          )}
        </Dropzone>
        <Error error={!!error} message={error} />
      </div>
      <div className="flex h-1/3 w-full flex-col gap-y-4 pt-8">
        <h3 className="text-lg text-[--milkylb-color-neutral-600]">
          <FormattedMessage {...messages.loadedFiles} />
        </h3>
        {fileDisplay && files && files.length > 0 ? (
          files.map((f: File) => (
            <div
              key={f.name}
              className="flex items-center justify-between text-[--milkylb-color-neutral-600]"
            >
              <p
                className={classnames(
                  "text-xs",
                  error && "text-[--milkylb-color-red-error]"
                )}
              >
                {`• ${f.name}`}
                {` (${(f.size / KB_TO_MB_RATIO).toFixed(2)} mb)`}
              </p>
              <button
                onClick={(e) => {
                  e.stopPropagation();
                  onChange(files?.filter((file) => file.name !== f.name));
                }}
                type="button"
              >
                <CrossIcon
                  className={classnames(
                    "h-5 w-5 fill-[--milkylb-color-neutral-600]",
                    error && "fill-[--milkylb-color-red-error]"
                  )}
                />
              </button>
            </div>
          ))
        ) : (
          <h3 className="text-base text-[--milkylb-color-neutral-400]">
            <FormattedMessage {...messages.emptyCaseFiles} />
          </h3>
        )}
      </div>
    </>
  );
};

export default FileDropzone;
