import { defineMessages } from "react-intl";

export const scope = "components.HisotryAdvancedSearch";

export const messages = defineMessages({
  cancelDate: {
    id: `${scope}.cancelDate`,
    defaultMessage: "CANCELAR",
  },
  acceptDate: {
    id: `${scope}.acceptDate`,
    defaultMessage: "ACEPTAR",
  },
});
