import classNames from "classnames";
import { BackButtonProps } from "./types";
import { FormattedMessage } from "react-intl";
import { ReactComponent as SimpleArrowIcon } from "assets/images/icons/simple-arrow.svg";
import { messages } from "./messages";
import { useNavigate } from "react-router-dom";

export const BackButton: React.FC<BackButtonProps> = ({
  className,
  onClick,
}) => {
  const navigate = useNavigate();

  return (
    <label
      onClick={() => (onClick ? onClick() : navigate(-1))}
      data-cypress-id="back-button"
      className={classNames(
        className,
        "flex cursor-pointer items-center text-sm font-semibold text-[--milkylb-color-secondary-400]"
      )}
    >
      <SimpleArrowIcon className="mr-2" />
      <FormattedMessage {...messages.label} />
    </label>
  );
};
