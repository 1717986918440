import { defineMessages } from "react-intl";

export const scope = "pages.OnBoarding.components.InformationRequestCard";

export const messages = defineMessages({
  uploadLabel: {
    id: `${scope}.uploadLabel`,
    defaultMessage: "Enviado",
  },
  rejectedLabel: {
    id: `${scope}.rejectedLabel`,
    defaultMessage: "Rechazado",
  },
  aprovedLabel: {
    id: `${scope}.aprovedLabel`,
    defaultMessage: "Aprobado",
  },
  updatedLabel: {
    id: `${scope}.updatedLabel`,
    defaultMessage: "Actualizado",
  },
  awaitingAdminLabel: {
    id: `${scope}.awaitingAdminLabel`,
    defaultMessage: "En revisión",
  },
  fileLabel: {
    id: `${scope}.filesLabel`,
    defaultMessage: "archivo",
  },
  filesLabel: {
    id: `${scope}.filesLabel`,
    defaultMessage: "archivos",
  },
});
