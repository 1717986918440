import React, { useCallback, useMemo } from "react";
import { Controller, FieldError } from "react-hook-form";
import { useIntl } from "react-intl";
import { TextFieldTypes } from "@ionic/core";

import {
  FLAG_FORM_INPUT_TYPE,
  PRECOGS_FLAG_FORM_TYPE,
} from "@letsbit/malcolmlb";
import {
  CheckboxItem,
  DateTimeSelector,
  Error,
  Input,
  OptionSelector,
  RadioGroup,
  RadioItem,
  Toggle,
} from "@letsbit/milkylb";

import { PrecogsFormItemProps } from "./types";
import { messages } from "./messages";

export const PrecogsFormItem: React.FC<PrecogsFormItemProps> = ({
  formItem,
  control,
  disableValidation,
}) => {
  const intl = useIntl();

  const getInputType = (
    type: FLAG_FORM_INPUT_TYPE,
  ): {
    type: TextFieldTypes;
    inputMode:
      | "none"
      | "text"
      | "tel"
      | "url"
      | "email"
      | "numeric"
      | "decimal"
      | "search";
  } => {
    switch (type) {
      case FLAG_FORM_INPUT_TYPE.number:
        return { type: "number" as const, inputMode: "numeric" as const };
      case FLAG_FORM_INPUT_TYPE.string:
        return { type: "text" as const, inputMode: "text" as const };
      default:
        return { type: "text" as const, inputMode: "text" as const };
    }
  };

  const defaultValue = useMemo(() => {
    switch (formItem.type) {
      case PRECOGS_FLAG_FORM_TYPE.CHECKBOX_GROUP:
      case PRECOGS_FLAG_FORM_TYPE.INPUT:
      case PRECOGS_FLAG_FORM_TYPE.RADIO_GROUP:
      case PRECOGS_FLAG_FORM_TYPE.OPTION_SELECTOR:
        return formItem.defaultValue;
      default:
        return undefined;
    }
  }, [formItem]);
  const formTypeToElement = useCallback(
    (
      onChange: (...event: unknown[]) => void,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      value: any,
      error?: FieldError,
    ) => {
      switch (formItem.type) {
        case PRECOGS_FLAG_FORM_TYPE.CHECKBOX_GROUP:
          return (
            <>
              {formItem.options.map((option, idx) => (
                <>
                  <CheckboxItem
                    key={`precogs-form-item-checkbox-${idx}`}
                    label={option}
                    value={option}
                    onIonChange={(e) => {
                      if (e.target.checked) {
                        onChange(value ? [...value, option] : [option]);
                      } else {
                        onChange(
                          value
                            ? value.filter((val: string) => val !== option)
                            : [],
                        );
                      }
                    }}
                  />
                  <br />{" "}
                  {/* DELETE THIS WHEN LABEL PART EXPOSED (when ionic releases it)*/}
                </>
              ))}
              <Error error={!!error?.message} message={error?.message} />
            </>
          );
        case PRECOGS_FLAG_FORM_TYPE.DATE_PICKER:
          return (
            <DateTimeSelector
              value={value}
              // placeholder={intl.formatMessage(messages.selectDateRangeFrom)}
              floatingLabel={intl.formatMessage(messages.selectDateRangeFrom)}
              doneText={intl.formatMessage(messages.selectDateRangeDone)}
              cancelText={intl.formatMessage(messages.selectDateRangeCancel)}
              onChange={(
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                e: any,
              ) => {
                onChange(e?.detail?.value);
              }}
            />
          );
        case PRECOGS_FLAG_FORM_TYPE.INPUT:
          return (
            <Input
              placeholder={formItem.placeholder}
              onIonInput={onChange}
              error={!!error}
              value={value}
              message={error?.message}
              type={getInputType(formItem.inputType).type}
              inputmode={getInputType(formItem.inputType).inputMode}
              autocomplete="off"
            />
          );
        case PRECOGS_FLAG_FORM_TYPE.RADIO_GROUP:
          return (
            <RadioGroup
              value={value}
              onIonChange={onChange}
              error={!!error}
              message={error?.message}
            >
              {formItem.options.map((option, idx) => (
                <div key={`precogs-form-item-radio-${idx}`}>
                  <RadioItem mode="md" label={option} value={option} />
                </div>
              ))}
            </RadioGroup>
          );
        case PRECOGS_FLAG_FORM_TYPE.OPTION_SELECTOR:
          return (
            <OptionSelector
              option1={{
                value: formItem.options[0],
                label: formItem.options[0],
              }}
              option2={{
                value: formItem.options[1],
                label: formItem.options[1],
              }}
              selected={value || formItem.defaultValue || formItem.options[0]}
              setSelected={(e) => {
                onChange(e);
              }}
              error={!!error}
              errorMsg={intl.formatMessage(messages.errorRequired)}
            />
          );
        case PRECOGS_FLAG_FORM_TYPE.TOGGLE:
          return (
            <div className="border-t-1 flex h-14 items-center justify-between border-[--color-neutral-100]">
              <div className="flex items-center">
                <h2 className="text-base font-semibold text-[--color-neutral-800]">
                  {formItem.title}
                </h2>
              </div>
              <div className="mr-2">
                <Toggle
                  className="border-b-1 border-[--color-neutral-100]"
                  checked={value}
                  onChange={(e) => onChange(e.detail.checked)}
                />
              </div>
            </div>
          );
      }
    },
    [formItem, intl],
  );

  return (
    <Controller
      name={formItem.name}
      control={control}
      rules={
        disableValidation
          ? undefined
          : {
              required: {
                value: !!formItem.required,
                message: intl.formatMessage(messages.errorRequired),
              },
            }
      }
      defaultValue={defaultValue}
      render={({ field: { onChange, value }, fieldState: { error } }) =>
        formTypeToElement(onChange, value, error)
      }
    />
  );
};

export default PrecogsFormItem;
