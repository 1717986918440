import React, { useState } from "react";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateCalendar } from "@mui/x-date-pickers/DateCalendar";
import { DatePickerProps } from "./types";
import { Modal } from "components/Modal";
import dayjs, { Dayjs } from "dayjs";
import "./styles.scss";
import { Button, Input } from "@letsbit/milkylb";
import { FormattedMessage } from "react-intl";
import { messages } from "./messages";
import updateLocale from "dayjs/plugin/updateLocale";
import "dayjs/locale/es";

dayjs.extend(updateLocale);

dayjs.updateLocale("es", {
  weekStart: 0,
});

export const DatePicker: React.FC<DatePickerProps> = ({
  floatingLabel,
  error,
  dateFormat = "DD/MM/YYYY",
  onChange,
  value,
  dataCypressId,
  ...props
}) => {
  const [selectedDate, setSelectedDate] = useState<Dayjs | null>(null);
  const [open, setOpen] = useState(false);

  return (
    <>
      <Modal
        className="flex flex-col"
        classNameModalWrapper="date-picker"
        isOpen={open}
        onDidDismiss={() => setOpen(false)}
        withArrow={false}
      >
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <label className="header-title"> Hasta </label>
          <DateCalendar
            className="row-span-2 h-fit"
            disableFuture={true}
            value={selectedDate}
            onChange={setSelectedDate}
            {...props}
          />
        </LocalizationProvider>
        <div className="grid h-fit grid-cols-2 gap-4">
          <Button
            id="cancelar"
            fill="clear"
            className="letsbit-ui-button-outline-svg-filled"
            onClick={() => {
              onChange?.(null);
              setOpen(false);
            }}
          >
            <FormattedMessage {...messages.cancelDate} />
          </Button>
          <Button
            id="aceptar"
            fill="clear"
            className="letsbit-ui-button-outline-svg-filled"
            onClick={() => {
              if (selectedDate) {
                onChange?.(selectedDate);
              }
              setOpen(false);
            }}
          >
            <FormattedMessage {...messages.acceptDate} />
          </Button>
        </div>
      </Modal>
      <Input
        onClick={() => {
          setOpen(true);
        }}
        error={!!error}
        placeholder={floatingLabel}
        value={value && dayjs(value).format(dateFormat)}
        data-cypress-id={dataCypressId}
      />
    </>
  );
};

export default DatePicker;
