import { defineMessages } from "react-intl";

export const scope = "components.FileInput";

export const messages = defineMessages({
  loadButton: {
    id: `${scope}.loadButton`,
    defaultMessage: "CARGAR",
  },
  deleteButton: {
    id: `${scope}.deleteButton`,
    defaultMessage: "ELIMINAR",
  },
  selectedFileLabel: {
    id: `${scope}.selectedFileLabel`,
    defaultMessage: "Archivos cargados",
  },
  noDocLabel: {
    id: `${scope}.noDocLabel`,
    defaultMessage: "No has cargado ningún archivo",
  },
  dropzonePlaceholder: {
    id: `${scope}.dropzonePlaceholder`,
    defaultMessage: "Arrastra tu archivos .pdf aquí",
  },
  uploadButton: {
    id: `${scope}.uploadButton`,
    defaultMessage: "CARGAR ARCHIVO",
  },
});
