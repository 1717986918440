import React, { useCallback, useEffect, useRef } from "react";
import { FieldValues, SubmitHandler, useForm } from "react-hook-form";
import { Button, ProgressBar } from "@letsbit/milkylb";
import { Carousel } from "antd";
import { CarouselRef } from "antd/es/carousel";
import { FormattedMessage, useIntl } from "react-intl";
import { PRECOGS_FLAG_TYPE } from "@letsbit/malcolmlb";

import PrecogsFirstSlide from "./PrecogsFirstSlide";
import PrecogsFormSlide from "./PrecogsFormSlide";
import { usePrecogsFlags } from "hooks/user/usePrecogsFlags";
import { ReactComponent as SimpleArrowIcon } from "assets/images/icons/simple-arrow.svg";
import { ReactComponent as Arrow } from "assets/images/icons/arrow.svg";
import { ReactComponent as CrossIcon } from "assets/images/icons/cross.svg";
import PrecogsFinalSlide from "./PrecogsFinalSlide";

import { PrecogsFormSliderProps } from "./types";
import { messages } from "./messages";

import "./styles.scss";

function resize() {
  // Trigger resize (IE compatible)
  if (
    navigator.userAgent.indexOf("MSIE") !== -1 ||
    navigator.appVersion.indexOf("Trident/") > 0
  ) {
    const event = document.createEvent("UIEvents");
    event.initUIEvent("resize", true, false, window, 0);
    window.dispatchEvent(event);
  } else {
    window.dispatchEvent(new Event("resize"));
  }
}

export const PrecogsFormSlider: React.FC<PrecogsFormSliderProps> = ({
  flag,
  onExit,
}) => {
  const { control, handleSubmit, reset } = useForm();
  const intl = useIntl();
  const { answerFlag, postponeAnswerFlag, omitAnswerFlag } = usePrecogsFlags();

  const carouselRef = useRef<CarouselRef | null>(null);
  const carouselItemsRef = useRef<CarouselRef | null>(null);

  const [activeIndex, setActiveIndex] = React.useState<number>(0);

  const [formValues, setFormValues] = React.useState<FieldValues>();
  const activeIndexRef = useRef(activeIndex);

  const onSubmitForm: SubmitHandler<FieldValues> = (e) => {
    setFormValues(e);
    if (activeIndex === flag.form.form.length) {
      answerFlag(flag.id, e);
      carouselRef.current?.next();
    } else {
      carouselItemsRef.current?.next();
    }
  };
  const onStart = () => carouselRef.current?.next();
  const onSkip = () => {
    if (flag.type === PRECOGS_FLAG_TYPE.ONE_TIME_VIEW) {
      omitAnswerFlag(flag.id);
    } else {
      postponeAnswerFlag(flag.id);
    }
    onExit();
  };

  const onOmit = () => {
    omitAnswerFlag(flag.id);
    onExit();
  };

  const onGoBack = useCallback(() => {
    setActiveIndex((active) => active - 1);

    switch (true) {
      // first slide
      case activeIndexRef.current === 0:
        return;
      // first item slide
      case activeIndexRef.current === 1:
        carouselRef.current?.prev();
        break;
      // last slide
      case activeIndexRef.current === flag.form.form.length + 1:
        return;
      default:
        carouselItemsRef.current?.prev();
    }
  }, [flag.form.form.length]);

  /* Used for reset validations on slide change (this allows you to continue to the next form on submit)*/
  useEffect(() => {
    reset(formValues);
  }, [activeIndex, formValues, reset]);

  useEffect(() => {
    activeIndexRef.current = activeIndex;
  }, [activeIndex]);

  const handleBeforeChange = () => {
    // This setTimeout is needed for adaptive height in nested Carousel
    setTimeout(() => {
      resize();
    }, 500);
  };

  return (
    <Carousel
      ref={
        carouselRef
          ? (ref) => {
              carouselRef.current = ref;
            }
          : undefined
      }
      beforeChange={(_: number, nextSlide: number) => {
        setActiveIndex(nextSlide)
        handleBeforeChange()
      }}
      afterChange={(e) => setActiveIndex(e)}
      accessibility={false}
      adaptiveHeight={false}
      arrows={false}
      dots={false}
      infinite={false}
      initialSlide={0}
      slidesToScroll={1}
      slidesToShow={1}
      speed={500}
      swipe={false}
    >
      <div className="h-[calc(90vh-4rem)] w-full " key="first-slide">
        <PrecogsFirstSlide
          flag={flag}
          onSkip={onSkip}
          onStart={onStart}
          onOmit={onOmit}
        />
      </div>
      <div className="h-[calc(90vh-4rem)] w-full" key="second-slide">
        <div className="flex h-full w-full flex-col justify-between">
          <div className="w-full">
            <div className="flex flex-row justify-between mb-6">
              <SimpleArrowIcon
                className="h-6 w-6 cursor-pointer stroke-[--milkylb-color-secondary-200]"
                onClick={onGoBack}
              />
              {flag.omittable && (
                <CrossIcon
                  className="h-6 w-6 cursor-pointer fill-[--milkylb-color-secondary-200]"
                  onClick={onSkip}
                />
              )}
            </div>
            <Carousel
              className="px-6"
              accessibility={false}
              adaptiveHeight={false}
              arrows={false}
              dots={false}
              infinite={false}
              initialSlide={0}
              slidesToScroll={1}
              slidesToShow={1}
              speed={500}
              beforeChange={(_: number, nextSlide: number) => {
                setActiveIndex(nextSlide + 1)
                handleBeforeChange()
              }}
              swipe={false}
              ref={
                carouselItemsRef
                  ? (ref) => {
                      carouselItemsRef.current = ref;
                    }
                  : undefined
              }
            >
              {flag.form.form.map((formItem, idx) => (
                <div className="w-full" key={`precogs-form-slider-${idx}`}>
                  <form
                    className="h-full w-full"
                    id={`precogs-form-slider-form-${idx + 1}`}
                    onSubmit={handleSubmit(onSubmitForm)}
                  >
                    <PrecogsFormSlide
                      disableValidation={activeIndex !== idx + 1}
                      formItem={formItem}
                      control={control}
                    />
                  </form>
                </div>
              ))}
            </Carousel>
          </div>
          <div className="precogs-form-slider__footer flex justify-between px-6 pb-2">
            <ProgressBar
              text={`${intl.formatMessage(
                messages.progressBarFirst
              )} ${activeIndex} ${intl.formatMessage(
                messages.progressBarSecond
              )} ${flag.form.form.length}`}
              percent={activeIndex / flag.form.form.length}
            />
            <Button
              className="precogs-form-slider__footer__button ml-4 w-1/2"
              rightIcon={() => <Arrow className="rotate-90"/>}
              type="submit"
              form={`precogs-form-slider-form-${activeIndex}`}
            >
              <FormattedMessage {...messages.buttonSlide} />
            </Button>
          </div>
        </div>
      </div>
      <div className="h-[calc(90vh-4rem)] w-full" key="third-slide">
        <PrecogsFinalSlide flag={flag} onSubmit={onExit} />
      </div>
    </Carousel>
  );
};

export default PrecogsFormSlider;
