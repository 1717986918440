import { defineMessages } from "react-intl";

export const scope = "pages.OnBoarding.components.FundsOriginUpload";

export const messages = defineMessages({
  commentDocsplaceholder: {
    id: `${scope}.commentDocsplaceholder`,
    defaultMessage: "Descripción de la documentación",
  },
  commentplaceholder: {
    id: `${scope}.commentplaceholder`,
    defaultMessage: "Agregue un comentario",
  },
  commentLabel: {
    id: `${scope}.commentLabel`,
    defaultMessage: "Describa brevemente la documentación cargada",
  },
  description: {
    id: `${scope}.description`,
    defaultMessage:
      "Necesitamos que cargues documentación sobre el origen de fondos a emplear en nuestra plataforma. Son válidos todos los documentos que muestren de dónde vienen los fondos que van a ser utilizados en la plataforma. Por ejemplo: último balance certificado.",
  },
  dropzonePlaceholder: {
    id: `${scope}.dropzonePlaceholder`,
    defaultMessage: "Arrastra tu archivos .pdf aquí",
  },
  uploadButton: {
    id: `${scope}.uploadButton`,
    defaultMessage: "CARGAR ARCHIVO",
  },
});
