export const VALID_NUMBER_REGEX = /^\d*\.?\d*$/;

export const VALID_NUMBER_REGEX_BY_PRECISION = (p: number) => {
  return new RegExp(`^\\d*\\.?\\d{0,${p}}$`);
};

export enum CurrencySelectorValues {
  ALL = "all",
  REAL_STATE = "real_state",
  CRYPTOCURRENCY = "cryptocurrency",
  STABLECOIN = "stablecoin",
  FIAT = "fiat",
  COMMODITY = "commodity",
}

export const VIRTUAL_CARDS_LIMIT = 5;
