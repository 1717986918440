import { LogOutButtonProps } from "./types";
import classNames from "classnames";
import { FormattedMessage } from "react-intl";
import { ReactComponent as LogoutIcon } from "assets/images/icons/navbar/logout.svg";
import { useContext } from "react";
import { UserContext } from "providers/UserProvider";
import { messages } from "./messages";

export const LogOutButton: React.FC<LogOutButtonProps> = ({
  className,
  ...props
}) => {
  const { logout } = useContext(UserContext);

  return (
    <button
      className={classNames(
        className,
        "flex items-center rounded-lg px-2 py-1 text-[--milkylb-color-red-error]"
      )}
      type="button"
      onClick={async () => {
        await logout();
      }}
      {...props}
    >
      <span className={"mr-4 p-2"}>
        <LogoutIcon />
      </span>
      <FormattedMessage {...messages.logoutButton} />
    </button>
  );
};
