// import { useNavigate } from "react-router-dom";
import { LogOutButton } from "components/LogOutButton";
import { SuccessContent } from "../../components/SuccessContent";

export const SuccessPage: React.FC = () => {
  return (
    <div className="relative flex h-full w-full flex-col items-center justify-center">
      <LogOutButton className="absolute right-[40px] top-[24px]" />
      <div className="flex h-full w-full flex-col p-12">
        <SuccessContent />
      </div>
    </div>
  );
};
