import React from "react";
import { FormattedMessage, useIntl } from "react-intl";

import InfoScreen from "components/InfoScreen";
import successImg from "assets/images/success.svg";
import PrecogsFirstAndFinalSlideHeader from "../PrecogsFirstAndFinalSlideHeader";

import { messages } from "./messages";
import { PrecogsFinalSlideProps } from "./types";

export const PrecogsFinalSlide: React.FC<PrecogsFinalSlideProps> = ({
  flag,
  onSubmit,
}) => {
  const intl = useIntl();

  return (
    <InfoScreen
      header={
        flag.omittable ? (
          <PrecogsFirstAndFinalSlideHeader onClick={onSubmit} />
        ) : undefined
      }
      picture={successImg}
      info={
        <>
          <h2 className="text-[--color-navbar-logo] text-center text-3xl font-bold font-['Source_Sans_Pro']">
            {flag.form.title ? (
              <FormattedMessage
                {...{
                  id: `PrecogsFinalSlideTitle:${flag.form.endTitle}`,
                  defaultMessage: flag.form.endTitle || "",
                }}
                values={{ br: <br /> }}
              />
            ) : (
              ""
            )}
          </h2>
          <p className="text-center text-sm font-['Source_Sans_Pro']">
            {flag.form.tooltip ? (
              <FormattedMessage
                {...{
                  id: `PrecogsFinalSlideTooltip:${flag.form.endTooltip}`,
                  defaultMessage: flag.form.endTooltip || "",
                }}
              />
            ) : (
              ""
            )}
          </p>
        </>
      }
      filledButton={{ text: intl.formatMessage(messages.button), onSubmit }}
    />
  );
};

export default PrecogsFinalSlide;
