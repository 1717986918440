import { defineMessages } from "react-intl";

export const scope = "pages.OnBoarding.components.CompanyDataForm";

export const messages = defineMessages({
  activityPlaceholder: {
    id: `${scope}.activityPlaceholder`,
    defaultMessage: "Describa con sus palabras a que se dedica su empresa",
  },
  activityLabel: {
    id: `${scope}.activityLabel`,
    defaultMessage: "Rubro",
  },
  inscriptionDateLabel: {
    id: `${scope}.inscriptionDateLabel`,
    defaultMessage: "Fecha de inscripción de la empresa",
  },
  registrationNumberPlaceholder: {
    id: `${scope}.registrationNumberPlaceholder`,
    defaultMessage: "Ingrese su número",
  },
  registrationNumberLabel: {
    id: `${scope}.registrationNumberLabel`,
    defaultMessage: "Número de inscripción Registral",
  },
  continueButton: {
    id: `${scope}.continueButton`,
    defaultMessage: "CONTINUAR",
  },
});
