import { Carousel } from "antd";
import { DividerWithCarouselProps } from "./types";
import classNames from "classnames";

export const DividerWithCarousel: React.FC<DividerWithCarouselProps> = ({
  className,
  children,
  carouselContent,
  noSwipe = false,
  noDots = false,
  forwardRef
}) => {
  return (
    <div className={classNames("flex h-full w-full", className)}>
      <section className="relative h-full w-1/2 bg-[--milkylb-color-secondary-600]">
        <Carousel ref={forwardRef ? ref => { forwardRef.current = ref} : undefined} className="p-11" swipe={!noSwipe} dots={!noDots} >
          {carouselContent?.map((c, idx) => (
            <div key={idx} className="h-[calc(100vh-5.50rem)]">{c}</div>
          ))}
        </Carousel>
      </section>
      <section className="flex h-full w-1/2 items-center justify-center">
        {children}
      </section>
    </div>
  );
};
