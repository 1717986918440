import React from "react";

import { LocationFormProps } from "./types";
import classNames from "classnames";
import { AddressInput } from "components/AddressInput";
import { useIntl } from "react-intl";
import { messages } from "./messages";
import { useGetUserCountry } from "hooks/user/useGetUserCountry";

export const LocationForm: React.FC<LocationFormProps> = ({
  onSubmit,
  className,
  form,
}) => {
  const { formatMessage } = useIntl();
  const country = useGetUserCountry();

  const value =
    form?.street_name &&
    form.city &&
    form.province &&
    form.postcode &&
    form.street_number &&
    form.floor &&
    form.apartment
      ? {
          street_name: form.street_name,
          street_number: form.street_number,
          postcode: form.postcode,
          city: form.city,
          province: form.province,
          floor: form.floor,
          apartment: form.apartment,
          additional_info: form.additional_info,
        }
      : undefined;

  return (
    <form
      className={classNames(className, "fadeInSimple relative")}
      onSubmit={(e) => e.preventDefault()}
      data-cypress-id="sede__social-form"
    >
      <AddressInput
        onSelect={(ad) => {
          onSubmit({
            street_name: ad.street_name,
            street_number: ad.street_number,
            postcode: ad.postcode,
            city: ad.city,
            province: ad.province,
            floor: ad.floor,
            apartment: ad.apartment,
            additional_info: ad.additional_info,
          });
        }}
        place={
          value
            ? {
                ...value,
                neighborhood: "",
                country: country || "",
              }
            : undefined
        }
        data-cypress-id="sede__social-location__input"
        placeholder={formatMessage(messages.placeholder)}
        dataCypressId="sede__social-location__input--address"
      />
    </form>
  );
};
