import { Modal } from "components/Modal";
import { IndirectBeneficiaryModalProps } from "../types";
import { Button } from "@letsbit/milkylb";
import CustomFormattedMessage from "components/CustomFormattedMessage";
import { messages } from "../messages";

export const IndirectBeneficiaryModal: React.FC<
  IndirectBeneficiaryModalProps
> = ({ open, setOpen, onConfirm, onCancel }) => {
  const continueHandler = () => {
    setOpen(false);
    onConfirm();
  };

  const cancelHandler = () => {
    setOpen(false);
    onCancel();
  };

  return (
    <Modal
      isOpen={open}
      withArrow={false}
      onDidDismiss={() => setOpen(false)}
      classNameModalWrapper="UltimateBeneficialOwnersForm_IndirectBeneficiaryModal overflow-auto"
    >
      <div className="flex h-full flex-col items-center justify-between">
        <h1 className="mb-4 text-center text-2xl font-bold text-[--milkylb-color-secondary-400]">
          <CustomFormattedMessage {...messages.modalBeneficiaryTitle} />
        </h1>
        <p className="text-center text-sm leading-[14px]">
          <CustomFormattedMessage {...messages.modalBeneficiaryDescription} />
        </p>
        <div className="mt-4 flex gap-x-2">
          <Button type="button" onClick={continueHandler} fill="clear">
            <CustomFormattedMessage {...messages.modalBeneficiaryBack} />
          </Button>
          <Button type="button" onClick={cancelHandler}>
            <CustomFormattedMessage {...messages.modalBeneficiaryCancel} />
          </Button>
        </div>
      </div>
    </Modal>
  );
};
