import { BigNumber } from "bignumber.js";
import { useCallback } from "react";

export const useFormatter = (): {
  formatValue: (
    value: string,
    precision?: number | undefined,
    format?: BigNumber.Format
  ) => string;
  reverseFormatNumber: (val: string) => string;
} => {
  const formatValue = useCallback((
    value: string,
    precision?: number,
    format?: BigNumber.Format
  ) => {
    if (value === "") return "";
    if (value === "-") return "-";
    const formatter = format
      ? format
      : {
        prefix: "",
        decimalSeparator: ",",
        groupSeparator: ".",
        groupSize: 3,
        secondaryGroupSize: 0,
        fractionGroupSeparator: " ",
        fractionGroupSize: 0,
        suffix: "",
      };

    const decimals = precision ? precision : 2;
    const isInteger = +value % 1 === 0;

    return new BigNumber(value).toFormat(
      isInteger ? 1 : decimals,
      BigNumber.ROUND_DOWN,
      formatter
    );
  }, []);

  const reverseFormatNumber = useCallback((val: string) => {
    const group = new Intl.NumberFormat("de-DE").format(1111).replace(/1/g, "");
    const decimal = new Intl.NumberFormat("de-DE")
      .format(1.1)
      .replace(/1/g, "");
    let reversedVal = val.replace(new RegExp("\\" + group, "g"), "");
    reversedVal = reversedVal.replace(new RegExp("\\" + decimal, "g"), ".");
    return Number.isNaN(+reversedVal) ? "0" : reversedVal;
  }, []);

  return { formatValue, reverseFormatNumber };
};
