import CustomFormattedMessage from "components/CustomFormattedMessage";
import React, { useEffect } from "react";
import { messages } from "../messages";
import { Button, Checkbox, Error } from "@letsbit/milkylb";
import { IntroProps } from "../types";
import { Controller } from "react-hook-form";
import { useIntl } from "react-intl";
import { formMessages } from "utils/formMessages";
import { useGetUltimateBeneficialOwner } from "hooks/compliance/useGetUltimateBeneficialOwner";
import { UltimateBeneficialCard } from "./UltimateBeneficialCard";
import { useUltimateBeneficialOwner } from "hooks/compliance/useUltimateBeneficialOwner";
import LoadingAnimation from "components/LoadingAnimation";
import { RejectedReasonWarning } from "components/InformationRequestUploadPage/components/RejectedReasonWarning";

export const Intro: React.FC<IntroProps> = ({
  onNext,
  control,
  onEdit,
  setShowSubmit,
  informationRequest,
}) => {
  const { formatMessage } = useIntl();
  const { data, mutate } = useGetUltimateBeneficialOwner();
  const { deleteUltimateBeneficialOwner, loading } =
    useUltimateBeneficialOwner();

  useEffect(() => {
    if (data && data.length > 0) {
      setShowSubmit(true);
    } else {
      setShowSubmit(false);
    }
  }, [setShowSubmit, data]);

  return (
    <div className="flex h-full gap-x-8 overflow-hidden">
      {loading && <LoadingAnimation />}
      <div className="flex-1 overflow-auto text-[--milkylb-color-neutral-600]">
        {informationRequest && informationRequest.comments && (
          <RejectedReasonWarning informationRequest={informationRequest} />
        )}
        <p className="mb-4">
          <CustomFormattedMessage {...messages.introParagraph1} />
        </p>
        <p className="mb-4">
          <CustomFormattedMessage {...messages.introParagraph2} />
        </p>
        <ul className="mb-4 list-disc pl-4">
          <li className="mb-4">
            <CustomFormattedMessage {...messages.firstListItem} />
          </li>
          <li>
            <CustomFormattedMessage {...messages.secondListItem} />
          </li>
        </ul>
        <Controller
          control={control}
          rules={{
            required: formatMessage(formMessages.requiredFieldError),
          }}
          render={({
            field: { onChange, value, onBlur },
            fieldState: { error },
          }) => (
            <div className="flex flex-col gap-y-2">
              <div className="flex gap-x-2">
                <Checkbox
                  checked={value}
                  onIonChange={(e: { target: { checked: boolean } }) => {
                    onChange(e.target.checked);
                  }}
                  // justify="start"
                  labelPlacement="end"
                  onIonBlur={onBlur}
                ></Checkbox>
                <p>
                  <CustomFormattedMessage {...messages.terms} />
                </p>
              </div>
              <Error error={!!error} message={error?.message} />
            </div>
          )}
          name="terms_and_conditions"
        />
      </div>
      <div className="flex flex-1 flex-col text-[--milkylb-color-neutral-600]">
        <h3 className="mb-4 text-lg">
          <CustomFormattedMessage {...messages.beneficialOwnersTitle} />
        </h3>
        {data && data.length > 0 ? (
          <ul className="flex flex-col gap-y-2">
            {data?.map((ubo) => (
              <li key={ubo.id}>
                <UltimateBeneficialCard
                  ultimateBeneficialOwner={ubo}
                  onDelete={async () => {
                    await deleteUltimateBeneficialOwner(ubo.id);
                    mutate();
                  }}
                  onEdit={onEdit}
                />
              </li>
            ))}
          </ul>
        ) : (
          <p className="mb-4 text-[--milkylb-color-neutral-400]">
            <CustomFormattedMessage {...messages.emptyBeneficial} />
          </p>
        )}

        <Button
          type="button"
          fill="clear"
          className="mt-4 self-end"
          onClick={onNext}
        >
          <CustomFormattedMessage {...messages.addBeneficial} />
        </Button>
      </div>
    </div>
  );
};
