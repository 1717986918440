import { useContext } from "react";
import { SWRResponse } from "swr";
import { PrecogsUser } from "@letsbit/malcolmlb";
import { useAuthSWR } from "hooks/utils/useAuthSWR";
import { ApiContext } from "providers/ApiProvider";

export const SWR_MEMBER_USER_KEY = "get-member-user";

export const useGetMemberUser = (): SWRResponse<PrecogsUser, any> => {
  const { memberInstance } = useContext(ApiContext);

  const swr = useAuthSWR(
    SWR_MEMBER_USER_KEY,
    async () => {
      const { data } = await memberInstance.precogs.getUser();

      return data;
    },
    { revalidateIfStale: false },
  );

  return swr;
};
