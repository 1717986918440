import { useContext, useMemo } from "react";
import {
  CurrencyType,
  CURRENCY_FILTER_PARAMS,
  HubCurrencies,
} from "@letsbit/malcolmlb";
import { ApiContext } from "providers/ApiProvider";
import { countryMap } from "utils/locales";
import { UserContext } from "providers/UserProvider";
import { useAuthSWR } from "hooks/utils/useAuthSWR";
import { CurrencySelectorValues } from "utils/constants";
import { filterByTag } from "utils/filters";

export const SWR_CURRENCIES_KEY = "get-currencies-data";

export type HubCurrenciesFilterParams = {
  preferredCurrency?: string;
  country?: string;
  filterBy?: CURRENCY_FILTER_PARAMS;
  debitCurrencyFilter?: string;
};

export enum Languages {
  ES = "es",
}
const CurrencyTypeObject = {
  [CurrencyType.REAL_STATE]: { [Languages.ES]: "Bienes Raíces" },
  [CurrencyType.CRYPTOCURRENCY]: { [Languages.ES]: "Criptomonedas" },
  [CurrencyType.STABLECOIN]: { [Languages.ES]: "Stablecoins" },
  [CurrencyType.FIAT]: { [Languages.ES]: "Fiat" },
  [CurrencyType.COMMODITY]: { [Languages.ES]: "Commodities" },
};

export const translateCurrencyType = (
  value: CurrencyType,
  lang: Languages = Languages.ES,
): string => {
  return CurrencyTypeObject[value][lang];
};

const filterFun = (filter?: CurrencySelectorValues) => (c: HubCurrencies) => {
  switch (filter) {
    case undefined:
    case CurrencySelectorValues.ALL:
      return true;
    default:
      return filterByTag(c, filter);
  }
};

export interface CurrencyMetadata {
  type?: CurrencyType;
  more_info_url?: string;
  exchange_form_text?: string;
  terms_and_conditions?: boolean;
  deposit_messages?: string[];
  withdraw_messages?: string[];
  country?: keyof typeof countryMap;
  require_beneficiary_for_deposit?: boolean;
}

export const useCurrencies = (
  params?: HubCurrenciesFilterParams,
  filter?: CurrencySelectorValues[],
) => {
  const { user } = useContext(UserContext);
  const { memberInstance } = useContext(ApiContext);
  const preferredCurrency =
    params?.preferredCurrency || user?.data?.preferred_currency || "pax";

  const swr = useAuthSWR(
    [SWR_CURRENCIES_KEY, filter, params],
    async () => {
      const { data } = await memberInstance.hub.getCurrencies({
        preferred_currency: preferredCurrency,
        filter_by: params?.filterBy,
        debit_currency_filter: params?.debitCurrencyFilter,
      });

      return data.filter((c) =>
        filter
          ? filter?.reduce((prev, f) => filterFun(f)(c) || prev, false)
          : true,
      );
    },
    {
      revalidateIfStale: false,
    },
  );

  return swr;
};

export const useCurrency = (currency_id?: string) => {
  const { data: currencies } = useCurrencies();

  const currency = useMemo(
    () => currencies?.find((currency) => currency.id === currency_id),
    [currency_id, currencies],
  );

  return currency;
};
