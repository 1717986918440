import { Flag } from "@letsbit/malcolmlb";
import { UserContext } from "providers/UserProvider";
import { useContext, useEffect, useRef, useState } from "react";

import { PrecogsSocket } from "sockets/precogs/flags";

export const usePrecogsWs = (): {
  flags: Flag[];
  showFlags: boolean;
  setFlags: (flag: Flag[]) => void;
  setShowFlags: (showFlags: boolean) => void;
} => {
  const ws = useRef<PrecogsSocket>(new PrecogsSocket());
  const { user } = useContext(UserContext);
  const [flags, setFlags] = useState<Flag[]>([]);
  const [showFlags, setShowFlags] = useState<boolean>(false);
  const showFlagsRef = useRef<boolean>(false);

  useEffect(() => {
    showFlagsRef.current = showFlags;
  }, [showFlags]);

  useEffect(() => {
    if (user) {
      const onSubscribe = (res: Flag[]) => {
        if (res.length > 0 && !showFlagsRef.current) {
          setFlags(res);
        }
      };

      const wsValue = ws.current;

      wsValue.connectWebsocket();

      const id = wsValue.suscribeToMessages(onSubscribe);

      return () => {
        wsValue.unsuscribeToMessages(id);
        wsValue.close();
      };
    }
  }, [user, setFlags]);

  return {
    flags,
    showFlags,
    setShowFlags,
    setFlags,
  };
};
