import { defineMessages } from "react-intl";

export const scope = "components.BackButton";

export const messages = defineMessages({
  label: {
    id: `${scope}.label`,
    defaultMessage: "VOLVER",
  },
});
