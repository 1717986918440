import { useContext } from "react";
import { NotificationContext } from "providers/NotificationsProvider";

export const useNotification = () => {
  const { addNotification } = useContext(NotificationContext)

  return {
    showNotification: addNotification
  };
};
