import { useContext, useState } from "react";
import { ApiContext } from "providers/ApiProvider";
import { defineMessages, useIntl } from "react-intl";
import { useNotification } from "hooks/utils/useNotification";
import { NotificationStatus } from "components/Notification/types";
import {
  INFORMATION_REQUEST_STATUS,
  InformationRequests,
  InformationRequestsReplyBody,
} from "@letsbit/malcolmlb";

export const scope = "hook.useInformationRequests";

export const messages = defineMessages({
  defaultErrorNotificationTitle: {
    id: `${scope}.confirmErrorNotificationTitle`,
    defaultMessage: "Ocurrio un error, intentalo de nuevo mas tarde.",
  },
  defaultErrorNotificationMessage: {
    id: `${scope}.confirmErrorNotificationMessage`,
    defaultMessage:
      "No pudimos subir el documento, intentalo de nuevo mas tarde",
  },
  notifyAdminTitle: {
    id: `${scope}.notifyAdminTitle`,
    defaultMessage: "Documentación enviada",
  },
  notifyAdminMessage: {
    id: `${scope}.notifyAdminMessage`,
    defaultMessage: "Te notificaremos el resultado de la revisión por mail.",
  },
  updatedDocsNotificationTitle: {
    id: `${scope}.updatedDocsNotificationTitle`,
    defaultMessage: "Documento actualizado",
  },
  updatedDocsNotificationMessage: {
    id: `${scope}.updatedDocsNotificationMessage`,
    defaultMessage:
      "Envia los documentos actualizados para ponerlos en revisión",
  },
  noneDocsErrorNotificationTitle: {
    id: `${scope}.noneDocsErrorNotificationTitle`,
    defaultMessage: "No hay documentos actualizados",
  },
  noneDocsErrorNotificationMessage: {
    id: `${scope}.noneDocsErrorNotificationMessage`,
    defaultMessage: "Hacé click en la documentación pendiente de carga",
  },
});

export const informationRequestHasValidState = (
  doc: InformationRequests | undefined,
): boolean => {
  if (!doc || doc.status === INFORMATION_REQUEST_STATUS.REJECTED) {
    return false;
  }

  return true;
};

export const useInformationRequests = () => {
  const { memberInstance } = useContext(ApiContext);
  const [loading, setLoading] = useState(false);
  const { showNotification } = useNotification();
  const { formatMessage } = useIntl();

  const submitInformationRequest = async (
    form: InformationRequestsReplyBody,
  ): Promise<void> => {
    setLoading(true);
    try {
      await memberInstance.compliance.submitInformationRequest(form);
      showNotification({
        title: formatMessage(messages.updatedDocsNotificationTitle),
        message: formatMessage(messages.updatedDocsNotificationMessage),
        status: NotificationStatus.SUCCESS,
      });
    } catch (error) {
      console.error(error);
      switch (error) {
        default:
          showNotification({
            title: formatMessage(messages.defaultErrorNotificationTitle),
            message: formatMessage(messages.defaultErrorNotificationMessage),
            status: NotificationStatus.ERROR,
          });
          break;
      }
      throw error;
    } finally {
      setLoading(false);
    }
  };

  const notifyInformationRequest = async (): Promise<void> => {
    setLoading(true);
    try {
      await memberInstance.compliance.notifyInformationRequest();
      showNotification({
        title: formatMessage(messages.notifyAdminTitle),
        message: formatMessage(messages.notifyAdminMessage),
        status: NotificationStatus.SUCCESS,
      });
    } catch (error) {
      console.error(error);
      switch (error) {
        case "member.informationrequest.nothing_to_notify":
          showNotification({
            title: formatMessage(messages.noneDocsErrorNotificationTitle),
            message: formatMessage(messages.noneDocsErrorNotificationMessage),
            status: NotificationStatus.ERROR,
          });
          break;
        default:
          showNotification({
            title: formatMessage(messages.defaultErrorNotificationTitle),
            message: formatMessage(messages.defaultErrorNotificationMessage),
            status: NotificationStatus.ERROR,
          });
          break;
      }
      throw error;
    } finally {
      setLoading(false);
    }
  };

  return {
    submitInformationRequest,
    notifyInformationRequest,
    loading,
  };
};
