import {
  HubBalanceParams,
  HubBalanceResponse,
  HubCurrencies,
} from "@letsbit/malcolmlb/lib/microservices/hub/types";
import BigNumber from "bignumber.js";
import { useEffect, useState } from "react";
import { KeyedMutator } from "swr";

import { useCoinValues } from "./useCoinValues";
import { useCurrencies } from "hooks/user/useCurrencies";
import { useFormatter } from "hooks/utils/useFormatter";
import { useGetHubBalances } from "./useGetHubBalances";

export interface Wallet extends HubCurrencies {
  balance: string;
  locked: string;
  total_balance: string;
  total_balance_preferred_currency: string;
  total_balance_preferred_currency_without_format: string;
  balancePreferredCurrency: string;
}

export interface Wallets {
  [key: string]: Wallet;
}

export const useWallets = (
  balancesParams?: HubBalanceParams,
): {
  data: Wallets | undefined;
  mutate: KeyedMutator<HubBalanceResponse[]>;
  isValidating: boolean;
  isLoading: boolean;
} => {
  const { data: currencies } = useCurrencies();
  const { preferredCurrency } = useCoinValues();
  const {
    data: balances,
    mutate,
    isValidating,
    isLoading,
  } = useGetHubBalances(balancesParams);
  const { formatValue } = useFormatter();

  const [data, setData] = useState<Wallets>();

  useEffect(() => {
    if (currencies && balances) {
      const wallets: Wallets = currencies?.reduce((prev: Wallets, currency) => {
        const balance = balances?.find(
          (item) => currency.id === item.currency.toLowerCase(),
        );

        const total_balance = balance
          ? (+balance.balance + +balance.locked).toString()
          : "";

        const total_balance_preferred_currency = balance
          ? BigNumber.sum(
              balance.balancePreferredCurrency,
              balance.lockedPreferredCurrency,
            ).toString()
          : "";

        return {
          ...prev,
          [currency.id]: {
            ...currency,
            balance: balance?.balance || "0",
            locked: balance?.locked || "0",
            total_balance,
            total_balance_preferred_currency: formatValue(
              total_balance_preferred_currency,
              preferredCurrency?.precision,
            ),
            total_balance_preferred_currency_without_format:
              total_balance_preferred_currency,
            balancePreferredCurrency: balance?.balancePreferredCurrency || "0",
          },
        };
      }, {});
      setData(wallets);
    }
  }, [currencies, balances, preferredCurrency, formatValue]);

  return { data, mutate, isValidating, isLoading };
};
