import { ReactNode, useEffect } from "react";
import posthog from "posthog-js";
import { PostHogProvider } from "posthog-js/react";

if (typeof window !== "undefined") {
  posthog.init(window.env.posthog.apiKey, {
    api_host: window.env.posthog.url,
    autocapture: window.env.posthog.autocapture,
    capture_pageview: window.env.posthog.capture_pageview,
    capture_pageleave: window.env.posthog.capture_pageleave,
  });
}

export type Props = {
  children: ReactNode;
};

export const PHProvider = (props: Props) => {
  useEffect(() => {
    posthog.register({platform: "web"})
  }, [])
  return <PostHogProvider client={posthog}>{props.children}</PostHogProvider>;
};
