import React, { useState, useEffect } from "react";
import { HelpBubbleProps } from "./types";
import { NotificationStatus } from "components/Notification/types";
import { ReactComponent as HelpIcon } from "assets/images/icons/help.svg";
import classNames from "classnames";
import { Notification } from "components/Notification";
import { animated, useSpring } from "@react-spring/web";
import { createPortal } from "react-dom";

export const HelpBubble: React.FC<HelpBubbleProps> = ({
  title,
  message,
  className,
  delay = 2000,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const [pillsStyles, pillsAnimationApi] = useSpring(() => ({
    config: {
      tension: 125,
      friction: 20,
      precisioxn: 0.0001,
      duration: 200,
    },
    from: {
      opacity: 0,
      visibility: "hidden",
    },
  }));

  const handleNotification = (open: boolean) => {
    if (open) {
      pillsAnimationApi({
        to: {
          opacity: 1,
          visibility: "visible",
        },
      });
    } else {
      pillsAnimationApi({
        to: {
          opacity: 0,
          visibility: "hidden",
        },
      });
    }
    setIsOpen(open);
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      handleNotification(true);
    }, delay);

    return () => clearTimeout(timer);
  }, [delay]);

  return createPortal(
    <div className="absolute bottom-[88px] right-[20px] z-[10000]">
      <animated.div style={pillsStyles as any}>
        <Notification
          className={`absolute bottom-12 right-12 w-max transform rounded-br-[0px] transition-all duration-300 ease-in-out`}
          title={title}
          message={message}
          status={NotificationStatus.INFO}
          onCancel={() => handleNotification(false)}
        />
      </animated.div>
      <button
        onClick={() => handleNotification(!isOpen)}
        className={classNames(
          "transform rounded-full bg-[#7D8AFF] px-3 py-3 transition-transform hover:scale-105 active:scale-95",
          className,
        )}
      >
        <HelpIcon />
      </button>
    </div>,
    document.getElementById("root") as HTMLElement,
  );
};
