import {
  INFORMATION_REQUEST_STATUS,
  InformationRequests,
  InformationRequestsParams,
} from "@letsbit/malcolmlb";
import { useContext, useMemo } from "react";
import { KeyedMutator } from "swr";

import { ApiContext } from "providers/ApiProvider";
import { useAuthSWR } from "hooks/utils/useAuthSWR";

export const SWR_INFORMATION_REQUESTS_KEY = "get-information-requests";

export const useGetInformationRequests = (
  params?: InformationRequestsParams,
): // params?: Pick<MemberStatusParams, "country_residence">
{
  data: InformationRequests[] | undefined;
  awaitingMember: InformationRequests[];
  awaitingAdmin: InformationRequests[];
  error: Error | undefined;
  mutate: KeyedMutator<InformationRequests[]>;
  isValidating: boolean;
  isLoading: boolean;
} => {
  const { memberInstance } = useContext(ApiContext);

  const { data, error, mutate, isValidating, isLoading } = useAuthSWR(
    [SWR_INFORMATION_REQUESTS_KEY, params],
    async () => {
      const result = await memberInstance.compliance.getInformationRequests(
        params || {},
      );
      return result?.data;
    },
  );

  const awaitingMember = useMemo(
    () =>
      data?.filter(
        (ir) =>
          ir.status === INFORMATION_REQUEST_STATUS.AWAITING_MEMBER ||
          ir.status === INFORMATION_REQUEST_STATUS.NOTIFY_ADMIN,
      ) || [],
    [data],
  );

  const awaitingAdmin = useMemo(
    () =>
      data?.filter(
        (ir) =>
          ir.status !== INFORMATION_REQUEST_STATUS.AWAITING_MEMBER &&
          ir.status !== INFORMATION_REQUEST_STATUS.NOTIFY_ADMIN,
      ) || [],
    [data],
  );

  return {
    data: data,
    error,
    mutate,
    isLoading,
    isValidating,
    awaitingMember,
    awaitingAdmin,
  };
};
