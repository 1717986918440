import React, { useContext } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { CompanyNationalIdPageProps } from "../types";
import { UserContext } from "providers/UserProvider";

import { JuridicalCustomerForm } from "../../components/forms/JuridicalCustomerForm";
import { messages } from "./messages";
import { LogOutButton } from "components/LogOutButton";

export const CompanyNationalIdPage: React.FC<CompanyNationalIdPageProps> = ({
  onSubmit,
}) => {
  const { user } = useContext(UserContext);
  const { formatMessage } = useIntl();
  const country = user?.data.country_residence as string;

  return (
    <div className="fadeInSimple relative flex h-full w-full flex-col items-center justify-center">
      <LogOutButton className="absolute right-[40px] top-[24px]" />
      <div className="flex h-full flex-col py-[5%]">
        <h1 className="mb-8 w-full font-['Tomato_Grotesk'] text-4xl font-bold text-[--milkylb-color-neutral-600]">
          <FormattedMessage {...messages.title} />
        </h1>
        <JuridicalCustomerForm
          className="w-[448px] grow"
          country={country}
          onSubmit={onSubmit}
          rules={{
            validate: (cuit: string) => {
              if (+cuit < 30000000000 && cuit[0] !== "3") {
                return formatMessage(messages.invalidCuit);
              }
            },
          }}
        />
      </div>
    </div>
  );
};
