import React, { useCallback, useState } from "react";
import { useForm } from "react-hook-form";

import { Form, UltimateBeneficialOwnersFormProps } from "./types";
import { Intro } from "./components/Intro";
import { DataFirstStep } from "./components/DataFirstStep";
import { Route, Routes, useNavigate } from "react-router-dom";
import { FadeInRouter } from "components/FadeInRouter";

import "./styles.scss";
import { DataSecondStep } from "./components/DataSecondStep";
import {
  UBO_DOCUMENT_TYPES,
  useUltimateBeneficialOwner,
} from "hooks/compliance/useUltimateBeneficialOwner";
import LoadingAnimation from "components/LoadingAnimation";
import {
  UltimateBeneficialOwner,
  UltimateBeneficialOwnerDocumentForm,
} from "@letsbit/malcolmlb";
import { useGetUltimateBeneficialOwner } from "hooks/compliance/useGetUltimateBeneficialOwner";
import { IndirectBeneficiaryModal } from "./components/IndirectBeneficiaryModal";
import { useApplyInformationRequestTemplate } from "hooks/user/useApplyInformationRequestTemplate";
import { useGetChainOfCommandTemplates } from "hooks/compliance/useGetChainOfCommandTemplates";

export const UltimateBeneficialOwnersForm: React.FC<
  UltimateBeneficialOwnersFormProps
> = ({ formId, onSubmit, informationRequest, setShowSubmit }) => {
  const [showIndirectBeneficiaryModal, setShowIndirectBeneficiaryModal] =
    useState(false);
  const navigate = useNavigate();
  const {
    control,
    handleSubmit,
    trigger,
    reset,
    formState: { dirtyFields },
    watch,
  } = useForm<Form>({
    // reValidateMode: "onBlur",,
    mode: "onBlur",
  });
  const { mutate } = useGetUltimateBeneficialOwner();
  const { applying, applyInformationRequestTemplate } =
    useApplyInformationRequestTemplate();
  const { data: templates } = useGetChainOfCommandTemplates();
  const {
    createUltimateBeneficialOwner,
    updateUltimateBeneficialOwner,
    loading,
  } = useUltimateBeneficialOwner();

  const onEdit = useCallback(
    (editUbo: UltimateBeneficialOwner | undefined) => {
      if (!editUbo) {
        reset({
          first_name: undefined,
          last_name: undefined,
          email: undefined,
          phone: undefined,
          tax_identification_number: undefined,
          national_id: undefined,
          nationality: undefined,
          politically_exposed: false,
          direct: false,
          shares: undefined,
          profession: undefined,
          civil_status: undefined,
          address: undefined,
          id_front_file: undefined,
          id_back_file: undefined,
          proof_of_address_document_file: undefined,
          id: undefined,
          id_front_file_url: undefined,
          id_back_file_url: undefined,
          proof_of_address_document_file_url: undefined,
        });
        return;
      }

      reset({
        ...editUbo,
        id: editUbo.id,
        shares: editUbo.shares?.toString(),
        address: {
          country: editUbo.country_residence,
          street_name: editUbo.street_name,
          city: editUbo.city,
          province: editUbo.province,
          postcode: editUbo.post_code,
          street_number: editUbo.street_number,
          floor: editUbo.floor,
          apartment: editUbo.apartment,
        },
        id_front_file_url: editUbo.ultimate_beneficial_owners_documents.find(
          (doc) => doc.document_type === UBO_DOCUMENT_TYPES.FRONT_ID,
        )?.document.files[0].url,
        id_back_file_url: editUbo.ultimate_beneficial_owners_documents.find(
          (doc) => doc.document_type === UBO_DOCUMENT_TYPES.BACK_ID,
        )?.document.files[0].url,
        proof_of_address_document_file_url:
          editUbo.ultimate_beneficial_owners_documents.find(
            (doc) =>
              doc.document_type ===
              UBO_DOCUMENT_TYPES.PROOF_OF_ADDRESS_DOCUMENT,
          )?.document.files[0].url,
      });
      navigate("./data");
    },
    [navigate, reset],
  );

  const createNewUbo = async (data: Form) => {
    await createUltimateBeneficialOwner({
      first_name: data.first_name,
      last_name: data.last_name,
      email: data.email,
      phone: data.phone,
      tax_identification_number: data.tax_identification_number,
      national_id: data.national_id,
      nationality: data.nationality,
      country_residence: data.address.country,
      street_name: data.address.street_name,
      city: data.address.city,
      province: data.address.province,
      post_code: data.address.postcode,
      street_number: data.address.street_number,
      floor: data.address.floor,
      apartment: data.address.apartment,
      politically_exposed: data.politically_exposed,
      shares: +data.shares.replace(/[-%]/g, "").replace(",", "."),
      profession: data.profession,
      civil_status: data.civil_status,
      obligated_subject: data.obligated_subject,
      direct: true,
      documents: [
        data.id_back_file && {
          document_type: UBO_DOCUMENT_TYPES.BACK_ID,
          file: data.id_back_file,
        },
        data.id_front_file && {
          document_type: UBO_DOCUMENT_TYPES.FRONT_ID,
          file: data.id_front_file,
        },
        data.proof_of_address_document_file && {
          document_type: UBO_DOCUMENT_TYPES.PROOF_OF_ADDRESS_DOCUMENT,
          file: data.proof_of_address_document_file,
        },
      ].filter((doc) => !!doc),
    });

    navigate("./");
  };

  const updateUbo = async (data: Form) => {
    if (!data.id) {
      return;
    }

    if (Object.keys(dirtyFields).length === 0) {
      navigate("./");

      return;
    }

    const documents: UltimateBeneficialOwnerDocumentForm[] = [
      dirtyFields.id_back_file && {
        document_type: UBO_DOCUMENT_TYPES.BACK_ID,
        file: data.id_back_file,
      },
      dirtyFields.id_front_file && {
        document_type: UBO_DOCUMENT_TYPES.FRONT_ID,
        file: data.id_front_file,
      },
      dirtyFields.proof_of_address_document_file && {
        document_type: UBO_DOCUMENT_TYPES.PROOF_OF_ADDRESS_DOCUMENT,
        file: data.proof_of_address_document_file,
      },
    ].filter(
      (doc) => !!doc,
    ) as unknown as UltimateBeneficialOwnerDocumentForm[];

    await updateUltimateBeneficialOwner(data.id, {
      first_name: data.first_name,
      last_name: data.last_name,
      email: data.email,
      phone: data.phone,
      tax_identification_number: data.tax_identification_number,
      national_id: data.national_id,
      nationality: data.nationality,
      country_residence: data.address.country,
      street_name: data.address.street_name,
      city: data.address.city,
      province: data.address.province,
      post_code: data.address.postcode,
      street_number: data.address.street_number,
      floor: data.address.floor,
      apartment: data.address.apartment,
      politically_exposed: data.politically_exposed,
      shares: +data.shares.replace(/[-%]/g, "").replace(",", "."),
      profession: data.profession,
      civil_status: data.civil_status,
      obligated_subject: data.obligated_subject,
      direct: true,
      documents,
    });

    navigate("./");
    mutate();
  };

  //   (e) => {
  //   e.preventDefault();
  //   onSubmit({
  //     id: informationRequest.id.toString(),
  //     response: "uploaded",
  //   });
  // }
  return (
    <form
      id={formId}
      onSubmit={(e) => {
        e.preventDefault();
        setShowIndirectBeneficiaryModal(true);
      }}
      className="h-full w-full overflow-auto"
    >
      <IndirectBeneficiaryModal
        open={showIndirectBeneficiaryModal}
        setOpen={setShowIndirectBeneficiaryModal}
        onConfirm={async () => {
          if (templates && templates.length > 0) {
            await applyInformationRequestTemplate({
              template_id: templates[0].id,
              notify: true,
            });
          }
          setShowIndirectBeneficiaryModal(false);
          onSubmit({
            id: informationRequest.id.toString(),
            response: "uploaded",
          });
        }}
        onCancel={() => {
          setShowIndirectBeneficiaryModal(false);
          onSubmit({
            id: informationRequest.id.toString(),
            response: "uploaded",
          });
        }}
      />
      {(loading || applying) && <LoadingAnimation />}
      <FadeInRouter prefix="">
        <Routes>
          <Route
            path="/"
            element={
              <Intro
                setShowSubmit={setShowSubmit}
                control={control}
                onEdit={onEdit}
                onNext={async () => {
                  const isValid = await trigger();

                  if (isValid) {
                    onEdit(undefined);
                    navigate("./data");
                  }
                }}
                informationRequest={informationRequest}
              />
            }
          />
          <Route
            path="/data"
            element={
              <DataFirstStep
                setShowSubmit={setShowSubmit}
                control={control}
                onNext={async () => {
                  const isValid = await trigger();

                  if (isValid) {
                    navigate("./documents");
                  }
                }}
              />
            }
          />
          <Route
            path="/documents"
            element={
              <DataSecondStep
                control={control}
                onNext={handleSubmit(
                  async (data) => {
                    if (data.id) {
                      await updateUbo(data);
                    } else {
                      await createNewUbo(data);
                    }
                  },
                  (error) => {
                    console.log(error);
                  },
                )}
                watch={watch}
              />
            }
          />
        </Routes>
      </FadeInRouter>
    </form>
  );
};
