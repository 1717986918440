import { useCallback } from "react";

export enum LOCAL_STORAGE_KEYS {
  SWORN_DECLARATION_SENT = "swornDeclarationSent",
}
export const useLocalStorage = (): {
  set: (key: LOCAL_STORAGE_KEYS, value: string) => void;
  get: (key: LOCAL_STORAGE_KEYS) => string | null;
  remove: (key: LOCAL_STORAGE_KEYS) => void;
  clear: () => void;
} => {
  const set = useCallback((key: LOCAL_STORAGE_KEYS, value: string) => {
    localStorage.setItem(key, value);
  }, []);
  const get = useCallback((key: string) => {
    return localStorage.getItem(key);
  }, []);
  const remove = useCallback((key: LOCAL_STORAGE_KEYS) => {
    localStorage.removeItem(key);
  }, []);
  const clear = useCallback(() => {
    localStorage.clear();
  }, []);

  return { set, get, remove, clear };
};
