import { GetRegionsParams, HubRegion } from "@letsbit/malcolmlb";
import { ApiContext } from "providers/ApiProvider";
import { useContext } from "react";
import useSWR, { SWRResponse } from "swr";

export const SWR_GET_REGIONS_KEY = "get-regions-key";

export const useGetRegions = (
  params: GetRegionsParams,
): SWRResponse<HubRegion[] | undefined> => {
  const { publicInstance } = useContext(ApiContext);

  const swr = useSWR(
    [SWR_GET_REGIONS_KEY, params],
    async () => {
      if (params.countryIsoCode) {
        const { data } = await publicInstance.hub.getRegions(params);
        return data;
      } else {
        return undefined;
      }
    },
    { revalidateIfStale: false },
  );

  return swr;
};
