import { defineMessages } from "react-intl";

export const scope = "pages.OnBoardingRoutes.InformationRequestUpload";

export const messages = defineMessages({
  title: {
    id: `${scope}.title`,
    defaultMessage: "Declaraciones juradas",
  },
  sendButton: {
    id: `${scope}.sendButton`,
    defaultMessage: "ACTUALIZAR CAMBIOS",
  },
  goBack: {
    id: `${scope}.goBack`,
    defaultMessage: "VOLVER",
  },
});
