import { defineMessages } from "react-intl";

export const scope = "components.PrecogsFormItem";

export const messages = defineMessages({
  errorRequired: {
    id: `${scope}.errorRequired`,
    defaultMessage: "Este campo es requerido",
  },
  selectDateRangeFrom: {
    id: `${scope}.selectDateRangeFrom`,
    defaultMessage: "Desde",
  },

  selectDateRangeTo: {
    id: `${scope}.selectDateRangeTo`,
    defaultMessage: "Hasta",
  },

  selectDateRangeDone: {
    id: `${scope}.selectDateRangeDone`,
    defaultMessage: "OK",
  },
  selectDateRangeCancel: {
    id: `${scope}.selectDateRangeCancel`,
    defaultMessage: "Cancelar",
  },
});
