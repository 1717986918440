import {
  ApplyRequestTemplatesParams,
  InformationRequests,
} from "@letsbit/malcolmlb";
import { useCallback, useContext, useState } from "react";
import { ApiContext } from "providers/ApiProvider";
import { defineMessages, useIntl } from "react-intl";
import { useNotification } from "hooks/utils/useNotification";
import { NotificationStatus } from "components/Notification/types";

export const SWR_APPLY_INFORMATION_REQUEST_TEMPLATES =
  "apply-information-request-templates";

export const scope = "hook.useCreateJuridicalPersonData";

export const messages = defineMessages({
  defaultErrorNotificationMessage: {
    id: `${scope}.defaultErrorNotificationMessage`,
    defaultMessage: "No pudimos iniciar la solicitud de aumento de límite",
  },
  notificationTitle: {
    id: `${scope}.notificationTitle`,
    defaultMessage: "Aumento de limites",
  },
});

export const useApplyInformationRequestTemplate = () => {
  const [applying, setApplying] = useState(false);
  const { memberInstance } = useContext(ApiContext);
  const { showNotification } = useNotification();
  const { formatMessage } = useIntl();

  const applyInformationRequestTemplate = useCallback(
    async (
      params: ApplyRequestTemplatesParams,
    ): Promise<InformationRequests[]> => {
      setApplying(true);
      try {
        const { data: ir } =
          await memberInstance.compliance.applyInformationRequestTemplate(
            params,
          );
        return ir;
      } catch (error) {
        console.error(error);
        showNotification({
          title: formatMessage(messages.notificationTitle),
          message: formatMessage(messages.defaultErrorNotificationMessage),
          status: NotificationStatus.ERROR,
        });
        throw error;
      } finally {
        setApplying(false);
      }
    },
    [formatMessage, memberInstance.compliance, showNotification],
  );

  return {
    applying,
    applyInformationRequestTemplate,
  };
};
