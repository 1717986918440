import { FormattedMessage } from "react-intl";
import { messages } from "./messages";
import { ReactComponent as SuccessImage } from "assets/images/success.svg";

export const TosAcceptedPage: React.FC = () => {
  return (
    <div className="flex h-full w-full flex-col items-center justify-center p-8">
      <SuccessImage />
      <h1 className="mb-8 w-full text-center font-['Tomato_Grotesk'] text-4xl font-bold text-[--milkylb-color-secondary-600]">
        <FormattedMessage {...messages.title} values={{ br: <br /> }} />
      </h1>
    </div>
  );
};
