import { defineMessages } from "react-intl";

export const scope = "components.OnBoardingForeign.StepsPage";

export const messages = defineMessages({
  start: {
    id: `${scope}.start`,
    defaultMessage: "EMPEZAR",
  },
  title: {
    id: `${scope}.title`,
    defaultMessage: "Completemos tu cuenta",
  },
  description: {
    id: `${scope}.description`,
    defaultMessage: "Para avanzar necesitaremos que tengas a mano documentación de tu empresa y que cargues documentación acerca de su compañía.",
  },

  step1Title: {
    id: `${scope}.step1Title`,
    defaultMessage: "Número de identificación tributaria",
  },

  step2Title: {
    id: `${scope}.step2Title`,
    defaultMessage: "Datos de la compañía",
  },
  step2Desc2: {
    id: `${scope}.step2Desc2`,
    defaultMessage: "Razón social y Domicilio",
  },
  step2Desc3: {
    id: `${scope}.step2Desc3`,
    defaultMessage: "Nombre y mail de representante legal",
  },
  step2Desc4: {
    id: `${scope}.step2Desc4`,
    defaultMessage: "Fecha y número de inscripción Registral",
  },
  step2Desc5: {
    id: `${scope}.step2Desc5`,
    defaultMessage: "Estatuto o contrato social",
  },
  step2Desc6: {
    id: `${scope}.step2Desc6`,
    defaultMessage: "Acta de asamblea de designación de autoridades",
  },

  step3Title: {
    id: `${scope}.step3Title`,
    defaultMessage: "Documentación de la compañía",
  },
  step3Desc1: {
    id: `${scope}.step3Desc1`,
    defaultMessage: "Le pediremos que suba documentación que respalde su operatoria.",
  },

  step3Desc2: {
    id: `${scope}.step3Desc2`,
    defaultMessage: "Nuestro equipo de cumplimiento analizará la información cargada y le notificará el estado de cada uno de los documentos.",
  },

})