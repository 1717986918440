import { defineMessages } from "react-intl";

export const scope = "components.WalletSelector";

export const messages = defineMessages({
  onAccountLabel: {
    id: `${scope}.onAccountLabel`,
    defaultMessage: "En cuenta",
  },
  selectWalletPlaceholder: {
    id: `${scope}.selectWalletPlaceholder`,
    defaultMessage: "Selecciona una cuenta",
  },
  originAccountTitle: {
    id: `${scope}.originAccountTitle`,
    defaultMessage: "Cuenta de origen",
  },
  searchPlaceholder: {
    id: `${scope}.searchPlaceholder`,
    defaultMessage: "Buscar",
  },
});
