import { AccountAdvisor } from "@letsbit/malcolmlb";
import { useContext } from "react";
import { SWRResponse } from "swr";

import { ApiContext } from "providers/ApiProvider";
import { useAuthSWR } from "hooks/utils/useAuthSWR";

export const SWR_ACCOUNT_ADVISOR_KEY = "get-account-advisor";

export const useGetAccountAdvisor = (): SWRResponse<AccountAdvisor, Error> => {
  const { memberInstance } = useContext(ApiContext);

  const swr = useAuthSWR(SWR_ACCOUNT_ADVISOR_KEY, async () => {
    const { data } = await memberInstance.hub.getAdvisor();
    return data;
  });

  return swr;
};
