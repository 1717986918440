import { defineMessages } from "react-intl";

export const scope = "utils.formMessages";

export const formMessages = defineMessages({
  minLengthFieldError: {
    id: `${scope}.minLengthFieldError`,
    defaultMessage: "El campo debe tener al menos {length}",
  },
  maxLengthFieldError: {
    id: `${scope}.maxLengthFieldError`,
    defaultMessage: "El campo debe tener menos de {length}",
  },
  requiredFieldError: {
    id: `${scope}.requiredFieldError`,
    defaultMessage: "Campo obligatorio",
  },
  emailFieldError: {
    id: `${scope}.emailFieldError`,
    defaultMessage: "Email no válido",
  },
  activityMaxLengthError: {
    id: `${scope}.activityFieldError`,
    defaultMessage: "Alcanzaste el límite de caracteres.",
  },
  chooseDate: {
    id: `${scope}.chooseDate`,
    defaultMessage: "Elige una fecha",
  },
  twoFactorLengthError: {
    id: `${scope}.twoFactorLengthError`,
    defaultMessage: "El código ingresado debe tener 6 números",
  },
  noCountryError: {
    id: `${scope}.noCountryError`,
    defaultMessage: "La dirección no posee pais",
  },
  noCityError: {
    id: `${scope}.noCityError`,
    defaultMessage: "La dirección no posee pais",
  },
  noStreetError: {
    id: `${scope}.noStreetError`,
    defaultMessage: "La dirección no posee calle o número",
  },
  noStateError: {
    id: `${scope}.noStateError`,
    defaultMessage: "La dirección no posee provincia",
  },
});
