import { useMemo } from "react";
import EventTracker from "./eventTracker";
import PostHogAdapter from "./posthog";
import { usePostHog } from "posthog-js/react";

export const useEventTracking = (): EventTracker => {
  const posthog = usePostHog();
  const tracker = useMemo(
    () => new EventTracker([new PostHogAdapter(posthog)]),
    [posthog],
  );

  return tracker;
};
