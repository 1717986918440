import React, { PropsWithChildren } from "react";
import classnames from "classnames";

import { ItemArrowProps } from "./types";
import { ReactComponent as Foward } from "../../assets/images/icons/simple-arrow.svg";

import "./styles.scss";

export const ItemArrow: React.FC<PropsWithChildren<ItemArrowProps>> = ({
  onClick,
  className,
  children,
}) => {
  return (
    <div className={classnames("item-arrow", className)} onClick={onClick}>
      {children}
      <Foward className="item-arrow__icon" />
    </div>
  );
};

export default ItemArrow;
