import { defineMessages } from "react-intl";

export const scope = "pages.OnBoardingLocal.CompanyRepresentativePage";

export const messages = defineMessages({
  title: {
    id: `${scope}.title`,
    defaultMessage: "Representante legal",
  },
  continueButton: {
    id: `${scope}.continueButton`,
    defaultMessage: "CONTINUAR",
  },
  warning: {
    id: `${scope}.warning`,
    defaultMessage:
      "¡Atención! Para continuar necesitarás contar con un cuenta de Let’sBit creada para un representante legal. Puedes bajar la app desde {href}",
  },
  href: {
    id: `${scope}.href`,
    defaultMessage: "aquí",
  },
});
