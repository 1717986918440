import { Action, History } from "history";
import { useContext, useEffect, useRef } from "react";
import { UNSAFE_NavigationContext } from "react-router-dom";

interface NavBlockerControl {
  confirm: () => void;
  cancel: () => void;
}

interface NavBlocker {
  enabled?: boolean;
}

export const useNavBlocker = ({
  enabled,
}: NavBlocker): React.MutableRefObject<NavBlockerControl | null> => {
  const { block } = useContext(UNSAFE_NavigationContext).navigator as History;

  // Latest ref pattern
  // Latest version of the function stored to the onBlockRef
  const onBlockRef = useRef<NavBlockerControl | null>(null);

  useEffect(() => {
    if (!enabled) {
      return;
    }

    let isActive = false;

    const unblock = block(({ retry, action }) => {
      // Only block if the action is POP (backward navigation)
      if (action === Action.Pop) {
        if (isActive) {
          unblock();
          return retry();
        }

        onBlockRef.current = {
          confirm: retry,
          cancel: () => {
            isActive = false;
          },
        };

        isActive = true;
      } else {
        unblock();
        return retry();
      }
    });

    return unblock;
  }, [block, enabled]);

  return onBlockRef;
};
