import { ACCOUNT_ADVISOR_TYPE } from "@letsbit/malcolmlb";
import { useGetAccountAdvisor } from "hooks/user/useGetAccountAdvisor";
import { useIntercom } from "react-use-intercom";

export const useIntercomCustom = () => {
  const { data: accountAdvisor } = useGetAccountAdvisor();
  const intercomHooks = useIntercom();

  const customShow = () => {
    if (
      accountAdvisor &&
      accountAdvisor.type === ACCOUNT_ADVISOR_TYPE.PERSONAL_ADVISOR
    ) {
      window.open(accountAdvisor.advisor.contact_link);
    } else {
      intercomHooks.show();
    }
  };

  return {
    ...intercomHooks,
    show: customShow,
    accountAdvisor,
  };
};
