import { Button, Input } from "@letsbit/milkylb";
import React from "react";
import { useIntl, FormattedMessage } from "react-intl";
import { Controller, useForm } from "react-hook-form";
import { ReactComponent as WarningIcon } from "assets/images/icons/warning.svg";

import { messages } from "./messages";
import { useGetNationalIdTypes } from "hooks/compliance/useGetNationalIdTypes";
import {
  PERSON_TYPES,
  PRODUCT_NAME_TERMS_AND_CONDITION,
} from "@letsbit/malcolmlb";
import LoadingAnimation from "components/LoadingAnimation";
import { CustomerForm, JuridicalCustomerFormProps } from "./types";
import classNames from "classnames";
import { ReactComponent as InfoIcon } from "assets/images/icons/info.svg";
import { formMessages } from "utils/formMessages";
import { useGetTermsAndConditions } from "hooks/compliance/useGetTermsAndConditions";
import { useTermsAndConditions } from "hooks/compliance/useTermsAndConditions";

export const JuridicalCustomerForm: React.FC<JuridicalCustomerFormProps> = ({
  onSubmit,
  country,
  className,
  form,
  rules = [],
}) => {
  const { handleSubmit, control } = useForm<CustomerForm>({
    mode: "onBlur",
    defaultValues: form,
  });
  const { formatMessage } = useIntl();

  const { data: nationalIdtypes } = useGetNationalIdTypes({
    country,
    person_type: PERSON_TYPES.JURIDICAL,
  });
  const nit = nationalIdtypes?.[0];

  const {
    data: termsAndConditions,
    mutate: mutateTyc,
    isLoading: isLoadingTyc,
    error: errorTyc,
  } = useGetTermsAndConditions({
    product_name: PRODUCT_NAME_TERMS_AND_CONDITION.PLATFORM,
  });
  const { acceptTermsAndConditions } = useTermsAndConditions();

  return nit && !isLoadingTyc && !errorTyc && termsAndConditions ? (
    <form
      onSubmit={handleSubmit(async ({ national_id }) => {
        await Promise.all(
          termsAndConditions?.map((tyc) =>
            acceptTermsAndConditions({ version_control_id: tyc.id }),
          ),
        );
        await mutateTyc();
        onSubmit({
          country,
          national_id_type: nit.type,
          national_id,
          person_type: PERSON_TYPES.JURIDICAL,
        });
      })}
      className={classNames(
        className,
        "fadeInSimple relative flex flex-col items-center justify-center",
      )}
      data-cypress-id="submit-cuit__form"
    >
      <div className="h-full">
        <Controller
          control={control}
          rules={{
            required: formatMessage(formMessages.requiredFieldError),
            ...rules,
          }}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <div>
              <label className="text-lg font-normal text-[--color-neutral-0]">
                <FormattedMessage
                  {...messages.inputDescriptionNationalIdType}
                  values={{
                    national_id_type: nit.type.toUpperCase(),
                  }}
                />
              </label>
              <Input
                placeholder={formatMessage(
                  messages.companyPlaceholderNationalIdType,
                  {
                    national_id_type: nit.type.toUpperCase(),
                  },
                )}
                value={value}
                onIonInput={onChange}
                error={!!error}
                message={error?.message}
                data-cypress-id="input-cuit"
              />
            </div>
          )}
          name="national_id"
        />
        <div
          className={
            "flex items-center text-xs font-semibold text-[--milkylb-color-neutral-400]"
          }
        >
          <WarningIcon className="shrink-0" />{" "}
          <p className="ml-2">
            <FormattedMessage {...messages.warning} />
          </p>
        </div>
      </div>
      <div className="flex w-full flex-col justify-end">
        {termsAndConditions && termsAndConditions.length > 0 && (
          <div
            className={
              "mb-[14px] flex w-full items-center text-xs font-semibold text-[--milkylb-color-neutral-400]"
            }
          >
            <InfoIcon className="shrink-0" />
            <p className="ml-2">
              <FormattedMessage
                {...messages.termsAndConditions}
                values={{
                  br: <br />,
                  link: termsAndConditions.map((tyc) => (
                    <a
                      className="text-[--milkylb-color-secondary-200] underline-offset-1"
                      href={tyc.url}
                      target="_blank"
                    >
                      {tyc.friendly_name}
                    </a>
                  )),
                }}
              />
            </p>
          </div>
        )}
        <Button
          type="submit"
          style={{ width: 197 }}
          className="mt-8 w-full self-end"
          isLoading={isLoadingTyc}
        >
          <FormattedMessage {...messages.continueButton} />
        </Button>
      </div>
    </form>
  ) : (
    <LoadingAnimation />
  );
};
