import React, { useMemo, useState } from "react";
import { FormattedMessage } from "react-intl";

import { messages } from "./messages";
import { BackButton } from "components/BackButton";
import { Button } from "@letsbit/milkylb";
import { ReactComponent as UploadIcon } from "assets/images/icons/upload-simple.svg";
import { InformationRequestUploadPageProps } from "./types";
import { USER_DOCUMENTATION_TYPE } from "@letsbit/malcolmlb";
import { SwornDeclarationUpload } from "./components/SwornDeclarationUpload";
import { GenericUpload } from "./components/GenericUpload";
import { InformationRequestLabel } from "../InformationRequestDisplay";
import { useParams } from "react-router-dom";
import LoadingAnimation from "components/LoadingAnimation";
import ReCaptchaProvider from "providers/ReCaptchaProvider";
import { UltimateBeneficialOwnersForm } from "components/UltimateBeneficialOwnersForm";

export const InformationRequestUploadPage: React.FC<
  InformationRequestUploadPageProps
> = ({
  informationRequests,
  onSubmit,
  directorMemberEmail,
  directorName,
  loading,
}) => {
  const formId = "InformationRequestUploadPage__upload";
  const { id } = useParams<{ id: string }>();
  const [showSubmit, setShowSubmit] = useState(true);

  const getRenderUpload = (
    informationRequestUploadType: string | undefined,
  ) => {
    switch (informationRequestUploadType) {
      case USER_DOCUMENTATION_TYPE.SWORN_DECLARATION:
        return SwornDeclarationUpload;
      case USER_DOCUMENTATION_TYPE.ULTIMATE_BENEFICIAL_OWNERS:
        return UltimateBeneficialOwnersForm;
      default:
        return GenericUpload;
    }
  };

  const informationRequest = useMemo(
    () => informationRequests?.find((ir) => ir.id.toString() === id),
    [informationRequests, id],
  );

  const RenderUpload = getRenderUpload(informationRequest?.upload_type);

  return informationRequest ? (
    <ReCaptchaProvider>
      <div className="flex h-full flex-col p-10">
        <div className="mb-8 flex items-center justify-between border-b border-[--milkylb-color-secondary-200] pb-8">
          <div className="flex flex-col items-start">
            <BackButton className="mb-4" />
            <div className="flex">
              <h1 className="font-['Tomato_Grotesk'] text-4xl font-bold text-[--milkylb-color-neutral-600]">
                {informationRequest.subject}
              </h1>
              <InformationRequestLabel
                label={informationRequest.status}
                className="ml-8 self-center"
              />
            </div>
          </div>
          <div className="flex justify-center ">
            {showSubmit && (
              <Button
                className="mr-4"
                type="submit"
                form={formId}
                isLoading={loading}
              >
                <UploadIcon className="mr-2" />
                <FormattedMessage {...messages.sendButton} />
              </Button>
            )}
          </div>
        </div>
        <RenderUpload
          directorName={directorName}
          directorMemberEmail={directorMemberEmail}
          formId={formId}
          onSubmit={onSubmit}
          informationRequest={informationRequest}
          setShowSubmit={setShowSubmit}
        />
      </div>
    </ReCaptchaProvider>
  ) : (
    <LoadingAnimation />
  );
};
