import { defineMessages } from "react-intl";

export const scope = "components.FileDropzone";

export const messages = defineMessages({
  loadedFiles: {
    id: `${scope}.loadedFiles`,
    defaultMessage: "Archivos cargados",
  },
  emptyCaseFiles: {
    id: `${scope}.emptyCaseFiles`,
    defaultMessage: "No has cargado ningún archivo",
  },
});