import { Button, Input } from "@letsbit/milkylb";
import React, { useContext } from "react";
import { useIntl, FormattedMessage } from "react-intl";
import { Controller, useForm } from "react-hook-form";
import classNames from "classnames";

import { messages } from "./messages";
import {
  RepresentativeFormProps,
  RepresentativeForm as RepForm,
} from "./types";
import { formMessages } from "utils/formMessages";
import { EmailSchema } from "utils/validators";
import { UserContext } from "providers/UserProvider";
import { LegalRepresentativeWarningCard } from "../../../components/LegalRepresentativeWarningCard";

export const RepresentativeForm: React.FC<RepresentativeFormProps> = ({
  onSubmit,
  className,
  form,
  children,
}) => {
  const { handleSubmit, control } = useForm<RepForm>({
    mode: "onBlur",
    defaultValues: form,
  });
  const { formatMessage } = useIntl();
  const { user } = useContext(UserContext);

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className={classNames(
        className,
        "fadeInSimple relative flex flex-col items-center justify-center",
      )}
      data-cypress-id="rep__legal-form"
    >
      <div className="h-full w-full">
        <Controller
          control={control}
          rules={{
            required: formatMessage(formMessages.requiredFieldError),
          }}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <div>
              <label className="text-lg font-normal text-[--color-neutral-0]">
                <FormattedMessage {...messages.inputNameDescription} />
              </label>
              <Input
                placeholder={formatMessage(messages.inputNamePlaceholder)}
                value={value}
                onIonInput={onChange}
                error={!!error}
                message={error?.message}
                data-cypress-id="rep__legal-name__input"
              />
            </div>
          )}
          name="director_name"
        />
        <Controller
          control={control}
          rules={{
            required: formatMessage(formMessages.requiredFieldError),
            validate: (email) => {
              if (!EmailSchema.safeParse(email).success) {
                return formatMessage(formMessages.emailFieldError);
              } else if (
                email.toLowerCase() === user?.email.toLocaleLowerCase()
              ) {
                return formatMessage(messages.sameEmailError);
              }
            },
          }}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <div>
              <label className="text-lg font-normal text-[--color-neutral-0]">
                <FormattedMessage {...messages.inputEmailDescription} />
              </label>
              <Input
                placeholder={formatMessage(messages.inputEmailPlaceholder)}
                value={value}
                onIonInput={onChange}
                error={!!error}
                message={error?.message}
                data-cypress-id="rep__legal-email__input"
              />
            </div>
          )}
          name="director_member_email"
        />
        {children}
      </div>
      <LegalRepresentativeWarningCard />
      <Button
        type="submit"
        style={{ width: 197 }}
        className="mt-8 w-full self-end"
      >
        <FormattedMessage {...messages.continueButton} />
      </Button>
    </form>
  );
};
