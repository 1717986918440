import { useContext } from "react";
import { useGetMemberStatus } from "hooks/compliance/useGetMemberStatus";
import { UserContext } from "providers/UserProvider";

export const useGetUserCountry = (): string | undefined => {
  const { user } = useContext(UserContext);
  const { data: memberStatus } = useGetMemberStatus();

  if (memberStatus?.country) {
    return memberStatus.country.toUpperCase()
  } else {
    return user?.data.country_residence?.toUpperCase();
  }
};
