import { GetCountriesParams, HubCountry } from "@letsbit/malcolmlb";
import { ApiContext } from "providers/ApiProvider";
import { useContext } from "react";
import useSWR, { SWRResponse } from "swr";

export const SWR_GET_COUNTRIES_KEY = "get-countries-key";

export const useGetCountries = (
  params: GetCountriesParams,
): SWRResponse<HubCountry[]> => {
  const { publicInstance } = useContext(ApiContext);

  const swr = useSWR(
    [SWR_GET_COUNTRIES_KEY, params],
    async () => {
      const { data } = await publicInstance.hub.getCountries(params);
      return data;
    },
    { revalidateIfStale: false },
  );

  return swr;
};
