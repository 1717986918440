import { Button, Error, Input } from "@letsbit/milkylb";
import { Modal } from "components/Modal";
import usePlacesAutocomplete from "use-places-autocomplete";
import { messages } from "./messages";
import { useIntl } from "react-intl";
import { AddressInputProps } from "./types";
import ItemArrow from "components/ItemArrow";
import { ReactComponent as PinLocationIcon } from "assets/images/icons/pin-location.svg";
import AddressIncomplete from "assets/images/delete-virtual-card.svg";
import { PlaceDetails, usePlaceDetails } from "hooks/utils/usePlaceDetails";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { formMessages } from "utils/formMessages";
import InfoScreen from "components/InfoScreen";
import { useGetCountries } from "hooks/utils/useGetCountries";
import { useGetRegions } from "hooks/utils/useGetRegions";
import { countryMap } from "utils/countries";
import { useGetMemberStatus } from "hooks/compliance/useGetMemberStatus";
import { HubCountry, HubRegion } from "@letsbit/malcolmlb";
import CustomFormattedMessage from "components/CustomFormattedMessage";
import debounce from "lodash.debounce";

enum STEP {
  GEOLOCATION = "GEOLOCATION",
  INCOMPLETE = "INCOMPLETE",
  COUNTRY_MANUAL = "COUNTRY_MANUAL",
  REGION_MANUAL = "REGION_MANUAL",
  CITY_MANUAL = "CITY_MANUAL",
  STREET_MANUAL = "STREET_MANUAL",
  DETAILS = "DETAILS",
}

export const AddressInput: React.FC<AddressInputProps> = ({
  country,
  onSelect,
  place,
  dataCypressId,
  onBlur,
  ...rest
}) => {
  const {
    control,
    handleSubmit,
    setValue: setValueForm,
    watch,
  } = useForm<PlaceDetails & { place_details: PlaceDetails }>();
  const [step, setStep] = useState(STEP.GEOLOCATION);
  const [hasPostcode, setHasPostcode] = useState(false);
  const [wasOpened, setWasOpened] = useState(false);
  const [searchCountry, setSearchCountry] = useState<string>("");
  const [countryIso, setCountryIso] = useState<string>("");
  const [searchRegion, setSearchRegion] = useState<string>("");
  const [open, setOpen] = useState(false);
  const { data: memberStatus } = useGetMemberStatus();
  const { formatMessage } = useIntl();
  const {
    value: inputValue,
    setValue,
    suggestions: { status, data },
  } = usePlacesAutocomplete({
    requestOptions: {
      componentRestrictions: {
        country: country?.toLowerCase() || null,
      },
      language: "es",
      types: ["address"],
    },
    // ms delay before making a request to Google Maps Places API
    debounce: 500,
  });
  const { getPlaceDetails } = usePlaceDetails();

  const nextLegalAddressPage = useCallback(
    (
      legalAddress: Partial<PlaceDetails>,
      dontShowIncompletePage?: boolean,
    ): STEP => {
      if (
        (!legalAddress.province ||
          !legalAddress.province ||
          !legalAddress.city ||
          !legalAddress.street_name ||
          !legalAddress.street_number) &&
        !dontShowIncompletePage
      ) {
        return STEP.INCOMPLETE;
      } else if (!legalAddress.country) {
        return STEP.COUNTRY_MANUAL;
      } else if (!legalAddress.province) {
        return STEP.REGION_MANUAL;
      } else if (!legalAddress.city) {
        return STEP.CITY_MANUAL;
      } else if (!legalAddress.street_name || !legalAddress.street_number) {
        return STEP.STREET_MANUAL;
      } else {
        return STEP.DETAILS;
      }
    },
    [],
  );

  const street_number = watch("street_number");
  const street_name = watch("street_name");
  const city = watch("city");
  const province = watch("province");
  const postcode = watch("postcode");
  const countryForm = watch("country");
  const deboucedSetSearchCountry = debounce(setSearchCountry, 500);
  const deboucedSetSearchRegion = debounce(setSearchRegion, 500);

  const regionText = useMemo(() => {
    return (country || memberStatus?.country || "AR") === countryMap.AR
      ? formatMessage(messages.chooseProvince)
      : formatMessage(messages.chosseRegion);
  }, [formatMessage, country, memberStatus?.country]);

  const { data: countries } = useGetCountries({
    search: searchCountry,
    limit: 100,
  });

  const { data: regions } = useGetRegions({
    countryIsoCode: countryIso,
    search: searchRegion,
    limit: 100,
  });

  const form = () => {
    switch (step) {
      case STEP.GEOLOCATION:
        return (
          <Controller
            control={control}
            render={({ field: { onChange }, fieldState: { error } }) => (
              <>
                <Input
                  onIonInput={(e) => {
                    setValue(e.target.value as string);
                  }}
                  value={inputValue}
                  placeholder={formatMessage(messages.placeholderSearch)}
                  type="text"
                  inputmode="text"
                  autocomplete="off"
                  data-cypress-id={dataCypressId}
                  key="input-place-details-form"
                />
                {status === "OK" &&
                  data.length > 0 &&
                  data.map((p: google.maps.places.AutocompletePrediction) => (
                    <ItemArrow
                      onClick={async () => {
                        const placeDetails = await getPlaceDetails(
                          formatMessage(messages.cantGetPlaceDetails),
                          p.place_id,
                        );

                        if (placeDetails) {
                          Object.entries(placeDetails).map(
                            ([key, value]: [string, string]) => {
                              setValueForm(key as keyof PlaceDetails, value);
                            },
                          );

                          onChange(placeDetails);
                          handleSubmit((values) => {
                            setHasPostcode(!!placeDetails.postcode);
                            setStep(nextLegalAddressPage(values));
                          })();
                        }
                      }}
                      key={p.place_id}
                      className="cursor-pointer"
                    >
                      <div
                        style={{ display: "flex" }}
                        data-cypress-id={`${p.description}`}
                      >
                        <PinLocationIcon />
                        <div>{p.description}</div>
                      </div>
                    </ItemArrow>
                  ))}
                <Error error={!!error} message={error?.message} />
              </>
            )}
            name="place_details"
          />
        );
      case STEP.INCOMPLETE:
        return (
          <form
            id="address-input-incomplete-form"
            className="fadeInSimple flex h-full w-full flex-col"
            onSubmit={(e) => {
              e.stopPropagation();
              e.preventDefault();
              handleSubmit((values) =>
                setStep(nextLegalAddressPage(values, true)),
              )(e);
            }}
          >
            <InfoScreen
              picture={AddressIncomplete}
              pictureClassName="h-[200px]"
              info={
                <>
                  <h2 className="mb-6 font-['Tomato_Grotesk'] text-2xl font-bold text-[--milkylb-color-neutral-600]">
                    <CustomFormattedMessage {...messages.incompleteDataTitle} />
                  </h2>
                  <p className="mb-6 text-lg text-[--milkylb-color-neutral-600]">
                    <CustomFormattedMessage
                      {...messages.incompleteDataMessage}
                    />
                  </p>
                </>
              }
              filledButton={{
                text: formatMessage(messages.retryButton),
                onSubmit: () => setStep(STEP.GEOLOCATION),
              }}
              outlineButton={{
                text: formatMessage(messages.manualInputButton),
                type: "submit",
                form: "address-input-incomplete-form",
              }}
            />
          </form>
        );
      case STEP.COUNTRY_MANUAL:
        return (
          <Controller
            control={control}
            key="input-country-form-controller"
            render={({ field: { onChange }, fieldState: { error } }) => (
              <>
                <Input
                  onIonInput={(e) => {
                    deboucedSetSearchCountry(e.target.value as string);
                  }}
                  value={searchCountry}
                  placeholder={formatMessage(messages.chooseCountry)}
                  type="text"
                  inputmode="text"
                  autocomplete="off"
                  key="input-country-form"
                />
                {countries &&
                  countries.length > 0 &&
                  countries.map((c: HubCountry) => (
                    <ItemArrow
                      onClick={() => {
                        onChange(c.name);
                        setCountryIso(c.isoCode);
                        handleSubmit((values) =>
                          setStep(nextLegalAddressPage(values, true)),
                        )();
                      }}
                      key={c.isoCode}
                      className="cursor-pointer"
                    >
                      <div style={{ display: "flex" }}>
                        <PinLocationIcon />
                        <div>{c.name}</div>
                      </div>
                    </ItemArrow>
                  ))}
                <Error error={!!error} message={error?.message} />
              </>
            )}
            name="country"
          />
        );
      case STEP.REGION_MANUAL:
        return (
          <Controller
            control={control}
            key="input-region-form-controller"
            render={({ field: { onChange }, fieldState: { error } }) => (
              <>
                <Input
                  onIonInput={(e) => {
                    deboucedSetSearchRegion(e.target.value as string);
                  }}
                  value={searchCountry}
                  placeholder={regionText}
                  type="text"
                  inputmode="text"
                  autocomplete="off"
                  key="input-region-form"
                />
                {regions &&
                  regions.length > 0 &&
                  regions.map((r: HubRegion) => (
                    <ItemArrow
                      onClick={() => {
                        onChange(r.name);
                        handleSubmit((values) =>
                          setStep(nextLegalAddressPage(values, true)),
                        )();
                      }}
                      key={r.isoCode}
                      className="cursor-pointer"
                    >
                      <div style={{ display: "flex" }}>
                        <PinLocationIcon />
                        <div>{r.name}</div>
                      </div>
                    </ItemArrow>
                  ))}
                <Error error={!!error} message={error?.message} />
              </>
            )}
            name="province"
          />
        );
      case STEP.CITY_MANUAL:
        return (
          <form
            id="address-input-city-form"
            className="fadeInSimple flex h-full w-full flex-col"
            onSubmit={(e) => {
              e.stopPropagation();
              e.preventDefault();
              handleSubmit((values) =>
                setStep(nextLegalAddressPage(values, true)),
              )(e);
            }}
          >
            <div className="flex h-full w-full flex-1 flex-col gap-4">
              <Controller
                control={control}
                key="input-city-form-controller"
                rules={{
                  required: formatMessage(formMessages.requiredFieldError),
                }}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <Input
                    onIonInput={(e) => {
                      onChange(e.target.value as string);
                    }}
                    value={value}
                    placeholder={formatMessage(messages.chooseCity)}
                    type="text"
                    inputmode="text"
                    autocomplete="off"
                    error={!!error}
                    message={error?.message}
                    key="input-city-form"
                  />
                )}
                name="city"
              />
            </div>
            <Button
              form="address-input-city-form"
              type="submit"
              className="mt-8 w-full self-end"
            >
              <CustomFormattedMessage {...messages.continueButton} />
            </Button>
          </form>
        );
      case STEP.STREET_MANUAL:
        return (
          <form
            id="address-input-street-form"
            className="fadeInSimple flex h-full w-full flex-col"
            onSubmit={(e) => {
              e.stopPropagation();
              e.preventDefault();
              handleSubmit((values) =>
                setStep(nextLegalAddressPage(values, true)),
              )(e);
            }}
          >
            <div className="flex h-full w-full flex-1 flex-col gap-4">
              <Controller
                control={control}
                rules={{
                  required: formatMessage(formMessages.requiredFieldError),
                }}
                key="input-street-name-form-controller"
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <Input
                    onIonInput={(e) => {
                      onChange(e.target.value as string);
                    }}
                    value={value}
                    placeholder={formatMessage(messages.chooseStreetName)}
                    type="text"
                    inputmode="text"
                    autocomplete="off"
                    error={!!error}
                    message={error?.message}
                    key="input-street-name-form"
                  />
                )}
                name="street_name"
              />
              <Controller
                control={control}
                rules={{
                  required: formatMessage(formMessages.requiredFieldError),
                }}
                key="input-street-number-form-controller"
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <Input
                    onIonInput={(e) => {
                      onChange(e.target.value as string);
                    }}
                    value={value}
                    placeholder={formatMessage(messages.chooseStreetNumber)}
                    type="text"
                    inputmode="text"
                    autocomplete="off"
                    error={!!error}
                    message={error?.message}
                    key="input-street-number-form"
                  />
                )}
                name="street_number"
              />
            </div>
            <Button
              form="address-input-street-form"
              type="submit"
              className="mt-8 w-full self-end"
            >
              <CustomFormattedMessage {...messages.continueButton} />
            </Button>
          </form>
        );
      case STEP.DETAILS:
        return (
          <form
            id="address-input-details-form"
            className="fadeInSimple flex h-full w-full flex-col"
            onSubmit={(e) => {
              e.stopPropagation();
              e.preventDefault();
              handleSubmit((data) => {
                onSelect(data);
                setOpen(false);
              })(e);
            }}
          >
            <h2 className="mb-6 font-['Tomato_Grotesk'] text-2xl font-bold text-[--milkylb-color-neutral-600]">
              {street_number} {street_name}, {city}, {province} {postcode},{" "}
              {countryForm}
            </h2>
            <div className="flex h-full w-full flex-1 flex-col gap-4">
              <Controller
                control={control}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <Input
                    placeholder={formatMessage(messages.floorPlaceholder)}
                    value={value}
                    onIonInput={onChange}
                    error={!!error}
                    message={error?.message}
                    data-cypress-id="address__details-floor__input"
                    key="input-floor-form"
                  />
                )}
                name="floor"
              />
              <Controller
                control={control}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <Input
                    placeholder={formatMessage(messages.apartmentPlaceholder)}
                    value={value}
                    onIonInput={onChange}
                    error={!!error}
                    message={error?.message}
                    data-cypress-id="address__details-apartment__input"
                    key="input-apartment-form"
                  />
                )}
                name="apartment"
              />
              <Controller
                control={control}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <Input
                    placeholder={formatMessage(
                      messages.adtionalReferencesPlaceholder,
                    )}
                    value={value}
                    onIonInput={onChange}
                    error={!!error}
                    message={error?.message}
                    data-cypress-id="address__details-aditional__info__input"
                    key="input-additional-info-form"
                  />
                )}
                name="additional_info"
              />
              {!hasPostcode && (
                <Controller
                  control={control}
                  rules={{
                    required: formatMessage(formMessages.requiredFieldError),
                  }}
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <div>
                      <Input
                        placeholder={formatMessage(
                          messages.postcodePlaceholder,
                        )}
                        value={value}
                        onIonInput={onChange}
                        error={!!error}
                        message={error?.message}
                        key="input-postcode-form"
                      />
                    </div>
                  )}
                  name="postcode"
                />
              )}
            </div>
            <Button
              type="submit"
              form="address-input-details-form"
              data-cypress-id={`${dataCypressId}-submit-form`}
              className="mt-8 w-full self-end"
            >
              <CustomFormattedMessage {...messages.continueButton} />
            </Button>
          </form>
        );
    }
  };

  const openHandler = () => {
    setStep(STEP.GEOLOCATION);
    setOpen(true);
  };

  useEffect(() => {
    if (!open && wasOpened) {
      onBlur?.();
    } else if (open) {
      setWasOpened(true);
    }
  }, [onBlur, open, wasOpened]);

  return (
    <>
      <Modal
        isOpen={open}
        onDidDismiss={() => setOpen(false)}
        withArrow={false}
        className="flex flex-col"
      >
        <h3 className="mb-8 text-center text-xl font-bold">
          <CustomFormattedMessage {...messages.title} />
        </h3>
        {form()}
      </Modal>
      <Input
        {...rest}
        onIonFocus={openHandler}
        onClick={openHandler}
        value={
          place &&
          `${place.street_number} ${place.street_name}, ${place.city}, ${place.province} ${place.postcode}, ${place.country}`
        }
        placeholder={formatMessage(messages.placeholder)}
      />
    </>
  );
};
