import { useCallback, useContext, useMemo, useState } from "react";
import { UserContext } from "providers/UserProvider";
import { SWR_CURRENCIES_KEY, useCurrencies } from "hooks/user/useCurrencies";
import { SWR_GET_HUB_BALANCES_KEY } from "./useGetHubBalances";
import { useMutateAll } from "hooks/utils/useMutateAll";
import { defineMessages, useIntl } from "react-intl";
import { useNotification } from "hooks/utils/useNotification";
import { NotificationStatus } from "components/Notification/types";


export const scope = "hook.useCoinValues";

export const messages = defineMessages({
  preferredCurrencySuccessNotificationMessage: {
    id: `${scope}.preferredCurrencySuccessNotificationMessage`,
    defaultMessage: "Moneda de preferencia cambiado con exito",
  },
  preferredCurrencyErrorNotificationMessage: {
    id: `${scope}.preferredCurrencyErrorNotificationMessage`,
    defaultMessage: "No pudimos cambiar tu moneda de preferencia",
  },
  notificationTitle: {
    id: `${scope}.notificationTitle`,
    defaultMessage: "Preferencias de usuarios",
  },
});


export const useCoinValues = () => {
  const [loading, setLoading] = useState(false);
  const { user, updateUserData, getUser } = useContext(UserContext);
  const { data: currencies } = useCurrencies();
  const { formatMessage } = useIntl();
  const { showNotification } = useNotification();
  const preferredCurrency = useMemo(
    () =>
      currencies?.find(
        (currency) => currency.id === (user?.data?.preferred_currency || "pax")
      ),
    [currencies, user?.data?.preferred_currency]
  );
  const { mutateAll } = useMutateAll();

  const getFavCurrencyValue = useCallback(
    (coin: string, balance: string): string => {
      const currency = currencies?.find((curr) => curr.id === coin);
      return String(+(currency?.price || 0) * +balance);
    },
    [currencies]
  );

  const setPreferredCurrency = async (id: string) => {
    try {
      setLoading(true);
      await updateUserData({ preferred_currency: id });
      try {
        await getUser();
      } catch (e) {
        console.error(e);
      }
      mutateAll(SWR_GET_HUB_BALANCES_KEY);
      mutateAll(SWR_CURRENCIES_KEY);
      showNotification({
        title: formatMessage(messages.notificationTitle),
        message: formatMessage(messages.preferredCurrencySuccessNotificationMessage),
        status: NotificationStatus.SUCCESS
      })
    } catch (e) {
      console.error(e)
      showNotification({
        title: formatMessage(messages.notificationTitle),
        message: formatMessage(messages.preferredCurrencyErrorNotificationMessage),
        status: NotificationStatus.ERROR
      })
    } finally {
      setLoading(false)
    }
  };

  return {
    getFavCurrencyValue,
    preferredCurrency,
    setPreferredCurrency,
    loading,
  };
};
