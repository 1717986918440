import React from "react";
import { FormattedMessage } from "react-intl";

import { messages } from "./messages";
import { JpdConfirmPageProps } from "../types";
import dayjs from "dayjs";
import { FormatedValue } from "components/FormatedValue";
import { Button } from "@letsbit/milkylb";
import { BackButton } from "components/BackButton";
import { ReactComponent as EditIcon } from "assets/images/icons/edit.svg";
import { Link } from "react-router-dom";

export const JpdConfirmPage: React.FC<JpdConfirmPageProps> = ({
  onSubmit,
  juridicalPersonData,
}) => {
  return (
    <div className="relative flex w-full flex-col justify-center">
      <div className="flex  w-full flex-col justify-between px-10 py-[5%] pt-[2%]">
        <div>
          <div className="flex w-full flex-row border-b-2 border-[#754FAD] pb-5">
            <div className=" flex w-full flex-col">
              <BackButton className="pt-0" />
              <h1 className="w-full font-['Tomato_Grotesk'] text-4xl font-bold text-[--milkylb-color-neutral-600] pt-5">
                <FormattedMessage {...messages.title} />
              </h1>
            </div>
            <div className="flex flex-row items-center">
              <Link className="" to="../company-name">
                <Button className="letsbit-ui-button__no-padding" fill="clear">
                  <EditIcon className="mr-2" />
                  <FormattedMessage {...messages.editButton} />
                </Button>
              </Link>
              <Button
                onClick={() => onSubmit()}
                style={{ width: 197 }}
                className="w-full"
                data-cypress-id="confirmation-continue__button"
              >
                <FormattedMessage {...messages.continueButton} />
              </Button>
            </div>
          </div>
          <ul className="flex max-w-xl flex-col text-right pt-10">
            <li className="flex items-center justify-between border-y border-[#FAFAFA]">
              <label className="font-bold text-[--milkylb-color-neutral-800]">
                <FormattedMessage {...messages.companyLabel} />
              </label>
              <div className="py-2 text-[--milkylb-color-neutral-400]">
                {juridicalPersonData.company_name}
              </div>
            </li>
            <li className="flex items-center justify-between border-b border-[#FAFAFA]">
              <label className="font-bold text-[--milkylb-color-neutral-800]">
                <FormattedMessage {...messages.addressLabel} />
              </label>
              <div className="py-2 text-[--milkylb-color-neutral-400]">
                {juridicalPersonData.street_name}{" "}
                {juridicalPersonData.street_number}{" "}
                {juridicalPersonData.floor || ""}
                {juridicalPersonData.apartment || ""}
                <br />
                {juridicalPersonData.city} {juridicalPersonData.province}
              </div>
            </li>
            <li className="flex items-center justify-between border-b border-[#FAFAFA]">
              <label className="font-bold text-[--milkylb-color-neutral-800]">
                <FormattedMessage {...messages.dateLabel} />
              </label>
              <div className="py-2 text-[--milkylb-color-neutral-400]">
                {dayjs(juridicalPersonData.registration_date).format(
                  "DD/MM/YYYY"
                )}
              </div>
            </li>
            <li className="flex items-center justify-between border-b border-[#FAFAFA]">
              <label className="font-bold text-[--milkylb-color-neutral-800]">
                <FormattedMessage {...messages.idNumberLabel} />
              </label>
              <div className="py-2 text-[--milkylb-color-neutral-400]">
                {juridicalPersonData.registration_number}
              </div>
            </li>
            <li className="flex justify-between border-b py-2 border-[#FAFAFA]">
              <label className="font-bold text-[--milkylb-color-neutral-800]">
                <FormattedMessage {...messages.objectiveLabel} />
              </label>
              <div className="text-[--milkylb-color-neutral-400]">
                <FormattedMessage
                  id="JpdConfirmPage.options"
                  defaultMessage={juridicalPersonData.purpose.replaceAll(
                    ",",
                    "{br}"
                  )}
                  values={{ br: <br /> }}
                />
              </div>
            </li>
            <li className="flex items-center justify-between border-b border-[#FAFAFA]">
              <label className="font-bold text-[--milkylb-color-neutral-800]">
                <FormattedMessage {...messages.amountLabel} />
              </label>
              <div className="py-2 text-[--milkylb-color-neutral-400]">
                <FormatedValue
                  value={juridicalPersonData.estimated_amount_monthly}
                  precision={2}
                />{" "}
                USD
                <br />
                <FormattedMessage {...messages.monthlyLabel} />
              </div>
            </li>
            <li className="flex items-center justify-between border-b border-[#FAFAFA]">
              <label className="font-bold text-[--milkylb-color-neutral-800]">
                <FormattedMessage {...messages.mailLabel} />
              </label>
              <div className="py-2 text-[--milkylb-color-neutral-400]">
                {juridicalPersonData.director_member_email}
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};
