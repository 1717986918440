import {
  InformationRequestTemplate,
  LIMITS_APPLIES_TO,
} from "@letsbit/malcolmlb";
import { useContext } from "react";
import useSWR, { SWRResponse } from "swr";
import { ApiContext } from "providers/ApiProvider";

export const SWR_GET_CHAIN_OF_COMMAND_TEMPLATES =
  "get-chain-of-command-templates";

export const useGetChainOfCommandTemplates = (): SWRResponse<
  InformationRequestTemplate[],
  Error
> => {
  const { memberInstance } = useContext(ApiContext);
  const swr = useSWR(SWR_GET_CHAIN_OF_COMMAND_TEMPLATES, async () => {
    const { data } =
      await memberInstance.compliance.getInformationRequestTemplates({
        applies_to: LIMITS_APPLIES_TO.ONBOARDING_CONTROL_CHAIN,
      });

    return data;
  });

  return swr;
};
