import React, { useRef, useEffect, ReactNode, useCallback } from "react";
import { createVoidContext } from "utils/voidContext";
import axios from "axios";

interface CSRFContext {
  csrfTokenRef: React.MutableRefObject<string | null>;
  setCSRFToken: (token: string) => void;
  deleteCSRFToken: () => void;
}

export const CSRFContext = React.createContext<CSRFContext>(
  createVoidContext("CSRF-context"),
);

export type Props = {
  children: ReactNode;
};

export const CSRF_TOKEN = "lb_csrf_token";
export const CSRF_HEADER = "X-CSRF-Token";

const CSRFProvider = (props: Props) => {
  const { children } = props;
  const csrfTokenRef = useRef<string | null>(null);

  const setHeaders = useCallback((value: string) => {
    axios.defaults.headers.post[CSRF_HEADER] = value;
    axios.defaults.headers.put[CSRF_HEADER] = value;
    axios.defaults.headers.delete[CSRF_HEADER] = value;
    axios.defaults.headers.patch[CSRF_HEADER] = value;
  }, []);

  useEffect(() => {
    const token = localStorage.getItem(CSRF_TOKEN);
    csrfTokenRef.current = token;

    if (token) {
      setHeaders(token);
    }
  }, [setHeaders]);

  const setCSRFToken = useCallback(
    (token: string) => {
      csrfTokenRef.current = token;
      localStorage.setItem(CSRF_TOKEN, token);

      setHeaders(token);
    },
    [setHeaders],
  );

  const deleteCSRFToken = useCallback(() => {
    csrfTokenRef.current = null;
    localStorage.removeItem(CSRF_TOKEN);

    setHeaders("");
  }, [setHeaders]);

  const contextValue = {
    csrfTokenRef,
    setCSRFToken,
    deleteCSRFToken,
  };

  return (
    <CSRFContext.Provider value={contextValue}>{children}</CSRFContext.Provider>
  );
};

export default CSRFProvider;
