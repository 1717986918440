import { useContext, useEffect } from "react";

import { LocationContext } from "providers/LocationProvider";
import { FadeInRouterProps } from "./types";
import classNames from "classnames";

export const FadeInRouter: React.FC<FadeInRouterProps> = ({
  prefix,
  children,
  className,
}) => {
  const {
    transitionStage,
    addPrefix,
  } = useContext(LocationContext);

  useEffect(() => {
    addPrefix(prefix);
  }, [prefix, addPrefix]);

  return (
    <section
      className={classNames(className, transitionStage)}
    >
      {children}
    </section>
  );
};
