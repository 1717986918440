export enum NotificationStatus {
  INFO = "INFO",
  ERROR = "ERROR",
  WARNING = "WARNING",
  SUCCESS = "SUCCESS",
}

export type Notification = NotificationProps;

export interface NotificationProps {
  title: string;
  message: string | JSX.Element;
  className?: string;
  status: NotificationStatus;
  forwardedRef?: React.LegacyRef<HTMLDivElement>;
  onCancel?: React.MouseEventHandler<SVGSVGElement>;
}
