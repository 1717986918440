import { HubVerificationEnterprise } from "@letsbit/malcolmlb";
import { useContext } from "react";
import { SWRResponse } from "swr";
import { ApiContext } from "providers/ApiProvider";
import { useAuthSWR } from "hooks/utils/useAuthSWR";

export const SWR_GET_OBOARDING_STEPS = "get-onboarding-steps";

export const useGetOnBoardingSteps = (): SWRResponse<HubVerificationEnterprise, Error> => {
  const { memberInstance } = useContext(ApiContext);
  const swr = useAuthSWR(SWR_GET_OBOARDING_STEPS, async () => {
    const { data } = await memberInstance.hub.getVerificationEnterpriseStep();
    return data;
  });

  return swr;
};
