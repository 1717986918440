import { Modal } from "components/Modal";
import { GoBackModalProps } from "../types";
import { Button } from "@letsbit/milkylb";
import CustomFormattedMessage from "components/CustomFormattedMessage";
import { messages } from "../messages";

export const GoBackModal: React.FC<GoBackModalProps> = ({
  open,
  setOpen,
  onConfirm,
  onCancel,
}) => {
  const continueHandler = () => {
    setOpen(false);
    onConfirm();
  };

  const cancelHandler = () => {
    setOpen(false);
    onCancel();
  };

  return (
    <Modal
      isOpen={open}
      withArrow={false}
      onDidDismiss={cancelHandler}
      classNameModalWrapper="UltimateBeneficialOwnersForm_GoBackModal"
    >
      <div className="flex flex-col items-center">
        <h1 className="mb-4 text-2xl font-bold text-[--milkylb-color-red-error]">
          <CustomFormattedMessage {...messages.modalTitle} />
        </h1>
        <p className="text-center text-sm leading-[14px]">
          <CustomFormattedMessage {...messages.modalDescription} />
        </p>
        <div className="mt-4 flex gap-x-2">
          <Button onClick={continueHandler} fill="clear">
            <CustomFormattedMessage {...messages.modalBack} />
          </Button>
          <Button onClick={cancelHandler}>
            <CustomFormattedMessage {...messages.modalCancel} />
          </Button>
        </div>
      </div>
    </Modal>
  );
};
