import { defineMessages } from "react-intl";

export const scope = "pages.OnBoarding.components.CustomerForm";

export const messages = defineMessages({
  inputDescriptionCompanyName: {
    id: `${scope}.inputDescriptionCompanyName`,
    defaultMessage: "Nombre de la empresa",
  },
  placeholderCompanyName: {
    id: `${scope}.placeholderCompanyName`,
    defaultMessage: "Ingrese la razón social",
  },
  continueButton: {
    id: `${scope}.continueButton`,
    defaultMessage: "CONTINUAR",
  },
});
