import { ReactComponent as WarningIcon } from "assets/images/icons/warning.svg";
import { RejectedReasonWarningProps } from "./types";
import classNames from "classnames";

export const RejectedReasonWarning: React.FC<RejectedReasonWarningProps> = ({
  informationRequest,
  className,
}) => {
  return (
    <div
      className={classNames(
        className,
        "mb-8 flex rounded-2xl bg-[--color-warning-background] p-4",
      )}
    >
      <div className="mr-2">
        <WarningIcon className="mt-1 shrink-0" />
      </div>
      <p className="flex items-center justify-center font-semibold">
        {informationRequest.comments}
      </p>
    </div>
  );
};
