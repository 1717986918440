import { defineMessages } from "react-intl";

export const scope = "components.OnBoardingBlockVerificationPage";

export const messages = defineMessages({
  downloadOurApp: {
    id: `${scope}.downloadOurApp`,
    defaultMessage: "Necesitamos que continúes { br } desde nuestra APP",
  },
  downloadOurAppSubtitle: {
    id: `${scope}.downloadOurAppSubtitle`,
    defaultMessage:
      "Para que podamos brindarte una mejor experiencia y la { br } máxima seguridad para tu cuenta, necesitamos que { br } descargues nuestra app para dispositivos móviles. { br } {  br } Una vez verificado, podrás utilizar Let’sBit desde tu computadora.",
  },
  closeSession: {
    id: `${scope}.closeSession`,
    defaultMessage: "CERRAR SESIÓN",
  },
});
