import { Navigate, Route, Routes } from "react-router-dom";
import { AlreadyRegistredCustomerPage } from "./AlreadyRegistredCustomerPage";

export const OnBoardingErrorRoutes: React.FC = () => {
  return (
    <Routes>
      <Route
        path="/already-registered-customer"
        element={<AlreadyRegistredCustomerPage />}
      />
      <Route path="*" element={<Navigate to="/login" replace={true} />} />
    </Routes>
  );
};
