import { defineMessages } from "react-intl";

export const scope = "pages.OnBoarding.components.FundsOriginForm";

export const messages = defineMessages({
  foundOriginsLabel: {
    id: `${scope}.foundOriginsLabel`,
    defaultMessage: "Selecciona con qué fondos operarás",
  },
  ownFundsLabel: {
    id: `${scope}.ownFundsLabel`,
    defaultMessage: "Fondos Propios",
  },
  thirdsFundsLabel: {
    id: `${scope}.thirdsFundsLabel`,
    defaultMessage: "Fondos de clientes",
  },
  continueButton: {
    id: `${scope}.continueButton`,
    defaultMessage: "CONTINUAR",
  },
  infoLabel: {
    id: `${scope}.infoLabel`,
    defaultMessage:
      "Si procesas compraventas, pagos o transferencia de terceros, o tu actividad es de arbitraje, operador OTC, PSP o similar, selecciona fondo de clientes. ",
  },
});
