import { DropzoneRef } from "react-dropzone";

export type FileDropzoneProps = {
  files: File[] | undefined;
  onChange: (files: File[] | undefined) => void;
  error?: string;
  onBlur?: React.FocusEventHandler<HTMLInputElement> | undefined;
  className?: string;
  acceptedExtensions?: string[];
  inputName?: string;
  noClick?: boolean;
  fileDisplay?: boolean;
  dropzoneRef?: React.Ref<DropzoneRef>;
};

export const KB_TO_MB_RATIO = 1048576;
