import { defineMessages } from "react-intl";

export const scope = "pages.OnBoardingForeign.JpdCompanyAddressPage";

export const messages = defineMessages({
  title: {
    id: `${scope}.title`,
    defaultMessage: "Sede social de la empresa",
  },
  continueButton: {
    id: `${scope}.continueButton`,
    defaultMessage: "CONTINUAR",
  },
});
