import {
  QuotePricesType,
  useListQuotePrices,
} from "hooks/broker/useListQuotePrices";
import { Wallet, useWallets } from "hooks/user/useWallets";
import React, { ReactNode, useEffect } from "react";
import { createVoidContext } from "utils/voidContext";

interface DollarizeContext {
  buySellPrices: QuotePricesType | undefined;
  origin?: Wallet;
  intermediate?: Wallet;
  destiny?: Wallet;
}

export const DollarizeContext = React.createContext<DollarizeContext>(
  createVoidContext("dollarize-context"),
);

export type Props = {
  children: ReactNode;
};

const swapCurrencies = {
  origin: "ars",
  intermediate: "nuars",
  destiny: "usd",
};

const DollarizeProvider = (props: Props) => {
  const { buySellPrices, getBuySellPricesCustomPair } = useListQuotePrices();

  useEffect(() => {
    getBuySellPricesCustomPair({
      debit_amount: window.env.dollarizeDigitalUsdAmountForPrice,
      assets: [
        swapCurrencies.destiny.toUpperCase(),
        swapCurrencies.intermediate.toUpperCase(),
        swapCurrencies.origin.toUpperCase(),
      ],
    });
  }, [getBuySellPricesCustomPair]);

  const { children } = props;

  const { data: wallets } = useWallets();

  const contextValue: DollarizeContext = {
    buySellPrices,
    origin: wallets?.[swapCurrencies.origin],
    intermediate: wallets?.[swapCurrencies.intermediate],
    destiny: wallets?.[swapCurrencies.destiny],
  };

  return (
    <DollarizeContext.Provider value={contextValue}>
      {children}
    </DollarizeContext.Provider>
  );
};

export default DollarizeProvider;
