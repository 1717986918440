import { BrokerListPair, BrokerListParams } from "@letsbit/malcolmlb";
import { ApiContext } from "providers/ApiProvider";
import { useCallback, useContext, useState } from "react";

export interface QuotePricesType {
  buy: string;
  sell: string;
}
export interface QuotePricesBuySellParamsType {
  debit_amount: number;
  fstCurr: string;
  middleCurrency: string;
  sndCurr: string;
}

export interface QuotePricesParamsCustomType {
  debit_amount: number;
  assets: string[];
}

export const useListQuotePrices = (): {
  getQuotePriceList: (body: BrokerListParams[]) => Promise<BrokerListPair[]>;
  getBuySellPricesCustomPair: (param: QuotePricesParamsCustomType) => void;
  quotePrice: BrokerListPair[] | undefined;
  buySellPrices: QuotePricesType | undefined;
} => {
  const { publicInstance } = useContext(ApiContext);
  const [quotePrice, setQuotePrice] = useState<BrokerListPair[] | undefined>(
    undefined
  );
  const [buySellPrices, setBuySellPrices] = useState<
    QuotePricesType | undefined
  >(undefined);

  const getQuotePriceList = useCallback(
    async (body: BrokerListParams[]) => {
      const { data } = await publicInstance.broker.getList(body);
      setQuotePrice(data);
      return data;
    },
    [publicInstance]
  );

  const getBuySellPricesCustomPair = useCallback(
    async (params: QuotePricesParamsCustomType) => {
      const { assets, debit_amount } = params;
      if (assets.length < 2) {
        console.error("cannot make a pair");
        return;
      }

      let newDebitAmount: number = debit_amount;
      let sellPrice: BrokerListPair[] | undefined;

      for (let i = 0; i < assets.length - 1; i++) {
        // assets.length - 1 because i want to iterate n-1 times
        sellPrice = await getQuotePriceList([
          {
            debit_amount: newDebitAmount,
            pair_uid: `${assets[i].toUpperCase()}${assets[
              i + 1
            ].toUpperCase()}`,
          },
        ]);
        if (sellPrice && sellPrice.length >= 1) {
          newDebitAmount = sellPrice[0].credit_amount;
        } else {
          console.error("cannot get sell price");
          return;
        }
      }

      const assetReverse = assets.reverse();
      let newCreditAmount: number = newDebitAmount;
      let buyPrice: BrokerListPair[] | undefined;

      for (let i = 0; i < assetReverse.length - 1; i++) {
        buyPrice = await getQuotePriceList([
          {
            debit_amount: newCreditAmount,
            pair_uid: `${assets[i].toUpperCase()}${assets[
              i + 1
            ].toUpperCase()}`,
          },
        ]);
        if (buyPrice && buyPrice.length >= 1) {
          newCreditAmount = buyPrice[0].credit_amount;
        } else {
          console.error("cannot get buy price");
          return;
        }
      }
      if (newDebitAmount && newCreditAmount && debit_amount) {
        setBuySellPrices({
          buy: (+newDebitAmount / +newCreditAmount).toString() || "-",
          sell: (+newDebitAmount / +debit_amount).toString() || "-",
        });
      } else {
        console.error("null amounts");
        return;
      }
    },
    [getQuotePriceList]
  );

  return {
    getQuotePriceList,
    quotePrice,
    buySellPrices,
    getBuySellPricesCustomPair,
  };
};
