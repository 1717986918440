import { useCallback, useContext, useState } from "react";
import { Answer } from "@letsbit/malcolmlb";
import { ApiContext } from "providers/ApiProvider";


interface PrecogsFlagsLoading {
  isAnswerFlagLoading: boolean;
  isOmitFlagLoading: boolean;
  isPostponeFlagLoading: boolean;
}

export const usePrecogsFlags = (): {
  answerFlag: (
    flagId: number,
    form: Record<string, any>
  ) => Promise<Answer | undefined>;
  omitAnswerFlag: (flagId: number) => Promise<void>;
  postponeAnswerFlag: (flagId: number) => Promise<void>;
  isLoading: PrecogsFlagsLoading;
} => {
  const { memberInstance } = useContext(ApiContext);
  const [isLoading, setIsLoading] = useState<PrecogsFlagsLoading>({
    isAnswerFlagLoading: false,
    isOmitFlagLoading: false,
    isPostponeFlagLoading: false,
  });

  const answerFlag = useCallback(
    async (flagId: number, form: Record<string, any>) => {
      /* Changes the form object to the specteced type from the server */
      Object.keys(form).forEach((key) => {
        form[key] = { value: form[key] };
      });

      setIsLoading((loading) => {
        return { ...loading, isAnswerFlagLoading: true };
      });
      try {
        const { data } = await memberInstance.precogs.answer({
          flagId,
          data: form,
        });
        setIsLoading((loading) => {
          return { ...loading, isAnswerFlagLoading: false };
        });
        return data;
      } catch (e) {
        setIsLoading((loading) => {
          return { ...loading, isAnswerFlagLoading: false };
        });
        throw e;
      }
    },
    [memberInstance]
  );
  const omitAnswerFlag = useCallback(
    async (flagId: number) => {
      setIsLoading((loading) => {
        return { ...loading, isOmitFlagLoading: true };
      });
      try {
        await memberInstance.precogs.omitAnswer(flagId);
        setIsLoading((loading) => {
          return { ...loading, isOmitFlagLoading: false };
        });
      } catch (e) {
        setIsLoading((loading) => {
          return { ...loading, isOmitFlagLoading: false };
        });
        throw e;
      }
    },
    [memberInstance]
  );
  const postponeAnswerFlag = useCallback(
    async (flagId: number) => {
      setIsLoading((loading) => {
        return { ...loading, isPostponeFlagLoading: true };
      });
      try {
        await memberInstance.precogs.postponeAnswer(flagId);
        setIsLoading((loading) => {
          return { ...loading, isPostponeFlagLoading: false };
        });
      } catch (e) {
        setIsLoading((loading) => {
          return { ...loading, isPostponeFlagLoading: false };
        });
        throw e;
      }
    },
    [memberInstance]
  );

  return { answerFlag, omitAnswerFlag, postponeAnswerFlag, isLoading };
};
