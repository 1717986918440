import { defineMessages } from "react-intl";

export const scope = "components.SuccessContent";

export const messages = defineMessages({
  title: {
    id: `${scope}.title`,
    defaultMessage: "¡Listo!",
  },
  fstSubtitle: {
    id: `${scope}.fstSubtitle`,
    defaultMessage: "Nuestro equipo de cumplimiento ya cuenta con toda tu{br} documentación para poder habilitar la cuenta empresa.",
  },
  sndSubtitle: {
    id: `${scope}.fstSubtitle`,
    defaultMessage: "Se contactarán a la casilla proporcionada dentro de 48 horas.",
  },
  logOutButton: {
    id: `${scope}.logOutButton`,
    defaultMessage: "CERRAR SESIÓN",
  }
});
