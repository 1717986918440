import { defineMessages } from "react-intl";

export const scope = "components.LoadingAnimation";

export const prhases = defineMessages({
  dbz: {
    id: `${scope}.dbz`,
    defaultMessage: "Armando la genkidama",
  },
  csps: {
    id: `${scope}.csps`,
    defaultMessage: "Añadiendo la sustancia X",
  },
  minecraft: {
    id: `${scope}.minecraft`,
    defaultMessage: "Crafteando pico de diamante",
  },
  bttf: {
    id: `${scope}.minecraft`,
    defaultMessage: "Preparando el condensador de flujo",
  },
  xmen: {
    id: `${scope}.xman`,
    defaultMessage: "Afilando las garras de adamantium",
  },
  hp: {
    id: `${scope}.hp`,
    defaultMessage: "Preparando poción multijugos",
  },
  ironman: {
    id: `${scope}.ironman`,
    defaultMessage: "Configurando el traje de Iron Man",
  },
  sw: {
    id: `${scope}.sw`,
    defaultMessage: "Cargando la bateria de R2D2",
  },
  alien: {
    id: `${scope}.alien`,
    defaultMessage: "Llenando el tanque del Nostromo",
  },
});

export const messages = defineMessages({
  waiting: {
    id: `${scope}.waiting`,
    defaultMessage: "Un momento por favor",
  },
  subtitle: {
    id: `${scope}.subtitle`,
    defaultMessage: "Estamos cargando la informacion solicitada",
  },
});
