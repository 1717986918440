import { defineMessages } from "react-intl";

export const scope = "pages.OnBoardingLocal.ExtraDocumentsPage";

export const messages = defineMessages({
  title: {
    id: `${scope}.title`,
    defaultMessage: "Información a cargar",
  },
  notifyAdminButton: {
    id: `${scope}.notifyAdminButton`,
    defaultMessage: "ENVIAR DOCUMENTOS",
  },
  awaitingMember: {
    id: `${scope}.pendingDocs`,
    defaultMessage: "Pendientes de carga",
  },
  awaitingAdmin: {
    id: `${scope}.awaitingAdmin`,
    defaultMessage: "En revisión",
  },
  sendDocs: {
    id: `${scope}.sendDocs`,
    defaultMessage: "Solicitar Revisión",
  },
  notificationTitle: {
    id: `${scope}.notificationTitle`,
    defaultMessage: "¿Querés saber que info te vamos a pedir?",
  },
  linkTextAR: {
    id: `${scope}.linkText`,
    defaultMessage:
      "En éste artículo encontrarás un listado de todo lo necesario {br}para verificar tu cuenta de empresa. ",
  },
  link: {
    id: `${scope}.link`,
    defaultMessage: "Hacé click acá",
  },
  secLinkText: {
    id: `${scope}.secLinkText`,
    defaultMessage: " si {br}tu empresa es argentina o ",
  },
  thirstLinkText: {
    id: `${scope}.thirstLinkText`,
    defaultMessage: " si tu empresa {br}es de otro país.",
  },
  linkTextForeign: {
    id: `${scope}.linkTextForeign`,
    defaultMessage:
      "En éste artículo encontrarás un listado de todo lo necesario {br}para verificar tu cuenta. ",
  },
  alertText: {
    id: `${scope}.alertText`,
    defaultMessage:
      "¡Atención! Tenés documentos actualizados sin enviar para su revisión. ",
  },
  closeTabConfirmationDesc: {
    id: `${scope}.closeTabConfirmationDesc`,
    defaultMessage:
      "¡Atención! Tenés documentos actualizados sin enviar para su revisión. Si cierra la ventana se perderan los cambios.",
  },
  continue: {
    id: `${scope}.continue`,
    defaultMessage: "CONTINUAR",
  },
});
