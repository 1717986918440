import {
  DocumentTypeParams,
  MemberStatus,
} from "@letsbit/malcolmlb";
import { useContext } from "react";

import { ApiContext } from "providers/ApiProvider";
import { useAuthSWR } from "hooks/utils/useAuthSWR";

export const SWR_GET_NATIONAL_ID_TYPES_KEY = "get-national-id-types-data";

export interface MemberStatusWithExtra extends MemberStatus {
  country: string;
}

export const useGetNationalIdTypes = (params?: DocumentTypeParams) => {
  const { publicInstance } = useContext(ApiContext);

  const swr = useAuthSWR(
    params && [params, SWR_GET_NATIONAL_ID_TYPES_KEY],
    async () => {
      if (params) {
        const result = await publicInstance.compliance.getDocumentType(params);
        return result?.data;
      }
    },
    {
      revalidateIfStale: false,
    }
  );

  return swr;
};
