import React from "react";
import { FormattedMessage } from "react-intl";

import { CustomFormattedMessageProps } from "./types";

const bold: (chunks: React.ReactNode) => JSX.Element = (chunks) => {
  return <b>{chunks}</b>;
};

export const CustomFormattedMessage: React.FC<CustomFormattedMessageProps> = ({
  values,
  ...props
}) => {
  return (
    <FormattedMessage
      {...props}
      values={{
        ...values,
        br: <br />,
        break: <br />,
        hr: <hr />,
        bold: values?.bold ? values?.bold : bold,
        b: bold,
      }}
    />
  );
};

export default CustomFormattedMessage;
