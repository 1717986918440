import React, { useCallback, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Controller } from "react-hook-form";
import { Button, Input, RadioGroup, RadioItem } from "@letsbit/milkylb";

import { formMessages } from "utils/formMessages";
import { messages } from "../messages";
import { DataSecondStepProps } from "../types";
import { useOnLeavePage } from "hooks/utils/useOnLeavePage";
import { GoBackModal } from "./GoBackModal";
import { useNavBlocker } from "hooks/utils/useNavBlocker";
import { FileInput } from "components/FileInput";
import { InfoIcon } from "components/InfoIcon";
import CustomFormattedMessage from "components/CustomFormattedMessage";
import { useNavigate } from "react-router-dom";

export const DataSecondStep: React.FC<DataSecondStepProps> = ({
  onNext,
  watch,
  control,
}) => {
  const { formatMessage } = useIntl();
  const [goBackModal, setGoBackModal] = useState(false);
  const [changePerformed, setChangePerformed] = useState(false);
  const blockRef = useNavBlocker({ enabled: changePerformed });
  const navigate = useNavigate();

  useOnLeavePage({
    onUnloadPage: useCallback(
      (e: BeforeUnloadEvent) => {
        if (changePerformed) {
          e.preventDefault();
        }
      },
      [changePerformed],
    ),
    onBackPage: useCallback(
      (e: PopStateEvent) => {
        e.preventDefault();

        if (!goBackModal) {
          setTimeout(() => {
            setGoBackModal(true);
          }, 300);
        }
      },
      [goBackModal],
    ),
  });

  const onChangeWrapper = (handler: (...event: any[]) => any) => {
    return (...event: any) => {
      setChangePerformed(true);
      return handler(...event);
    };
  };

  const idFrontFileUrl = watch("id_front_file_url");
  const idBackFileUrl = watch("id_back_file_url");
  // const proofOfAddressDocumentFile = watch("proof_of_address_document_file_url");

  return (
    <form className="flex h-full flex-col">
      <GoBackModal
        open={goBackModal}
        setOpen={setGoBackModal}
        onConfirm={() => {
          blockRef.current?.confirm();
        }}
        onCancel={() => {
          blockRef.current?.cancel();
        }}
      />
      <div className="grid h-full w-2/3 grid-cols-2 gap-8">
        <Controller
          control={control}
          rules={{
            required: formatMessage(formMessages.requiredFieldError),
          }}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <div>
              <label className="grid-flow-row text-lg font-normal text-[--color-neutral-0]">
                <FormattedMessage {...messages.documentNumberLabel} />
              </label>
              <Input
                onIonChange={onChangeWrapper(onChange)}
                value={value}
                placeholder={formatMessage(messages.documentNumberPlaceholder)}
                error={!!error}
                message={error?.message}
              />
            </div>
          )}
          name="national_id"
        />

        <Controller
          control={control}
          rules={{
            required: formatMessage(formMessages.requiredFieldError),
          }}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <div>
              <label className="grid-flow-row text-lg font-normal text-[--color-neutral-0]">
                <FormattedMessage {...messages.taxIdentificationLabel} />
              </label>
              <Input
                onIonChange={onChangeWrapper(onChange)}
                value={value}
                placeholder={formatMessage(
                  messages.taxIdentificationPlaceholder,
                )}
                error={!!error}
                message={error?.message}
              />
            </div>
          )}
          name="tax_identification_number"
        />
        <Controller
          name="obligated_subject"
          control={control}
          defaultValue={false}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <div>
              <label className="grid-flow-row text-lg font-normal text-[--color-neutral-0]">
                <FormattedMessage {...messages.obligatedSubjectLabel} />
              </label>
              <RadioGroup
                value={value}
                onIonChange={onChange}
                error={!!error}
                message={error?.message}
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  gap: 8,
                }}
              >
                <RadioItem
                  key="yes"
                  label={formatMessage(messages.yesLabel)}
                  value={true}
                />
                <RadioItem
                  key="no"
                  label={formatMessage(messages.noLabel)}
                  value={false}
                />
              </RadioGroup>
            </div>
          )}
        />
        <Controller
          control={control}
          rules={{
            required: formatMessage(formMessages.requiredFieldError),
          }}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <div>
              <label className="grid-flow-row text-lg font-normal text-[--color-neutral-0]">
                <FormattedMessage {...messages.sharesLabel} />
              </label>
              <Input
                // type="number"
                onIonChange={onChangeWrapper((e) => onChange(e.target.value))}
                value={value}
                placeholder={formatMessage(messages.sharesPlaceholder)}
                error={!!error}
                message={error?.message}
              />
            </div>
          )}
          name="shares"
        />
        <Controller
          control={control}
          rules={{
            required: idFrontFileUrl
              ? false
              : formatMessage(formMessages.requiredFieldError),
          }}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <div>
              <label className="grid-flow-row text-lg font-normal text-[--color-neutral-0]">
                <FormattedMessage {...messages.idFrontLabel} />
              </label>
              <FileInput
                error={error?.message}
                onChange={onChange}
                file={value}
              />
              {idFrontFileUrl && (
                <a
                  className="text-sm text-[--milkylb-color-secondary-200] underline-offset-1"
                  target="_blank"
                  href={idFrontFileUrl}
                >
                  Ver documento
                </a>
              )}
            </div>
          )}
          name="id_front_file"
        />
        <Controller
          name="politically_exposed"
          control={control}
          defaultValue={false}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <div>
              <label className="grid-flow-row text-lg font-normal text-[--color-neutral-0]">
                <FormattedMessage {...messages.pepLabel} />
              </label>
              <RadioGroup
                value={value}
                onIonChange={onChange}
                error={!!error}
                message={error?.message}
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  gap: 8,
                }}
              >
                <RadioItem
                  key="yes"
                  label={formatMessage(messages.yesLabel)}
                  value={true}
                />
                <RadioItem
                  key="no"
                  label={formatMessage(messages.noLabel)}
                  value={false}
                />
              </RadioGroup>
              <InfoIcon>
                <CustomFormattedMessage
                  values={{
                    link: (
                      <a
                        className="text-[--milkylb-color-secondary-400]"
                        target="_blank"
                        href="https://www.argentina.gob.ar/normativa/nacional/resoluci%C3%B3n-35-2023-380196/texto"
                      >
                        link
                      </a>
                    ),
                  }}
                  {...messages.pepInfo}
                />
              </InfoIcon>
            </div>
          )}
        />
        <Controller
          control={control}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <div>
              <label className="grid-flow-row text-lg font-normal text-[--color-neutral-0]">
                <FormattedMessage {...messages.idBackLabel} />
              </label>
              <FileInput
                error={error?.message}
                onChange={onChangeWrapper(onChange)}
                file={value}
              />
              {idBackFileUrl && (
                <a target="_blank" href={idBackFileUrl}>
                  Ver documento
                </a>
              )}
            </div>
          )}
          name="id_back_file"
        />
      </div>
      <div className="flex justify-end">
        <Button
          type="button"
          className="mr-4"
          onClick={() => {
            navigate(-1);
          }}
          fill="clear"
        >
          <FormattedMessage {...messages.previous} />
        </Button>
        <Button type="button" className="mr-4" onClick={onNext}>
          <FormattedMessage {...messages.save} />
        </Button>
      </div>
    </form>
  );
};
