import React from "react";
import { FormattedMessage } from "react-intl";
import { useNavigate } from "react-router-dom";

import { messages } from "./messages";
import { CompanyRepresentativePageProps } from "../types";
import { RepresentativeForm } from "../../components/forms/RepresentativeForm";
import { LogOutButton } from "components/LogOutButton";
import { BackButton } from "components/BackButton";

export const CompanyRepresentativePage: React.FC<
  CompanyRepresentativePageProps
> = ({ onSubmit }) => {
  const navigate = useNavigate();

  return (
    <div className="fadeInSimple relative flex h-full w-full flex-col items-center justify-center">
      <BackButton
        onClick={() => navigate("../")}
        className="absolute left-[40px] top-[24px]"
      />

      <LogOutButton className="absolute right-[40px] top-[24px]" />
      <div className="flex h-full flex-col py-[5%]">
        <h1 className="mb-8 w-full font-['Tomato_Grotesk'] text-4xl font-bold text-[--milkylb-color-neutral-600]">
          <FormattedMessage {...messages.title} />
        </h1>
        <RepresentativeForm className="w-[448px] grow" onSubmit={onSubmit} />
      </div>
    </div>
  );
};
