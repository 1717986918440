import React from "react";
import { FormattedMessage } from "react-intl";

import { messages } from "./messages";
import { JpdConfirmPageProps } from "../types";
import dayjs from "dayjs";
import { FormatedValue } from "components/FormatedValue";
import { Button } from "@letsbit/milkylb";
import { LogOutButton } from "components/LogOutButton";
import { BackButton } from "components/BackButton";
import { Link } from "react-router-dom";
import { ReactComponent as EditIcon } from "assets/images/icons/edit.svg";

export const JpdConfirmPage: React.FC<JpdConfirmPageProps> = ({
  onSubmit,
  companyData,
}) => {
  return (
    <div className="relative flex h-full w-full flex-col items-center justify-center">
      <BackButton className="absolute left-[40px] top-[24px]" />

      <LogOutButton className="absolute right-[40px] top-[24px]" />
      <div className="flex h-full w-full flex-col justify-between px-10 py-[5%]">
        <div>
          <h1 className="mb-8 w-full font-['Tomato_Grotesk'] text-4xl font-bold text-[--milkylb-color-neutral-600]">
            <FormattedMessage {...messages.title} />
          </h1>
          <ul className="flex max-w-xl flex-col text-right">
            <li className="flex items-center justify-between">
              <label className="font-bold text-[--milkylb-color-neutral-800]">
                <FormattedMessage {...messages.companyLabel} />
              </label>
              <div className="py-2 text-[--milkylb-color-neutral-400]">
                {companyData.company_name}
              </div>
            </li>
            <li className="flex items-center justify-between">
              <label className="font-bold text-[--milkylb-color-neutral-800]">
                <FormattedMessage {...messages.addressLabel} />
              </label>
              <div className="py-2 text-[--milkylb-color-neutral-400]">
                {companyData.street_name} {companyData.street_number}{" "}
                {companyData.floor || ""}
                {companyData.apartment || ""}
                <br />
                {companyData.city} {companyData.province}
              </div>
            </li>
            <li className="flex items-center justify-between">
              <label className="font-bold text-[--milkylb-color-neutral-800]">
                <FormattedMessage {...messages.dateLabel} />
              </label>
              <div className="py-2 text-[--milkylb-color-neutral-400]">
                {dayjs(companyData.registration_date).format("DD/MM/YYYY")}
              </div>
            </li>
            <li className="flex items-center justify-between">
              <label className="font-bold text-[--milkylb-color-neutral-800]">
                <FormattedMessage {...messages.idNumberLabel} />
              </label>
              <div className="py-2 text-[--milkylb-color-neutral-400]">
                {companyData.registration_number}
              </div>
            </li>
            <li className="flex items-center justify-between">
              <label className="font-bold text-[--milkylb-color-neutral-800]">
                <FormattedMessage {...messages.objectiveLabel} />
              </label>
              <div className="text-[--milkylb-color-neutral-400]">
                <FormattedMessage
                  id="JpdConfirmPage.options"
                  defaultMessage={companyData.purpose.replaceAll(",", "{br}")}
                  values={{ br: <br /> }}
                />
              </div>
            </li>
            <li className="flex items-center justify-between">
              <label className="font-bold text-[--milkylb-color-neutral-800]">
                <FormattedMessage {...messages.amountLabel} />
              </label>
              <div className="py-2 text-[--milkylb-color-neutral-400]">
                <FormatedValue
                  value={companyData.estimated_amount_monthly}
                  precision={2}
                />{" "}
                USD
                <br />
                <FormattedMessage {...messages.monthlyLabel} />
              </div>
            </li>
            <li className="flex items-center justify-between">
              <label className="font-bold text-[--milkylb-color-neutral-800]">
                <FormattedMessage {...messages.mailLabel} />
              </label>
              <div className="py-2 text-[--milkylb-color-neutral-400]">
                {companyData.director_member_email}
              </div>
            </li>
            <Link className="self-end" to="../company-name">
              <Button className="letsbit-ui-button__no-padding" fill="clear">
                <EditIcon className="mr-2" />
                <FormattedMessage {...messages.editButton} />
              </Button>
            </Link>
          </ul>
        </div>

        <Button
          onClick={() => onSubmit()}
          style={{ width: 197 }}
          className="mt-8 w-full self-end"
        >
          <FormattedMessage {...messages.continueButton} />
        </Button>
      </div>
    </div>
  );
};
