import { lazy, Suspense, useContext, useEffect } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import LoadingAnimation from "components/LoadingAnimation";
import { UserContext } from "providers/UserProvider";
import { FadeInRouter } from "components/FadeInRouter";
import { LoggedRoutesProps } from "./types";
import { NavBar } from "./NavBar";
import { LocationContext } from "providers/LocationProvider";
import classNames from "classnames";
import { OnBoardingRoutes } from "./OnBoardingRoutes";
import IdleTimer from "components/IdleTimer";
import TermsAndConditionsMainModal from "components/TermsAndConditionsMainModal";
import InfoRequestModal from "components/InfoRequestModal";

const MultipleWithdrawRoutes = lazy(async () => ({
  default: (await import("./MultipleWithdrawRoutes")).MultipleWithdrawRoutes,
}));
const WithdrawRoutes = lazy(async () => ({
  default: (await import("./WithdrawRoutes")).WithdrawRoutes,
}));
const DepositRoutes = lazy(async () => ({
  default: (await import("./DepositRoutes")).DepositRoutes,
}));
const ExchangeRoutes = lazy(async () => ({
  default: (await import("./ExchangeRoutes")).ExchangeRoutes,
}));
const DashboardRoutes = lazy(async () => ({
  default: (await import("./DashboardRoutes")).DashboardRoutes,
}));
const BalanceRoutes = lazy(async () => ({
  default: (await import("./BalanceRoutes")).BalanceRoutes,
}));
const ProfileRoutes = lazy(async () => ({
  default: (await import("./ProfileRoutes")).ProfileRoutes,
}));
const DollarizeRoutes = lazy(async () => ({
  default: (await import("./DollarizeRoutes")).DollarizeRoutes,
}));
const HistoryRoutes = lazy(async () => ({
  default: (await import("./HistoryRoutes")).HistoryRoutes,
}));
const MarketPricesRoutes = lazy(async () => ({
  default: (await import("./MarketPricesRoutes")).MarketPricesRoutes,
}));
const CardsRoutes = lazy(async () => ({
  default: (await import("./CardsRoutes")).CardsRoutes,
}));

export const LoggedRoutes: React.FC<LoggedRoutesProps> = ({ prefix }) => {
  const { user, loading, auth0isAuthenticated, getUser } =
    useContext(UserContext);
  const { displayLocation } = useContext(LocationContext);

  useEffect(() => {
    if (user === undefined && !loading) {
      getUser();
    }
  }, [getUser, loading, user]);

  if ((loading || auth0isAuthenticated) && user === undefined) {
    return <LoadingAnimation />;
  }

  // TODO: Hay que revisar fuertemente cuando ya sabemos que un usuario esta logueado y cuando no.
  // if (!loading && !user && !auth0isAuthenticated) {
  //   return <Navigate to="/login" replace />;
  // }

  const shouldDisplayOnboarding =
    !displayLocation.pathname.startsWith("/logged/onboarding");

  return (
    <div className="flex h-full">
      {shouldDisplayOnboarding && <NavBar />}
      <IdleTimer />
      <TermsAndConditionsMainModal />
      <InfoRequestModal />
      <FadeInRouter
        className={classNames(
          "flex min-w-0 grow flex-col",
          !displayLocation.pathname.startsWith("/logged/dashboard") &&
            shouldDisplayOnboarding &&
            "pb-10 pl-5 pr-10 pt-10",
        )}
        prefix={prefix}
      >
        <Suspense fallback={<LoadingAnimation />}>
          <Routes>
            <Route
              path="/multiples-withdraw/*"
              element={<MultipleWithdrawRoutes />}
            />
            <Route path="/withdraw/*" element={<WithdrawRoutes />} />
            <Route path="/deposit/*" element={<DepositRoutes />} />
            <Route path="/exchange/*" element={<ExchangeRoutes />} />
            <Route path="/dashboard/*" element={<DashboardRoutes />} />
            <Route path="/balance/*" element={<BalanceRoutes />} />
            <Route path="/profile/*" element={<ProfileRoutes />} />
            <Route path="/dollarize/*" element={<DollarizeRoutes />} />
            <Route path="/onboarding/*" element={<OnBoardingRoutes />} />
            <Route path="/history/*" element={<HistoryRoutes />} />
            <Route path="/market-prices/*" element={<MarketPricesRoutes />} />
            <Route path="/cards/*" element={<CardsRoutes />} />
            <Route
              path="*"
              element={<Navigate to="/logged/dashboard" replace={true} />}
            />
          </Routes>
        </Suspense>
      </FadeInRouter>
    </div>
  );
};
