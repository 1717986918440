import React, {
  createContext,
  PropsWithChildren,
  useContext,
  useEffect,
} from "react";

import { createVoidContext } from "utils/voidContext";
import { useGetMemberStatus } from "hooks/compliance/useGetMemberStatus";
import { UserContext } from "./UserProvider";
import { useGetMemberUser } from "hooks/user/useGetMemberUser";
import { useIntercomCustom } from "hooks/utils/useIntercomCustom";
import { AccountAdvisorBubble } from "components/AccountAdvisorBubble";
import { ACCOUNT_ADVISOR_TYPE } from "@letsbit/malcolmlb";

export const IntercomContext = createContext(createVoidContext("intercom"));

const excludedTagsSet: Set<string> = new Set([
  "payment_card",
  "tnc",
  "onboarding_welcome_sept_22",
  "broker",
  "onboarding_dollarize",
  "dollarize",
  "active_card",
  "purchaser_card",
  "nuars",
  "surv_dollarize",
  "new_home",
  "new_home2",
  "beta-servicios",
  "show_payment_service",
  "surv_pmc",
  "have_seen_payment_service_tyc",
  "nuars-2",
  "nuars-test",
  "lb-cobro",
  "lb-cuit",
  "dolares",
  "midolar",
  "dolarya",
  "undolar",
  "country-ar",
  "country-at",
  "country-au",
  "country-be",
  "country-bg",
  "country-br",
  "country-ca",
  "country-ch",
  "country-cl",
  "country-cn",
  "country-co",
  "country-cr",
  "country-cy",
  "country-cz",
  "country-de",
  "country-dk",
  "country-do",
  "country-ec",
  "country-ee",
  "country-es",
  "country-fi",
  "country-fr",
  "country-gb",
  "country-gr",
  "country-gt",
  "country-hk",
  "country-hn",
  "country-hr",
  "country-hu",
  "country-ie",
  "country-il",
  "country-it",
  "country-lt",
  "country-lu",
  "country-lv",
  "country-mt",
  "country-mx",
  "country-nl",
  "country-no",
  "country-nz",
  "country-pe",
  "country-pl",
  "country-pt",
  "country-py",
  "country-ro",
  "country-se",
  "country-sg",
  "country-si",
  "country-sk",
  "country-sv",
  "country-tr",
  "country-us",
  "country-uy",
  "country-vg",
  "payment_card_transition_onboarding",
]);

export const IntercomProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const { data: memberStatus } = useGetMemberStatus();
  const { user } = useContext(UserContext);
  const { update: intercomUpdate, accountAdvisor } = useIntercomCustom();
  const { data: precogsProfile } = useGetMemberUser();

  useEffect(() => {
    if (user) {
      intercomUpdate({
        email: user.email,
        userId: user.uid,
        customAttributes: {
          personType: memberStatus?.customer?.person_type,
          level: memberStatus?.level,
          status: user.state,
          created_at: user.created_at,
          tags: precogsProfile?.tags
            .filter((tag) => !excludedTagsSet.has(tag.name))
            .map((a) => a.name)
            .join(", "),
        },
      });
    }
  }, [intercomUpdate, memberStatus, user, precogsProfile]);

  return (
    <IntercomContext.Provider value={{}}>
      {accountAdvisor &&
        accountAdvisor.type === ACCOUNT_ADVISOR_TYPE.PERSONAL_ADVISOR && (
          <AccountAdvisorBubble accountAdvisor={accountAdvisor.advisor} />
        )}
      {children}
    </IntercomContext.Provider>
  );
};
