import { defineMessages } from "react-intl";

export const scope = "pages.OnBoarding.components.LocationForm";

export const messages = defineMessages({
  inputDescriptionCompanyName: {
    id: `${scope}.inputDescriptionCompanyName`,
    defaultMessage: "Nombre de la empresa",
  },
  placeholderCompanyName: {
    id: `${scope}.placeholderCompanyName`,
    defaultMessage: "Ingrese la razón social",
  },
  continueButton: {
    id: `${scope}.continueButton`,
    defaultMessage: "CONTINUAR",
  },
  warning: {
    id: `${scope}.warning`,
    defaultMessage:
      "¡Atención! El número de identificación tributaria no podrá ser editado posteriormente",
  },
  placeholder: {
    id: `${scope}.companyPlaceholder`,
    defaultMessage: "Busca calle, número y localidad",
  },
  emptyResults: {
    id: `${scope}.emptyResults`,
    defaultMessage:
      "No hemos encontrado una dirección {br} que se ajuste a tu criterio de búsqueda. {bold}",
  },
  emptyResultsBold: {
    id: `${scope}.emptyResultsBold`,
    defaultMessage: "Por favor ingrésalo nuevamente.",
  },
  cantGetPlaceDetails: {
    id: `${scope}.cantGetPlaceDetails`,
    defaultMessage:
      "No pudimos seleccionar dirección, por favor reinténtalo más tarde.",
  },
});
