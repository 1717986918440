import useSWR, { Key, SWRConfiguration, BareFetcher } from "swr";
import useSWRInfinite, {
  SWRInfiniteConfiguration,
  SWRInfiniteResponse,
} from "swr/infinite";
import { useCallback, useContext } from "react";
import { UserContext } from "providers/UserProvider";

export type SWRInfiniteResponseCustom<T> = SWRInfiniteResponse<T, Error>;

export const generateAuthInfiniteKey = (
  key: Key,
  page: number,
  uuid?: string
): Key => {
  if (Array.isArray(key)) {
    const aux = key[1] as Record<string, string>;

    key[1] = { ...aux, page };
    return [...key, uuid];
  } else {
    return null;
  }
};

export const generateAuthKey = (key: Key, uuid: string): Key => {
  if (Array.isArray(key)) return [...key, uuid];
  if (key !== null) {
    switch (typeof key) {
      case "function":
        return generateAuthKey(key(), uuid);
      case "string":
        return [key, uuid];
      case "object":
        return { ...key, uuid };
      default:
        return { key };
    }
  } else {
    return null;
  }
};

export function useAuthSWR<Data>(
  key: Key,
  fn: BareFetcher<Data> | null,
  config?: SWRConfiguration<Data, Error>
) {
  const { user } = useContext(UserContext);
  const uuid = user?.uid;

  const authKey = uuid && generateAuthKey(key, uuid);

  return useSWR<Data>(key && authKey ? authKey : null, fn, config);
}

export function useAuthInfiniteSWR<Data>(
  key: Key,
  fn: BareFetcher<Data>,
  config?: SWRInfiniteConfiguration<Data, Error>
): SWRInfiniteResponseCustom<Data> {
  const { user } = useContext(UserContext);
  const uuid = user?.uid;

  const getKey = useCallback(
    (pageIndex: number) => {
      pageIndex = pageIndex + 1; // to begin on page 1
      const authKey = generateAuthInfiniteKey(key, pageIndex, uuid); // adds to the key uid and page number to params

      return [authKey && uuid ? authKey : null, { page: pageIndex }];
    },
    [key, uuid]
  );
  return { ...useSWRInfinite<Data>(getKey, fn || null, config) };
}
