import React, { useContext, useRef } from "react";
import { FormattedMessage } from "react-intl";
import { Button } from "@letsbit/milkylb";
import { CarouselRef } from "antd/es/carousel";

import { DividerWithCarousel } from "components/DividerWithCarousel";
import LogInEnterprise from "assets/images/login_enterprise.svg";
import { ReactComponent as QrLetsbit } from "assets/images/letsbit-app-qr.svg";

import { messages } from "./messages";
import { UserContext } from "providers/UserProvider";

export const OnBoardingBlockVerificationPage: React.FC = () => {
  const carouselRef = useRef<CarouselRef | null>(null);
  const { logout } = useContext(UserContext);
  const blockImage = [
    {
      img: <img className="h-[400px] w-[400px]" src={LogInEnterprise} />,
    },
  ];
  return (
    <DividerWithCarousel
      carouselContent={blockImage.map((item, idx) => (
        <div
          key={idx}
          className="flex h-full flex-col items-center justify-center"
        >
          {item.img}
        </div>
      ))}
      forwardRef={carouselRef}
      noDots
      noSwipe
    >
      <div className="flex w-full flex-col items-center justify-center">
        <div className="max-w-[420px]">
          <div className="pb-4 text-left font-['Tomato_Grotesk'] text-3xl font-bold text-[--milkylb-color-neutral-600]">
            <FormattedMessage
              {...messages.downloadOurApp}
              values={{ br: <br /> }}
            />
          </div>
          <div className="pb-8 text-left font-['Tomato_Grotesk'] text-base text-[--milkylb-color-neutral-600]">
            <FormattedMessage
              {...messages.downloadOurAppSubtitle}
              values={{ br: <br /> }}
            />
          </div>
          <div className="justify-left flex w-full flex-row pb-16">
            <div className="flex flex-col">
              <QrLetsbit className="w-[200px]" />
            </div>
          </div>
          <div className="flex w-full justify-end">
            <Button
              style={{ width: "50%", alignSelf: "flex-end" }}
              onClick={() => logout()}
            >
              <FormattedMessage {...messages.closeSession} />
            </Button>
          </div>
        </div>
      </div>
    </DividerWithCarousel>
  );
};
