import {
  MemberStatus,
  // MemberStatusParams,
  VERIFICATION_FLOW_VERSION,
} from "@letsbit/malcolmlb";
import { useContext, useEffect, useState } from "react";
import { KeyedMutator } from "swr";

import { ApiContext } from "providers/ApiProvider";
import { useAuthSWR } from "hooks/utils/useAuthSWR";

export const SWR_MEMBER_STATUS_KEY = "get-member-data";

export interface MemberStatusWithExtra extends MemberStatus {
  country: string;
}

export const useGetMemberStatus =
  (): // params?: Pick<MemberStatusParams, "country_residence">
  {
    data: MemberStatusWithExtra | undefined;
    error: Error | undefined;
    mutate: KeyedMutator<MemberStatus>;
    isValidating: boolean;
  } => {
    const { memberInstance } = useContext(ApiContext);

    const { data, error, mutate, isValidating } = useAuthSWR(
      SWR_MEMBER_STATUS_KEY,
      async () => {
        const result = await memberInstance.compliance.getMemberStatus({
          verification_flow: VERIFICATION_FLOW_VERSION.TWO,
        });
        return result?.data;
      },
      {
        revalidateIfStale: false,
      }
    );

    const [memberStatus, setMemberStatus] = useState<
      MemberStatusWithExtra | undefined
    >(
      data
        ? {
            ...data,
            country: data.customer?.country || "AR",
          }
        : undefined
    );

    useEffect(() => {
      if (data) {
        const country = data.customer?.country || "AR";
        setMemberStatus({ ...data, country });
      }
    }, [data]);

    return {
      data: memberStatus,
      error,
      mutate,
      isValidating,
    };
  };
