import { useContext } from "react";
import { SWRResponse } from "swr";
import { ApiContext } from "providers/ApiProvider";
import { useAuthSWR } from "hooks/utils/useAuthSWR";

export const SWR_GET_SERVICES_CONFIG = "get-services-config";

export const useGetServicesConfig = (): SWRResponse<string[], Error> => {
  const { memberInstance } = useContext(ApiContext);
  const swr = useAuthSWR(SWR_GET_SERVICES_CONFIG, async () => {
    const { data } = await memberInstance.precogs.getServicesConfigs();

    return data.flat();
  });

  return swr;
};
