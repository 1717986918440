import { useContext, useState } from "react";
import { ApiContext } from "providers/ApiProvider";
import { defineMessages, useIntl } from "react-intl";
import { useNotification } from "hooks/utils/useNotification";
import { NotificationStatus } from "components/Notification/types";
import { ReCaptchaContext } from "providers/ReCaptchaProvider";

export const scope = "hook.useSendEsignatureContract";

export const messages = defineMessages({
  defaultErrorNotificationMessage: {
    id: `${scope}.defaultErrorNotificationMessage`,
    defaultMessage: "No pudimos enviarle el contrato",
  },
  defaultSuccessNotificationMessage: {
    id: `${scope}.defaultSuccessNotificationMessage`,
    defaultMessage: "Enviamos el contrato al email del representante legal",
  },
  notificationTitle: {
    id: `${scope}.notificationTitle`,
    defaultMessage: "Esignature",
  },
});

export const useSendEsignatureContract = () => {
  const { memberInstance } = useContext(ApiContext)
  const [loading, setLoading] = useState(false);
  const { showNotification } = useNotification();
  const { formatMessage } = useIntl();
  const { getToken } = useContext(ReCaptchaContext)

  const sendContract = async (): Promise<void> => {
    setLoading(true)
    try {
      const token = await getToken();

      if (token) {
        await memberInstance.hub.sendEsignatureContract({
          captcha_response: token,
        })
      } else {
        throw new Error("Captcha is not working")
      }
      showNotification({
        title: formatMessage(messages.notificationTitle),
        message: formatMessage(messages.defaultSuccessNotificationMessage),
        status: NotificationStatus.SUCCESS
      })
    } catch (error) {
      console.error(error);
      showNotification({
        title: formatMessage(messages.notificationTitle),
        message: formatMessage(messages.defaultErrorNotificationMessage),
        status: NotificationStatus.ERROR
      })
      throw error;
    } finally {
      setLoading(false)
    }
  };

  return {
    sendContract,
    loading,
  };
};
