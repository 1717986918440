import { defineMessages } from "react-intl";

export const scope = "components.LegalRepresentativeWarningCard";

export const messages = defineMessages({
  alertTextBold: {
    id: `${scope}.alertTextBold`,
    defaultMessage: "El representante legal debe tener una cuenta verificada.",
  },
  alertText: {
    id: `${scope}.alertText`,
    defaultMessage:
      "{ bold } Aunque puedes continuar con el proceso de creación de cuenta empresa, para finalizarlo es necesario que el representante legal valide su cuenta con su identidad desde nuestra {link}.",
  },
  linkText: {
    id: `${scope}.linkText`,
    defaultMessage: "app",
  },
});
