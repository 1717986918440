import React from "react";
import { FormatedValueProps } from "./types";
import { useFormatter } from "hooks/utils/useFormatter";

export const FormatedValue: React.FC<FormatedValueProps> = ({
  value,
  precision,
  format,
}) => {
  const { formatValue } = useFormatter();
  return <>{formatValue(value, precision, format)}</>;
};
