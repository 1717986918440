import React, { useEffect, PropsWithChildren } from "react";
import { useLocation } from "react-router";

import { Modal } from "components/Modal";
import { usePrecogsWs } from "hooks/user/usePrecogsWs";
import PrecogsFormSlider from "components/PrecogsFormSlider";
import { FlagsHandlerProps } from "./types";

const ENABLED_PATHS = ["/logged/dashboard", "/logged/balance", "/logged/profile", "/logged/history"];

export const FlagsHandler: React.FC<PropsWithChildren<FlagsHandlerProps>> = ({
  children,
}) => {
  const { flags, showFlags, setShowFlags, setFlags } = usePrecogsWs();
  const location = useLocation();

  useEffect(() => {
    let timeout = 0;
    if (
      flags.length > 0 &&
      ENABLED_PATHS.find((path) => location.pathname.includes(path))
    ) {
      timeout = window.setTimeout(() => setShowFlags(true), 1000);
    }

    return () => {
      if (timeout) clearTimeout(timeout);
    };
  }, [flags, location, setFlags, setShowFlags]);

  return (
    <>
      <Modal isOpen={showFlags} withArrow={false} className="bg-[#fff]">
        {showFlags && flags[0] && (
          <PrecogsFormSlider
            flag={flags[0]}
            onExit={() => {
              setShowFlags(false);

              /* This clean up the flags because you already use it*/
              setFlags([]);
            }}
          />
        )}
      </Modal>
      {children}
    </>
  );
};

export default FlagsHandler;
