import { Flag } from "@letsbit/malcolmlb";
import { wsHost } from "./config";

export class PrecogsSocket {
  private static instance: PrecogsSocket;
  lastMessage: Flag[] | undefined;
  suscribedComponents!: Array<{
    callback: (message: Flag[]) => void;
    id: string;
  }>;
  ws!: WebSocket;
  channels!: string[];
  tries!: number;
  isActive!: boolean;

  constructor() {
    if (PrecogsSocket.instance) {
      return PrecogsSocket.instance;
    }

    PrecogsSocket.instance = this;

    this.suscribedComponents = [];

    this.channels = ["member.flags"];

    this.tries = 0;

    this.isActive = false;

    return this;
  }

  connectWebsocket = (): void => {
    const ws = new WebSocket(`${wsHost}`);

    ws.onopen = (): void => {
      this.isActive = true;
    };

    ws.onmessage = (message: MessageEvent): void => {
      const msg: Flag[] = JSON.parse(message.data);
      if (msg) {
        this.lastMessage = msg;
      }
      this.suscribedComponents.forEach(({ callback }) => callback(msg));
    };

    ws.onerror = (error): void => {
      console.dir("cant connect ws precgos xd", error);
    };

    // ws.onclose = (error): void => {
    //   this.isActive = false;
    //   ws.close();
    // };

    this.ws = ws;
  };

  suscribeToMessages = (callback: (message: Flag[]) => void): string => {
    const id = `precogs_ranger-${Date.now()}`;

    this.suscribedComponents = [...this.suscribedComponents, { id, callback }];

    if (this.lastMessage) {
      callback(this.lastMessage);
    }

    return id;
  };

  unsuscribeToMessages = (componentId: string): void => {
    this.suscribedComponents = this.suscribedComponents.filter(
      ({ id }) => id !== componentId
    );
  };

  close = (): void => {
    this.isActive = false;
    this.ws.close();
  };
}
