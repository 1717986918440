import { defineMessages } from "react-intl";

export const scope = "pages.OnBoarding.components.OperatoryDetailsForm";

export const messages = defineMessages({
  stimatedAmountPlaceholder: {
    id: `${scope}.stimatedAmountPlaceholder`,
    defaultMessage: "Ingresa el monto a operar mensualmente",
  },
  stimatedAmountLabel: {
    id: `${scope}.stimatedAmountLabel`,
    defaultMessage: "Monto estimado a operar (en USD)",
  },
  objectiveLabel: {
    id: `${scope}.objectiveLabel`,
    defaultMessage: "Objetivo de utilizar Let’sBit",
  },
  anotherPlaceholder: {
    id: `${scope}.anotherPlaceholder`,
    defaultMessage: "Otro objetivo",
  },
  continueButton: {
    id: `${scope}.continueButton`,
    defaultMessage: "CONTINUAR",
  },
  payrollOption: {
    id: `${scope}.payrollOption`,
    defaultMessage: "Payroll",
  },
  dollarizeOption: {
    id: `${scope}.dollarizeOption`,
    defaultMessage: "Dolarización",
  },
  sellAndBuyCryptoOption: {
    id: `${scope}.sellAndBuyCryptoOption`,
    defaultMessage: "Compra y venta de cripto",
  },
  internationalTransferOption: {
    id: `${scope}.internationalTransferOption`,
    defaultMessage: "Transferencias internacionales entre compañías",
  },
  otherOption: {
    id: `${scope}.otherOption`,
    defaultMessage: "Otro objetivo",
  },
});

export const options = [
  {
    label: messages.payrollOption,
    value: messages.payrollOption.defaultMessage,
  },
  {
    label: messages.dollarizeOption,
    value: messages.dollarizeOption.defaultMessage,
  },
  {
    label: messages.sellAndBuyCryptoOption,
    value: messages.sellAndBuyCryptoOption.defaultMessage,
  },
  {
    label: messages.internationalTransferOption,
    value: messages.internationalTransferOption.defaultMessage,
  },
  {
    label: messages.otherOption,
    value: messages.otherOption.defaultMessage,
  },
];
