import { defineMessages } from "react-intl";

export const scope = "components.PrecogsFinalSlide";

export const messages = defineMessages({
  title: {
    id: `${scope}.title`,
    defaultMessage: "¡Muchas gracias!",
  },
  subtitle: {
    id: `${scope}.subtitle`,
    defaultMessage: "No damos mas de agradecidos, gracias por tanto xD",
  },
  button: {
    id: `${scope}.button`,
    defaultMessage: "CONTINUAR",
  },
});
