import { StepperProps } from "./types";
import { ReactComponent as SuccessIcon } from "assets/images/icons/notifications/success.svg";

export const Stepper: React.FC<StepperProps> = ({ steps, completed }) => {
  // const navigate = useNavigate();

  return (
    <ul className="list-none">
      {steps.map((item) => {
        const complete = !!completed?.find((step) => step === item.idx);

        return (
          <li className="mb-4 flex flex-row" key={item.idx}>
            {complete ? (
              <div className="mr-4 flex h-[24px] min-w-[24px] items-center justify-center rounded-full bg-[--milkylb-color-green-success] font-bold text-[--milkylb-color-neutral-0]">
                <SuccessIcon />
              </div>
            ) : (
              <div className="mr-4 flex h-[24px] min-w-[24px] items-center justify-center rounded-full bg-[--milkylb-color-secondary-200] font-bold text-[--milkylb-color-neutral-0]">
                {item.idx}
              </div>
            )}
            <div
              className="flex flex-col"
              style={{
                opacity: complete ? 0.45 : 1,
              }}
            >
              <h3 className="text-sm font-bold text-[--milkylb-color-neutral-600]">
                {item.title}
              </h3>
              {item.description && (
                <p className="text-[--milkylb-color-neutral-600]">
                  {item.description}
                </p>
              )}
              <ul className="list-disc pl-4">
                {item.items?.map((subitem, i) => (
                  <li
                    className="text-sm font-normal text-[--milkylb-color-neutral-600]"
                    key={`${item.idx}-${i}`}
                  >
                    {subitem}
                  </li>
                ))}
              </ul>
            </div>
          </li>
        );
      })}
    </ul>
  );
};
