import React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { PRECOGS_FLAG_TYPE } from "@letsbit/malcolmlb";

import InfoScreen from "components/InfoScreen";
import PrecogsFirstAndFinalSlideHeader from "../PrecogsFirstAndFinalSlideHeader";

import { PrecogsFirstSlideProps } from "./types";
import { messages } from "./messages";

export const PrecogsFirstSlide: React.FC<PrecogsFirstSlideProps> = ({
  flag,
  onStart,
  onSkip,
  onOmit,
}) => {
  const intl = useIntl();
  return (
    <InfoScreen
      header={
        flag.omittable || flag.type !== PRECOGS_FLAG_TYPE.ONE_TIME_VIEW ? (
          <PrecogsFirstAndFinalSlideHeader onClick={onSkip} />
        ) : undefined
      }
      info={
        <>
          <h2 className="text-[--color-navbar-logo] text-center text-3xl font-bold font-['Source_Sans_Pro']">
            {flag.form.title ? (
              <FormattedMessage
                {...{
                  id: `PrecogsFirstSlideTitle:${flag.id}`,
                  defaultMessage: flag.form.title,
                }}
                values={{ br: <br /> }}
              />
            ) : (
              ""
            )}
          </h2>
          <p className="text-center text-sm font-['Source_Sans_Pro']">
            {flag.form.tooltip ? (
              <FormattedMessage
                {...{
                  id: `PrecogsFirstSlideTooltip:${flag.form.tooltip}`,
                  defaultMessage: flag.form.tooltip || "",
                }}
              />
            ) : (
              ""
            )}
          </p>
        </>
      }
      filledButton={{
        text: intl.formatMessage(messages.filledButton),
        onSubmit: onStart,
      }}
      clearButton={
        flag.omittable
          ? {
              text: intl.formatMessage(messages.clearButton),
              onSubmit: onOmit,
            }
          : undefined
      }
    />
  );
};

export default PrecogsFirstSlide;
