import React from "react";

import { PrecogsFormSlideProps } from "./types";
import PrecogsFormItem from "components/PrecogsFormSlider/PrecogsFormSlide/PrecogsFormItem";

export const PrecogsFormSlide: React.FC<PrecogsFormSlideProps> = ({
  formItem,
  control,
  disableValidation = false,
}) => {
  return (
    <div className="h-full w-full text-left">
      <div>
        <h3 className="text-2xl font-bold text-[--color-navbar-logo]" style={{ marginBottom: formItem.tooltip ? 8 : 16 }}>
          {formItem.title || ""}
        </h3>
        {formItem.tooltip && <p className="text-base font-normal mb-4 text-[--milkylb-color-neutral-600]">{formItem.tooltip}</p>}
      </div>

      <div>
        <PrecogsFormItem
          control={control}
          formItem={formItem}
          disableValidation={disableValidation}
        />
      </div>
    </div>
  );
};

export default PrecogsFormSlide;
