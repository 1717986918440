import React, { useContext } from "react";
import { FormattedMessage } from "react-intl";

import { messages } from "./messages";
import { JpdFundsOriginPageProps } from "../types";
import { FundsOriginForm } from "../../../components/forms/FundsOriginForm";
import { LogOutButton } from "components/LogOutButton";
import { BackButton } from "components/BackButton";
import { JPDContext } from "providers/JPDProvider";

export const JpdFundsOriginPage: React.FC<JpdFundsOriginPageProps> = ({
  onSubmit,
}) => {
  const form = useContext(JPDContext);

  return (
    <div className="fadeInSimple relative flex h-full w-full flex-col items-center justify-center">
      <BackButton className="absolute left-[40px] top-[24px]" />

      <LogOutButton className="absolute right-[40px] top-[24px]" />
      <div className="flex h-full flex-col py-[5%]">
        <h1 className="mb-8 w-full font-['Tomato_Grotesk'] text-4xl font-bold text-[--milkylb-color-neutral-600]">
          <FormattedMessage {...messages.title} />
        </h1>
        <FundsOriginForm
          className="w-[448px] grow"
          onSubmit={onSubmit}
          form={form}
        />
      </div>
    </div>
  );
};
