import { Navigate, Route, Routes, useNavigate } from "react-router-dom";
import { OnBoardingLocalCompaniesRoutes } from "./OnBoardingLocalCompaniesRoutes";
import { OnBoardingErrorRoutes } from "./OnBoardingErrorRoutes";
import { OnBoardingForeignCompaniesRoutes } from "./OnBoardingForeignCompaniesRoutes";
import { useGetOnBoardingSteps } from "hooks/user/useGetOnBoardingSteps";
import LoadingAnimation from "components/LoadingAnimation";
import { useContext, useEffect } from "react";
import {
  // FLOW_NEXT_STEP,
  ONBOARDING_FLOW,
  PERSON_TYPES,
  // VERIFICATION_ENTERPRISE_STEPS,
} from "@letsbit/malcolmlb";
// import { useGetNextStepFlow } from "hooks/compliance/useGetNextStepFlow";
import { OnBoardingBlockVerificationPage } from "./OnBoardingBlockVerificationPage";
import { UserContext } from "providers/UserProvider";
import { OnBoardingSelectAccountTypePage } from "./OnBoardingSelectAccountTypePage";

const OnBoardingRedirect: React.FC = () => {
  const { data } = useGetOnBoardingSteps();
  const navigate = useNavigate();
  // const { data: nextStepFlow } = useGetNextStepFlow();
  const { user } = useContext(UserContext);

  useEffect(() => {
    if (user && data) {
      if (user?.data.person_type === PERSON_TYPES.JURIDICAL) {
        if (data?.flow === ONBOARDING_FLOW.FOREIGN) {
          navigate("./foreign");
        }
        if (data?.flow === ONBOARDING_FLOW.LOCAL) {
          navigate("./local");
        }
      } else if (user?.data.person_type === PERSON_TYPES.NATURAL) {
        navigate("./mobile-verification");
      } else {
        navigate("./select-account-type");
        // if (
        //   nextStepFlow?.next_step !== FLOW_NEXT_STEP.COMPLETED &&
        //   nextStepFlow?.next_step !== FLOW_NEXT_STEP.CREATE_NEW_FLOW
        // ) {
        //   navigate("./mobile-verification");
        // } else if (data.step !== VERIFICATION_ENTERPRISE_STEPS.CUSTOMER_DATA) {
        //   if (data?.flow === ONBOARDING_FLOW.FOREIGN) {
        //     navigate("./foreign");
        //   }
        //   if (data?.flow === ONBOARDING_FLOW.LOCAL) {
        //     navigate("./local");
        //   }
        // } else {
        //   navigate("./select-account-type");
        // }
      }
    }
  }, [data, navigate, user]);

  return <LoadingAnimation />;
};

export const OnBoardingRoutes: React.FC = () => {
  return (
    <Routes>
      <Route
        path="/mobile-verification"
        element={<OnBoardingBlockVerificationPage />}
      />
      <Route
        path="/select-account-type"
        element={<OnBoardingSelectAccountTypePage />}
      />
      <Route path="/foreign/*" element={<OnBoardingForeignCompaniesRoutes />} />
      <Route path="/local/*" element={<OnBoardingLocalCompaniesRoutes />} />
      <Route path="/error/*" element={<OnBoardingErrorRoutes />} />
      <Route path="/" element={<OnBoardingRedirect />} />
      <Route
        path="*"
        element={<Navigate to="/logged/onboarding" replace={true} />}
      />
    </Routes>
  );
};
