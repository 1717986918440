import { useEffect, useState } from "react";
import { ModalSelectorProps } from "./types";
import { Input } from "@letsbit/milkylb";
import { Modal } from "../Modal";
import { useIntl } from "react-intl";
import { messages } from "./messages";

export function ModalSelector<T extends { id: number | string }>({
  title,
  onSelectOption,
  optionComponent: OptionComponent,
  options,
  actionRef,
  placeholder,
  value,
  children,
  searchPlaceholder,
  filter,
  modalProps,
  error,
  dataCypressId,
  closeOnSelect = true,
  actions,
  disabled,
  classNameModalWrapper,
  onEmptyOptions,
  onBlur,
}: ModalSelectorProps<T>) {
  const [selecting, setSelecting] = useState(false);
  const [wasOpened, setWasOpened] = useState(false);
  const [filteredOptions, setFilteredOptions] = useState(options);
  const [searchString, setSearchString] = useState<string>();
  const { formatMessage } = useIntl();

  useEffect(() => {
    if (actionRef) {
      actionRef.current = {
        open: () => setSelecting(true),
        close: () => setSelecting(false),
      };
    }
  }, [actionRef]);

  const onClick = () => setSelecting(true);

  useEffect(() => {
    if (searchString && filter && options) {
      setFilteredOptions(options.filter((o) => filter(o, searchString)));
    } else {
      setFilteredOptions(options);
    }
  }, [filter, options, searchString]);

  useEffect(() => {
    if (filteredOptions === undefined && options !== undefined) {
      setFilteredOptions(options);
    }
  }, [filteredOptions, options]);

  useEffect(() => {
    if (!selecting) {
      setSearchString("");
      if (wasOpened) {
        onBlur?.();
      }
    } else {
      setWasOpened(true);
    }
  }, [onBlur, selecting, wasOpened]);

  return (
    <>
      <Modal
        classNameModalWrapper={classNameModalWrapper}
        {...modalProps}
        onDidDismiss={() => setSelecting(false)}
        isOpen={selecting}
      >
        <div className="flex h-full flex-col">
          {title}
          {filter && (
            <Input
              type="text"
              onIonInput={(e) => setSearchString(e.target.value as string)}
              placeholder={
                searchPlaceholder || formatMessage(messages.searchPlaceholder)
              }
            />
          )}
          {actions}
          <div className="relative flex h-full flex-col gap-y-2 overflow-y-auto pb-6 pl-1 pr-2 pt-1">
            {/* <div className="sticky top-[calc(0px-0.25rem)] h-[48px] w-[calc(100%-10px)] shrink-0 bg-gradient-to-b from-[var(--milkylb-color-neutral-100)] to-white/0" /> */}
            {filteredOptions && filteredOptions.length > 0
              ? filteredOptions?.map((opt) => (
                  <span
                    className="hover:cursor-pointer"
                    key={opt.id}
                    onClick={() => {
                      onSelectOption(opt);
                      if (closeOnSelect) {
                        setSelecting(false);
                      }
                    }}
                    data-cypress-id={`${opt.id}-option`}
                  >
                    <OptionComponent element={opt} />
                  </span>
                ))
              : onEmptyOptions}
            <div className="sticky bottom-[calc(0px-1.6rem)]	h-[48px] w-full shrink-0 bg-gradient-to-t from-[var(--milkylb-color-neutral-100)] to-white/0" />
          </div>
        </div>
      </Modal>
      {children ? (
        children({
          onClick,
          value,
        })
      ) : (
        <Input
          onIonFocus={() => setSelecting(true)}
          disabled={disabled}
          onClick={onClick}
          value={value}
          placeholder={placeholder}
          error={!!error}
          message={error}
          data-cypress-id={dataCypressId}
        />
      )}
    </>
  );
}
