import { defineMessages } from "react-intl";

export const scope = "pages.TosAcceptedPage";

export const messages = defineMessages({
  title: {
    id: `${scope}.title`,
    defaultMessage: "Aceptaste los términos y condiciones",
  },
});
