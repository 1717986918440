import { defineMessages } from "react-intl";

export const scope = "components.LogOutButton";

export const messages = defineMessages({
  logoutButton: {
    id: `${scope}.logoutButton`,
    defaultMessage: "Cerrar sesión",
  },
});
