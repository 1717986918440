export const countryMap = {
  PE: "PE",
  AR: "AR",
  CO: "CO",
};

export const preferredCurrencyFromCountry = (
  country: string | undefined
): string => {
  if (country) {
    switch (country) {
      case countryMap.AR:
        return "ars";
      case countryMap.PE:
        return "pen";
      case countryMap.CO:
        return "cop";
      default:
        return "pax";
    }
  }
  return "pax";
};
