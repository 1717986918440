import { useContext, useState } from "react";
import { ApiContext } from "providers/ApiProvider";
import { defineMessages, useIntl } from "react-intl";
import { useNotification } from "hooks/utils/useNotification";
import { NotificationStatus } from "components/Notification/types";
import { CustomerData } from "@letsbit/malcolmlb";

export const scope = "hook.useCreateCustomer";

export const messages = defineMessages({
  defaultErrorNotificationMessage: {
    id: `${scope}.defaultErrorNotificationMessage`,
    defaultMessage: "No pudimos crear la identificacion de la empresa",
  },
  notificationTitle: {
    id: `${scope}.notificationTitle`,
    defaultMessage: "Identificacion de la empresa",
  },
});

export const useCreateCustomer = () => {
  const { memberInstance } = useContext(ApiContext)
  const [loading, setLoading] = useState(false);
  const { showNotification } = useNotification();
  const { formatMessage } = useIntl();

  const createCustomer = async (
    form: CustomerData,
  ): Promise<void> => {
    setLoading(true)
    try {
      await memberInstance.compliance.createCustomer(form)

    } catch (error) {
      console.error(error);
      showNotification({
        title: formatMessage(messages.notificationTitle),
        message: formatMessage(messages.defaultErrorNotificationMessage),
        status: NotificationStatus.ERROR
      })
      throw error;
    } finally {
      setLoading(false)
    }
  };

  return {
    createCustomer,
    loading,
  };
};
