import { defineMessages } from "react-intl";

export const scope = "components.AlreadyRegistredCustomerPage";

export const messages = defineMessages({
  chatButton: {
    id: `${scope}.chatButton`,
    defaultMessage: "ABRIR CHAT",
  },
  errorTitle: {
    id: `${scope}.errorTitle`,
    defaultMessage: "El CUIT ingresado{br} ya se encuentra en uso",
  },
  errorSusbtitle1: {
    id: `${scope}.errorSusbtitle1`,
    defaultMessage: "Lamentablemente no podrás continuar con la creación de la cuenta para tu compañía.",
  },
  errorSusbtitle2: {
    id: `${scope}.errorSusbtitle2`,
    defaultMessage: "Si crees que esto fue un error, contactate con nuestro soporte para solucionarlo.",
  },
});
