import { ContactAvatar } from "@letsbit/milkylb";
import { ReactComponent as WhatsappIcon } from "assets/images/icons/whatsapp.svg";
import { AccountAdvisorBubbleProps } from "./types";

import "./styles.scss";
import { useIntercomCustom } from "hooks/utils/useIntercomCustom";

export const AccountAdvisorBubble: React.FC<AccountAdvisorBubbleProps> = ({
  accountAdvisor,
}) => {
  const { show } = useIntercomCustom();

  return (
    <ContactAvatar
      onClick={show}
      img={accountAdvisor.image}
      size={56}
      icon={() => <WhatsappIcon className="h-full w-full fill-white" />}
      className="account-advisor-bubble absolute bottom-[24px] right-[24px] z-[2147483005] cursor-pointer"
    />
  );
};
