import React from "react";
import classnames from "classnames";
import ReactDOM from "react-dom";
import { FormattedMessage } from "react-intl";
import { messages } from "./messages";
// import { sleep } from "utils/setIntervalAsync";

import { LoadingAnimationProps } from "./types";

import "./styles.scss";

// const prhasesArray = Object.values(prhases);

export const LoadingAnimation: React.FC<LoadingAnimationProps> = ({
  className,
  node,
}) => {
  // const startIndex = useRef(
  //   Math.floor(Math.random() * (prhasesArray.length - 1))
  // );
  // const [currentMessage, setCurrentMessage] = useState<{
  //   id: string;
  //   defaultMessage: string;
  // }>(prhasesArray[startIndex.current]);
  // const [messageState, setMessaState] = useState<
  //   "fadeInSimple" | "fadeOutSimple"
  // >("fadeInSimple");

  // useEffect(() => {
  //   const loop = async () => {
  //     setMessaState(() => "fadeOutSimple");
  //     await sleep(300);
  //     startIndex.current = (startIndex.current + 1) % prhasesArray.length;
  //     setCurrentMessage(prhasesArray[startIndex.current]);
  //     setMessaState("fadeInSimple");
  //   };

  //   const interval = setInterval(() => {
  //     loop();
  //   }, 4000);

  //   return () => {
  //     clearInterval(interval);
  //   };
  // }, []);

  return ReactDOM.createPortal(
    <div className={classnames(className, "loading_animation fadeInSimple")}>
      <h1 className="mb-2 font-['Tomato_Grotesk'] text-4xl font-bold text-[--milkylb-color-neutral-600]">
        <FormattedMessage {...messages.waiting} />
      </h1>
      <h3 className={classnames("mb-6 text-sm font-semibold")}>
        <FormattedMessage {...messages.subtitle} />
      </h3>
      <div className="flex">
        <div className="loading_animation__wave" />
        <div className="loading_animation__wave" />
        <div className="loading_animation__wave" />
        <div className="loading_animation__wave" />
      </div>
    </div>,
    node || document.body
  );
};

export default LoadingAnimation;
