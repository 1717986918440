import React, { useState, useEffect } from "react";
import { useIdleTimer } from "react-idle-timer";
import { useNavigate } from "react-router-dom";

const IdleTimer: React.FC = () => {
  const [state, setState] = useState<boolean>(true);
  const navigate = useNavigate();

  const onIdle = () => {
    setState(false);
  };

  const onActive = () => {
    setState(true);
  };

  useIdleTimer({
    onIdle,
    onActive,
    timeout: window.env.session_expired_timeout,
    throttle: 1000,
  });

  useEffect(() => {
    if (!state) {
      // Limpiar completamente el historial y añadir "/session-expired"
      window.history.replaceState(null, "", "/login");
      navigate("/session-expired", { replace: true });
    }
  }, [state, navigate]);

  return null;
};

export default IdleTimer;
