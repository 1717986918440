import { ServiceEnvEnum } from "@letsbit/malcolmlb";

export const FLAGS_API_URL_CONDITION: Record<ServiceEnvEnum, string> = {
  [ServiceEnvEnum.LOCAL]: "wss://ionic-stage.qubit.com.ar/api/v1/lb/precogs/ws",
  [ServiceEnvEnum.PROD]: "wss://api.letsbit.io/api/v1/lb/precogs/ws",
  [ServiceEnvEnum.STAGE]:
    "wss://api-mobile-stage.cubetadecapitales.com.ar/api/v1/lb/precogs/ws",
  [ServiceEnvEnum.TEST]:
    "httwss://api-mobile-test.cubetadecapitales.com.ar/api/v1/lb/precogs/ws",
  [ServiceEnvEnum.TEST2]:
    "wss://api-mobile-test2.cubetadecapitales.com.ar/api/v1/lb/precogs/ws",
  [ServiceEnvEnum.TEST3]:
    "wss://api-mobile-test3.cubetadecapitales.com.ar/api/v1/lb/precogs/ws",
};
export const wsHost =
  FLAGS_API_URL_CONDITION[
  (window.env.apiEnv as ServiceEnvEnum) || "local"
  ];