import { defineMessages } from "react-intl";

export const scope = "components.InfoRequestModal";

export const messages = defineMessages({
  title: {
    id: `${scope}.title`,
    defaultMessage: "Tenés una solicitud{br}de documentos pendiente",
  },
  description: {
    id: `${scope}.description`,
    defaultMessage:
      "Para que puedas seguir operando necesitamos{br}que respondas la solicitud desde{br}Perfil -> Documentación",
  },
  skipButton: {
    id: `${scope}.skipButton`,
    defaultMessage: "SALTAR",
  },
  goToDocumentationButton: {
    id: `${scope}.goToDocumentationButton`,
    defaultMessage: "IR A DOCUMENTACIÓN",
  },
});
