import { z } from "zod";
import dayjs from "dayjs";

export const emailValidator = new RegExp(
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
);

export const validate120Less = (value: string): boolean => {
    const now = dayjs();
    return now.diff(dayjs(value), "years") <= 120;
};

export const validateMinAge = (age: string, minAge: number): boolean => {
    const now = dayjs();
    return now.diff(dayjs(age), "years") >= minAge;
};

export const weirdSimbolsRegex = new RegExp(
    /^[^±!@£$%^&*_+§¡€#¢§¶°•ªº«\\/<>¿?:;|=.,()[\]{}""©®]*$/
);

export const typeDocValidations = (country: string, isJuridical?: boolean) => {
    switch (country) {
        case "AR":
            return {
                minLength: {
                    value: 11,
                    message: "Debe tener al menos 11 dígitos",
                },
                maxLength: {
                    value: 11,
                    message: "No puede tener más de 11 dígitos",
                },
                validate: (cuit: number) =>
                    isJuridical
                        ? cuit.toString().charAt(0) === "3" ||
                        "CUIT no corresponde con tipo de cuenta"
                        : cuit.toString().charAt(0) === "2" ||
                        "CUIT no corresponde con tipo de cuenta",
            };
        default:
            return {};
    }
};

export const lowerCase = new RegExp(/(?=.*[ña-z])/);
export const upperCase = new RegExp(/(?=.*[ÑA-Z])/);
export const oneDigit = new RegExp(/(?=.*\d)/);

export const specialChar = new RegExp(/[$&+,:;=?@#|'<>.^*()%!-]/);

export const MIN_PASSWORD_LENGTH = 8;
export const MIN_PASSWORD_ENTROPY = 14;

export const validatePassword = (
    value: string,
    passwordEntropy: number,
    weaKPassMsg: string
): string | boolean => {
    if (value.length < MIN_PASSWORD_LENGTH) {
        return false;
    }
    if (!lowerCase.test(value)) {
        return false;
    }
    if (!upperCase.test(value)) {
        return false;
    }
    if (!oneDigit.test(value)) {
        return false;
    }
    if (!specialChar.test(value)) {
        return false;
    }
    if (passwordEntropy <= MIN_PASSWORD_ENTROPY) {
        return weaKPassMsg;
    }
    return true;
};

export const validateCBU = (cbu: string): boolean => {
    return cbu.length === 22 && /^[0-9]*$/.test(cbu);
};

export const EmailSchema = z.string().email();
